import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import "../styles/contacts.css";
import engagement from "../images/engagement.jpg";
import { store } from "react-notifications-component";
import "react-responsive-modal/styles.css";
import Cookies from "js-cookie";
import { post, get } from "../adapters/index";

import one from "../images/1.png";
import t22 from "../images/22.png";
import t33 from "../images/33.png";
import t44 from "../images/44.png";
import banner_engagement from "../images/banner_engagement.jpg";

const OurCertifications = () => {
  return (
    <div>
      <HeaderHome popup={false} />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + engagement + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Chez B.e Halal</h1>
                <p>
                  vous pouvez faire TOUTES vos courses en ligne les yeux fermés
                  !
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container margin_30_40">
          <div className="row"></div>
        </div>
      </div>

      <div className="container margin_10_40">
        <div
          className="banner lazy"
          style={{ background: "url(" + banner_engagement + ")" }}
        >
          <div
            className="wrapper d-flex align-items-center opacity-mask"
            data-opacity-mask="rgba(0, 0, 0, 0.2)"
          >
            <div>
              <h3>Pratique, rapide & certifié 100% Halal c’est B.e Halal</h3>
              <p>
                B.e Halal c’est PAS uniquement du HALAL c’est du HALAL CERTIFIÉ
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container margin_30_40">
        <div>
          <h3 style={{ textAlign: "center", fontWeight: 900 }}>
            ŒUVRES CARITATIVES
          </h3>
          <p style={{ textAlign: "center" }}>
            Chez B.E HALAL nous avons à cœur de parrainer des œuvres caritatives
            qui partagent nos valeurs, c’est pour cela que nous tenons à leur
            reverser 2.5 % de nos bénéfices.
          </p>
        </div>
        <div className="container1">
          <img src={one} alt="Avatar" />
          <strong style={{ fontWeight: 900 }}>
            Association Dignité International
          </strong>
          <p>
            L’association est porteuse de valeurs d’entraide universelles.{" "}
            <br />
            Elle réalise des missions humanitaires et des programmes pour des
            peuples dont la vie et la santé sont menacées.
            <br />
            <a href="https://www.dignite-international.org  ">
              https://www.dignite-international.org{" "}
            </a>
          </p>
        </div>

        <div className="container1">
          <img src={t22} alt="Avatar" />
          <strong style={{ fontWeight: 900 }}>
            Association Paris Eglantine
          </strong>
          <p>
            Paris Eglantine est une association culturelle qui vise à promouvoir
            la culture et l'art et à offrir des services dans les différents
            domaines de la vie aux personnes en situation de handicap, y compris
            aux personnes valides, en Europe.
            <br />
            <a href="https://fr-fr.facebook.com/InstitutParisEglantine/">
              https://fr-fr.facebook.com/InstitutParisEglantine/
            </a>
          </p>
        </div>
        <div className="container1">
          <img src={t33} alt="Avatar" />
          <strong style={{ fontWeight: 900 }}>Association Tahara</strong>
          <p>
            TAHARA est une association constituée de bénévoles qui ont à cœur de
            prendre soin de nos défunts et ceux depuis plus de 10 ans.
            Accompagnement à la toilette rituelle, formation gratuite afin de
            diffuser cette science et beaucoup d'autres actions.
            <br />
            <a href="https://www.associationtahara.com  ">
              https://www.associationtahara.com{" "}
            </a>
          </p>
        </div>
        <div className="container1">
          <img src={t44} alt="Avatar" />
          <strong style={{ fontWeight: 900 }}>L’école Du Savoir</strong>
          <p>
            L’école du savoir est un groupe scolaire qui a pour but d’offrir aux
            enfants des méthodes respectant leurs différents rythmes
            d’apprentissages et un cadre respectueux des valeurs morales.Elle
            est totalement indépendante et n’est pas subventionnée.
            <br />
            <a href="http://www.ecoledusavoir.fr/ ">
              http://www.ecoledusavoir.fr/
            </a>
          </p>
        </div>
      </div>

      <div className="container margin_30_40">
        <div className="row">
          <div className="col-lg-12">
            <div className="singlepost">
              <h1 className="add_bottom_15" style={{ fontWeight: 900 }}>
                Sourate SABA / Sourate 34 Verset 39
                <br />
                سبأ
              </h1>

              <div className="post-content">
                <h5 style={{ textAlign: "right", fontWeight: 900 }}>
                  بسم الله الرحمن الرحيم
                </h5>
                <h5 style={{ textAlign: "left", fontWeight: 900 }}>
                  Au nom d'Allah le Tout Miséricordieux, le Très Miséricordieux.
                </h5>
                <p style={{ textAlign: "right", fontWeight: 900 }}>
                  {" "}
                  قُلْ إِنَّ رَبِّى يَبْسُطُ ٱلرِّزْقَ لِمَن يَشَآءُ مِنْ
                  عِبَادِهِۦ وَيَقْدِرُ لَهُۥ ۚ وَمَآ أَنفَقْتُم مِّن شَىْءٍۢ
                  فَهُوَ يُخْلِفُهُۥ ۖ وَهُوَ خَيْرُ ٱلرَّٰزِقِينَ
                </p>
                <p>
                  <b>
                    <font size="3" color="#349d00">
                      Dis :
                    </font>
                  </b>
                  <font size="1">
                    « Mon Seigneur dispense avec largesse ou restreint Ses dons
                    à qui Il veut parmi ses serviteurs. Et toute dépense que
                    vous faites [dans le bien], Il la remplace, et c'est Lui le
                    Meilleur des donateurs. »
                  </font>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OurCertifications;
