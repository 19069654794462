import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import "../styles/contacts.css";
import certifications from "../images/certifications.jpg";
import { store } from "react-notifications-component";
import "react-responsive-modal/styles.css";
import Cookies from "js-cookie";
import { post, get } from "../adapters/index";

import achahada from "../images/achahada.png";
import avs from "../images/avs.png";
import hims from "../images/hims_n.png";
import deeni from "../images/deeni.png";
import banner_engagement from "../images/banner_engagement.jpg";

const OurCertifications = () => {
  return (
    <div>
      <HeaderHome popup={false} />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + certifications + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Notre démarche halal</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_gray">
        <div className="container margin_30_40">
          <div className="row">
            <div className="col-lg-12">
              <div className="singlepost">
                <h1 className="add_bottom_15" style={{ fontWeight: 900 }}>
                  Contrôle & Certification Halal
                </h1>

                <div className="post-content">
                  <p>
                    <i className="icon_circle-slelected"></i> Notre mission
                    principale chez B.E HALAL c’est de vous simplifier la vie.
                  </p>
                  <p>
                    <i className="icon_circle-slelected"></i> Pour cela, B.E
                    HALAL décide de s’entourer d’organismes ayant de nombreuses
                    années d’expériences dans le secteur de la viande halal
                    autour d’un objectif ambitieux.
                  </p>
                  <p>
                    <i className="icon_circle-slelected"></i> Nous collaborons
                    avec des organismes reconnus de certification qui respectent
                    l’éthique et la philosophie HALAL.
                  </p>
                  <p>
                    <i className="icon_circle-slelected"></i> Ils garantissent
                    la conformité d’un produit, qui s’appuie sur un cahier des
                    charges répondant strictement aux principes et règles
                    islamiques en la matière.
                  </p>
                  <p>
                    <i className="icon_circle-slelected"></i> Nous
                    commercialisons des produits exclusivement issus de
                    sacrifices manuels réalisés sans électronarcose, sans
                    électrochocs, sans assommage, et sans étourdissement.
                  </p>
                  <p>
                    <i className="icon_circle-slelected"></i> La certification
                    Halal d’un produit vendu chez B.E HALAL concerne tout le
                    processus essentiel à celui-ci ; de l’abattage à la
                    commercialisation du produit.
                  </p>
                  <p>
                    <i className="icon_circle-slelected"></i> L’ensemble de la
                    chaine de ces opérations doit être en stricte conformité
                    avec la jurisprudence islamique.
                  </p>
                  <p>
                    <i className="icon_circle-slelected"></i> C’est pour cela
                    que nous avons choisi les organismes :
                  </p>
                  <h5 style={{ fontWeight: 900 }}>
                    HIMS, Achahada, AVS et Deeni{" "}
                  </h5>
                  <img src={hims} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img src={achahada} />
                  &nbsp;&nbsp;&nbsp;
                  <img src={avs} />
                  &nbsp;&nbsp;&nbsp;
                  <img src={deeni} />
                  <br />
                  <br />
                  <p>
                    <i className="icon_circle-slelected"></i> Nous nous sommes
                    aperçus qu’authentifier la viande devenait de plus en plus
                    difficile.{" "}
                  </p>
                  <h6 style={{ fontWeight: 900 }}>
                    Or, Allah attend de nous une conscience vigilante afin de
                    préserver son dépôt (Amana){" "}
                  </h6>
                  <br />
                  <p>
                    <i className="icon_circle-slelected"></i> C’est pourquoi B.E
                    HALAL s’engage à porter une partie de ce dépôt, consolidant
                    ainsi le travail des organismes choisis qui ont la volonté
                    d’assainir le secteur de la viande halal, de ses fraudes et
                    de ses doutes.{" "}
                  </p>
                  <p>
                    <i className="icon_circle-slelected"></i> Comme nous, vous
                    en avez assez de devoir choisir entre la qualité & la
                    certification ?
                  </p>
                  <h5 style={{ fontWeight: 900 }}>
                    {" "}
                    B.E HALAL est fait pour vous{" "}
                  </h5>
                  <br />
                  <br />
                  <h5 style={{ textAlign: "center", fontWeight: 900 }}>
                    {" "}
                    Sourate 5 du Saint Coran
                    <br />
                    <br />
                    Al-Maidah (La table)
                    <br />
                    سورة المائدة
                  </h5>
                  <h5 style={{ textAlign: "right" }}>
                    بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم
                  </h5>
                  <p style={{ textAlign: "justify" }}>
                    {" "}
                    Au nom de Dieu, le Tout Miséricordieux, le Très
                    Miséricordieux.
                  </p>
                  <h5 style={{ textAlign: "right" }}>
                    حُرِّمَتْ عَلَيْكُمُ الْمَيْتَةُ وَالدَّمُ وَلَحْمُ
                    الْخِنزِيرِ وَمَا أُهِلَّ لِغَيْرِ اللَّهِ بِهِ
                    وَالْمُنْخَنِقَةُ وَالْمَوْقُوذَةُ وَالْمُتَرَدِّيَةُ
                    وَالنَّطِيحَةُ وَمَا أَكَلَ السَّبُعُ إِلَّا مَا ذَكَّيْتُمْ
                    وَمَا ذُبِحَ عَلَى النُّصُبِ وَأَن تَسْتَقْسِمُوا
                    بِالْأَزْلَامِ ذَلِكُمْ فِسْق
                  </h5>
                  <p style={{ textAlign: "justify" }}>
                    {" "}
                    Vous sont interdits la bête trouvée morte, le sang, la chair
                    de porc, ce sur quoi on a invoqué un autre nom que celui
                    d'Allah, la bête étouffée, la bête assommée ou morte d'une
                    chute ou morte d'un coup de corne, et celle qu'une bête
                    féroce a dévorée - sauf celle que vous égorgez avant qu'elle
                    ne soit morte -. (Vous sont interdits aussi la bête) qu'on a
                    immolée sur les pierres dressées, ainsi que de procéder au
                    partage par tirage au sort au moyen de flèches. Car cela est
                    perversité. " ( S5, V3 )
                  </p>
                  <h5 style={{ textAlign: "right" }}>
                    يَسْأَلُونَكَ مَاذَا أُحِلَّ لَهُمْ قُلْ أُحِلَّ لَكُمُ
                    الطَّيِّبَاتُ وَمَا عَلَّمْتُم مِّنَ الْجَوَارِحِ
                    مُكَلِّبِينَ تُعَلِّمُونَهُنَّ مِمَّا عَلَّمَكُمُ اللَّهُ
                    فَكُلُوا مِمَّا أَمْسَكْنَ عَلَيْكُمْ وَاذْكُرُوا اسْمَ
                    اللَّهِ عَلَيْهِ وَاتَّقُوا اللَّهَ إِنَّ اللَّهَ سَرِيعُ
                    الْحِسَاب
                  </h5>
                  <p style={{ textAlign: "justify" }}>
                    {" "}
                    Ils t'interrogent sur ce qui leur est permis. Dis : «Vous
                    sont permises les bonnes nourritures, ainsi que ce que
                    capturent les carnassiers que vous avez dressés, en leur
                    apprenant ce qu'Allah vous a appris. Mangez donc de ce
                    qu'elles capturent pour vous et prononcez dessus le nom
                    d'Allah. Et craignez Allah. Car Allah est, certes, prompt
                    dans les comptes. ( S5, V4 )
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container margin_10_40">
          <div
            className="banner lazy"
            data-bg={banner_engagement}
            style={{ background: "url(" + banner_engagement + ")" }}
          >
            <div
              className="wrapper d-flex align-items-center opacity-mask"
              data-opacity-mask="rgba(0, 0, 0, 0.2)"
            >
              <div>
                <h3> Pratique, rapide & certifié 100% Halal c’est B.e Halal</h3>
                <p>
                  {" "}
                  B.e Halal c’est PAS uniquement du HALAL c’est du HALAL
                  CERTIFIÉ
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OurCertifications;
