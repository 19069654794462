const openReducer = (state = {open: 0, loginAfter: ''}, action) => {
    switch(action.type) {
        case 'OPEN':
            state.open = action.payLoad;
            return state;
        case 'LOGIN_AFTER':
            state.loginAfter = action.payLoad;
            return state;
        default:
            return state;
    }
}

export default openReducer;