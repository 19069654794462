import React, { useState, useHook, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../images/logo.svg";
import logo_sticky from "../images/logo_sticky.svg";
import userImage from "../images/user.png";
import popup from "../images/popup.png";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { useTranslation } from "react-i18next";
import "../translations/i18n";

import { useSelector, useDispatch } from "react-redux";
import {
  addFav,
  removeFav,
  isLogin,
  loginStep,
  loading,
  fingerprint,
  user,
  cart,
} from "../actions/index";

import axios from "axios";
import Cookies from "js-cookie";

import ReactNotifications from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import addCart, { count } from "../utility/Cart";
import { reactLocalStorage } from "reactjs-localstorage";
import { post } from "../adapters/index";
import moment from "moment";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  AndroidView,
  IOSView,
} from "react-device-detect";

import { AsyncStorage } from "AsyncStorage";

const Header = (props) => {
  const inputM = useRef(null);
  const inputO = useRef(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [mobile, setMobile] = useState(0);
  const [error, setError] = useState("");
  const [items, setItems] = useState([]);
  const [shoCat, setShowCat] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const favorite = useSelector((state) => state.favorite);
  const isUserLogin = useSelector((state) => state.login);
  const loader = useSelector((state) => state.loading);
  const DFinger = useSelector((state) => state.fingerprint);
  const userData = useSelector((state) => state.user);
  const numOfItems = useSelector((state) => state.cart.count);
  const loginOpen = useSelector((state) => state.open.open);
  const loginAfter = useSelector((state) => state.open.loginAfter);
  const [mobError, setMobError] = useState(0);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [cart_item_count, setCartItemCount] = useState(0);

  useEffect(function () {
    if (isUserLogin.is_login == false || isUserLogin.is_login == undefined) {
      // setOpen(true);
    }
  });

  useEffect(
    function () {
      setCartItemCount(numOfItems);
    },
    [numOfItems]
  );
  useEffect(
    function () {
      setOpen(loginOpen);
    },
    [loginOpen]
  );

  useEffect(function () {
    var c = window.client;

    dispatch(fingerprint(c.getFingerprint()));

    if (Cookies.get("bhUser") != undefined) {
      dispatch(user(Cookies.getJSON("bhUser")));
      dispatch(isLogin({ is_login: true, login_step: 2 }));
    }
    console.log("user test :" + Cookies.get("bhUser"));
    //dispatch(cart(count()));
    _loadCartCount();
    // alert("90");
    _loadCategories();

    if (Cookies.get("popup_close") == undefined) {
      setShowDownloadPopup(true);
    } else {
      setShowDownloadPopup(false);
    }

    if (!isMobile) {
      setShowDownloadPopup(false);
    }
  }, []);

  //LOGIN ACTION
  const login = () => {
    setMobError(0);
    var mobile = inputM.current.value;

    if (mobile.length < 9 || mobile.length > 10) {
      setMobError(1);
      return false;
    }

    dispatch(isLogin({ is_login: false, login_step: 1 }));
    dispatch(loading(true));
    setMobile(mobile);

    const form = new FormData();
    form.append("phone", mobile);
    form.append("player_id", "");
    form.append("refcode", "");

    const options = {
      method: "POST",
      url: process.env.REACT_APP_API_URL + "register",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Accept: "application/json",
        Fingerprint: DFinger,
      },
      data: form,
    };

    dispatch(loading(false));
    return axios
      .request(options)
      .then
      //data => dispatch(makeASandwich(forPerson, sauce)),
      //error => dispatch(apologize('The Sandwich Shop', forPerson, error))
      ();
  };

  const setLogin = (data) => {
    //SET JS-COOKIE
    var c = {
      token: data.data.data.customer.api_token,
      mobile: data.data.data.customer.phone,
      name: data.data.data.customer.fname + " " + data.data.data.customer.lname,
    };
    //document.cookie = "bhUser=1234;max-age=172800; SameSite=Strict; Secure";
    Cookies.set(
      "bhUser",
      {
        token: data.data.data.customer.api_token,
        mobile: data.data.data.customer.phone,
        name:
          data.data.data.customer.fname + " " + data.data.data.customer.lname,
      },
      { expires: 2 }
    );

    //DISPATCH USER VALUES
    dispatch(isLogin({ is_login: true, login_step: 2 }));
    dispatch(
      user({
        token: data.data.data.customer.api_token,
        mobile: data.data.data.customer.phone,
        name:
          data.data.data.customer.fname + " " + data.data.data.customer.lname,
      })
    );
    // _loadCartCount();
    // alert("174");

    //CLOSE MODAL
    setOpen(false);
    setError("");

    //if (loginAfter == 'checkout') {
    //SAVE CART
    saveCart();
    _fetchUserCartData();
    //}
  };

  const _fetchUserCartData = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("cart/view", form)
      .then(async function (response) {
        var cartData = [];
        response.data.data.map((i, k) =>
          cartData.push({
            product: i[0].id,
            qty: i[0].cart_qty,
          })
        );

        var objectData = {
          data: cartData,
          expiry_time: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        };

        reactLocalStorage.remove("bhCart");
        reactLocalStorage.setObject("bhCart", JSON.stringify(objectData));
      })
      .catch(function (error) {});
  };

  const saveCart = () => {
    var bhCart = reactLocalStorage.getObject("bhCart");

    if (Object.keys(bhCart).length == 0) {
      return null;
    }

    var cart = JSON.parse(bhCart);
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("cart", JSON.stringify(cart.data));

    post("cart/bulk/store", form)
      .then(async function (response) {
        if (loginAfter == "checkout") {
          window.location = "/checkout";
        }
      })
      .catch(function (error) {});
  };

  const verify = () => {
    setMobError(0);
    if (inputO.current.value.length != 4) {
      setMobError(100);
      return false;
    }

    const form = new FormData();
    form.append("phone", mobile);
    form.append("otp", inputO.current.value);

    const options = {
      method: "POST",
      url: process.env.REACT_APP_API_URL + "verify-otp",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Accept: "application/json",
        Fingerprint: DFinger,
      },
      data: form,
    };

    return axios.request(options).then(
      (data) => setLogin(data),
      (error) => setError(t("otp_error"))
    );

    /*axios.request(options).then(function (response) {
            console.log(response.data);
        }).catch(function (error) {
            console.error(error);
        });*/
  };

  const _loadCategories = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("wb/category/all", form)
      .then(async function (response) {
        setCategories(response.data.data);
      })
      .catch(function (error) {});
  };

  const _loadCartCount = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("cart/wb/count", form)
      .then(async function (response) {
        // dispatch(cart(response.data.data));
        setCartItemCount(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const seo_url = (url) => {
    return url
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, "");
  };

  const back2enter = () => {
    dispatch(isLogin({ is_login: false, login_step: 0 }));
  };

  const popupClose = () => {
    Cookies.set("popup_close", true);
    setShowDownloadPopup(false);
  };

  return (
    <>
      {showDownloadPopup === true ? (
        <MobileView>
          <div
            style={{
              position: "absolute",
              border: "1px solid #83c55e",
              top: 0,
              left: 0,
              width: "100%",
              padding: 12,
              background: "#83c55e",
              zIndex: 9,
              color: "#fff",
              fontSize: 17,
              fontWeight: 500,
            }}
          >
            <AndroidView>
              <a
                onClick={() => popupClose()}
                href="#"
                style={{ color: "white" }}
              >
                x
              </a>
              &nbsp; <img src={popup} style={{ width: 23, height: 23 }} />
              &nbsp;B.e Halal for Android
              <a
                href="https://play.google.com/store/apps/details?id=com.behalal"
                style={{
                  color: "white",
                  float: "right",
                  border: "1px solid aliceblue",
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                }}
                target="_blank"
              >
                Install
              </a>
            </AndroidView>

            <IOSView>
              <a
                onClick={() => popupClose()}
                href="#"
                style={{ color: "white" }}
              >
                x&nbsp; <img src={popup} style={{ width: 23, height: 23 }} />
                &nbsp;
              </a>{" "}
              B.e Halal for IOS
              <a
                href="https://apps.apple.com/us/app/b-e-halal/id1611233109"
                style={{
                  color: "white",
                  float: "right",
                  border: "1px solid aliceblue",
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                }}
                target="_blank"
              >
                Install
              </a>
            </IOSView>
          </div>
        </MobileView>
      ) : null}
      <header
        className="header_in clearfix"
        style={{ top: showDownloadPopup == true ? 60 : 0 }}
      >
        <ReactNotifications />
        <div className="container">
          <div id="logo">
            <a href="/">
              <img src={logo_sticky} alt="" className="logo_sticky" />
            </a>
          </div>

          <ul id="top_menu">
            {isUserLogin.is_login == false ||
            isUserLogin.is_login == undefined ? (
              <li>
                <a
                  onClick={() => setOpen(true)}
                  href="#"
                  id="sign-in"
                  className="login"
                >
                  Sign In
                </a>
              </li>
            ) : (
              <li>
                <div className="dropdown user clearfix">
                  <a href="#" data-toggle="dropdown" aria-expanded="false">
                    <figure>
                      <img src={userImage} alt="" />
                    </figure>{" "}
                    {userData.name != " " ? userData.name : userData.mobile}
                  </a>
                  <div className="dropdown-menu">
                    <div className="dropdown-menu-content">
                      <ul>
                        <li>
                          <a href="/dashboard">
                            <i className="icon_cog"></i>
                            {t("dashboard")}
                          </a>
                        </li>
                        <li>
                          <a href="/my_orders">
                            <i className="icon_document"></i>
                            {t("my_orders")}
                          </a>
                        </li>
                        <li>
                          <a href="/logout">
                            <i className="icon_key"></i>Se déconnecter
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            )}

            <li>
              <Link
                to={"/cart"}
                className="wishlist_bt_top"
                title={t("my_cart")}
              >
                {t("my_cart")}
              </Link>
              <span
                id="cart_item_count"
                onClick={() => (window.location = "/cart")}
                className="badge"
                style={{ cursor: "pointer" }}
              >
                {cart_item_count}
              </span>
            </li>
          </ul>
          <a href="#0" className="open_close">
            <i className="icon_menu"></i>
            <span>Menu</span>
          </a>
          <nav className="main-menu">
            <div id="header_menu">
              <a href="#0" className="open_close">
                <i className="icon_close"></i>
                <span>Menu</span>
              </a>
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <ul>
              <li className="show-submenu">
                <a href="#0" className="show-submenu">
                  Catégories
                  {shoCat === false ? (
                    <span
                      style={{ float: "right", fontSize: 20 }}
                      className="arrow_carrot-down"
                    ></span>
                  ) : (
                    <span
                      style={{ float: "right", fontSize: 20 }}
                      className="arrow_carrot-up"
                    ></span>
                  )}
                </a>
                <ul style={{ maxHeight: 400, overflow: "scroll" }}>
                  {categories.map((c) => (
                    <li key={c.id}>
                      <a
                        href={
                          process.env.REACT_APP_BASE_URL +
                          "category/" +
                          c.id +
                          "/" +
                          seo_url(c.category_name)
                        }
                      >
                        {c.category_name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <a href={process.env.REACT_APP_BASE_URL + "delivery"}>
                  Livraison avec B.E HALAL
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_BASE_URL + "our-certifications"}>
                  {" "}
                  Nos certifications{" "}
                </a>
              </li>
              <li></li>
              <li>
                <a
                  href={process.env.REACT_APP_BASE_URL + "about"}
                  className="show-submenu"
                >
                  Qui sommes-nous
                </a>
              </li>
              {/*<li>
					        <a href={process.env.REACT_APP_BASE_URL + "contact"} className="show-submenu">Contactez-nous</a>
				        </li>*/}
              <li>
                <a
                  href={process.env.REACT_APP_BASE_URL + "tell-us-everything"}
                  className="show-submenu"
                >
                  Dites-nous tout
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <Modal
          styles={{
            modal: {
              backgroundColor: "transparent",
              overflow: "hidden",
              boxShadow: "none",
            },
          }}
          open={open}
          showCloseIcon={false}
          onClose={onCloseModal}
          blockScroll={true}
          center
        >
          {isUserLogin.login_step == 0 ? (
            <div id="sign-in-dialog" className="zoom-anim-dialog">
              <div className="modal_header">
                <h3>{t("sign_in")}</h3>
              </div>
              <div className="sign-in-wrapper">
                <a
                  href="#0"
                  className="social_bt facebook"
                  style={{ display: "none" }}
                >
                  Login with Facebook
                </a>
                <a
                  href="#0"
                  className="social_bt google"
                  style={{ display: "none" }}
                >
                  Login with Google
                </a>
                <div className="divider" style={{ display: "none" }}>
                  <span>Or</span>
                </div>
                <div className="form-group">
                  <label>{t("mobile_number")}</label>
                  <input
                    type="text"
                    ref={inputM}
                    className="form-control"
                    name="mobile"
                    id="mobile"
                    min="9"
                    max="9"
                    maxlength="10"
                  />
                  <i className="icon_phone"></i>
                  {mobError === 1 ? (
                    <div className="error">
                      Veuillez entrer un numéro de mobile valide
                    </div>
                  ) : null}
                </div>
                <div className="form-group" style={{ display: "none" }}>
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    defaultValue=""
                  />
                  <i className="icon_lock_alt"></i>
                </div>
                <div
                  className="clearfix add_bottom_15"
                  style={{ display: "none" }}
                >
                  <div className="checkboxes float-left">
                    <label className="container_check">
                      {t("rebember")}
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div style={{ display: "none" }} className="float-right mt-1">
                    {/* <a id="forgot" href="javascript:void(0);">
                      {t("forgot")}
                    </a> */}
                  </div>
                </div>
                <div className="text-center">
                  <button
                    onClick={() => login()}
                    className="btn_1 full-width mb_5 one"
                  >
                    {t("login")}
                  </button>
                </div>
                <div id="forgot_pw">
                  <div className="form-group">
                    <label>Please confirm login email below</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email_forgot"
                      id="email_forgot"
                    />
                    <i className="icon_mail_alt"></i>
                  </div>
                  <p>
                    You will receive an email containing a link allowing you to
                    reset your password to a new preferred one.
                  </p>
                  <div className="text-center">
                    <input
                      type="submit"
                      value="Reset Password"
                      className="btn_1"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div id="sign-in-dialog" className="zoom-anim-dialog">
              <div className="modal_header">
                <h3>{t("verify")}</h3>
              </div>
              <div className="sign-in-wrapper">
                <p>{t("sent_msg") + " +33 " + mobile}</p>
                <div className="form-group">
                  <label>{t("enter_pass")}</label>
                  <input
                    type="text"
                    ref={inputO}
                    className="form-control"
                    name="otp"
                    id="otp"
                    min="4"
                    max="4"
                    maxlength="4"
                  />
                  <i className="icon-user_2"></i>
                  {mobError === 100 ? (
                    <div className="error">
                      Veuillez entrer un code de vérification valide
                    </div>
                  ) : null}
                </div>

                {error != "" ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: 12,
                      paddingBottom: 5,
                      paddingTop: 5,
                    }}
                  >
                    {error}
                  </div>
                ) : null}

                <div className="text-center">
                  <button
                    onClick={() => verify()}
                    className="btn_1 full-width mb_5 one"
                  >
                    Valider
                  </button>
                  <div style={{ marginTop: 15 }}>
                    <a href="javascript:void(0)" onClick={() => back2enter()}>
                      Autre numéro
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </header>
    </>
  );
};

export default Header;
