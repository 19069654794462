import React, { useState, useHook, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { productDetails, cart, refreshCart } from "../actions/index";
import "../styles/detail-page.css";
import Cookies from "js-cookie";
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useParams } from "react-router";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Cache, { setCache } from "../utility/Cache";
import addCart, {
  isExist,
  removeCart,
  decrementCart,
  incrementCart,
} from "../utility/Cart";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import jBox from "jbox";
import "jbox/dist/jBox.all.css";

const CartList = (props) => {
  /*if (props[0] == undefined || props[0].product_image == undefined)  {
        props[0] = props;
    }*/

  // console.log("props", props.data);

  const { t } = useTranslation();
  const [qty, setQty] = useState(props.data.product_qty);
  const [unitPrice, setUnitPrice] = useState(props.data.product_min_price);
  const [totalPrice, setTotalPrice] = useState(props.data.total);
  const [cartId, setCartId] = useState(props.data.cart_id);
  const dispatch = useDispatch();

  const _loadCartCount = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("cart/wb/count", form)
      .then(async function (response) {
        // dispatch(cart(response.data.data));
        // setCartItemCount(response.data.data);
        // alert(response.data.data);
        document.getElementById("cart_item_count").innerHTML =
          response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const FetchTotalData = () => {
    var bhCart = reactLocalStorage.getObject("bhCart");
    if (Object.keys(bhCart).length == 0) {
      var element = document.getElementById("cart_total");
      if (element != undefined) {
        element.innerHTML = 0;
      }
      return null;
    }

    if (Cookies.get("bhUser") == undefined) {
      var cart = JSON.parse(bhCart);
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      form.append("cart", JSON.stringify(cart.data));

      post("wb/cart/details", form)
        .then(async function (response) {
          var element = document.getElementById("cart_total");
          if (element != undefined) {
            element.innerHTML = response.data.total_product_price;
          }
        })
        .catch(function (error) {
          var element = document.getElementById("cart_total");
          if (element != undefined) {
            element.innerHTML = 0;
          }
        });
    } else {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));

      post("cart/view", form)
        .then(async function (response) {
          var element = document.getElementById("cart_total");
          if (element != undefined) {
            element.innerHTML = response.data.total_product_price;
          }
          //setTotal(response.data.total_product_price);
        })
        .catch(function (error) {
          var element = document.getElementById("cart_total");
          if (element != undefined) {
            element.innerHTML = 0;
          }
        });
    }
  };

  const minus = () => {
    setQty((prevQty) => prevQty - 1);
    decrementCart(props.data.product_id, 1);
    dispatch(refreshCart(moment("x")));

    document.getElementById("qtyInput_" + props.data.product_id).value =
      qty - 1;

    if (Cookies.get("bhUser") != undefined) {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      form.append("cart_id", cartId);
      form.append("item_id", props.data.product_id);
      form.append("quantity", 1);

      post("cart/decrement", form)
        .then(async function (response) {
          //setItems(response.data.data);
          console.log(response.data);
          FetchTotalData();
        })
        .catch(function (error) {});
    }
  };

  const increment = () => {
    setQty((prevQty) => prevQty + 1);
    incrementCart(props.data.product_id, 1);
    dispatch(refreshCart(moment("x")));

    document.getElementById("qtyInput_" + props.data.product_id).value =
      qty + 1;

    if (Cookies.get("bhUser") != undefined) {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      form.append("cart_id", cartId);
      form.append("item_id", props.data.product_id);
      form.append("quantity", 1);

      post("cart/increment", form)
        .then(async function (response) {
          //setItems(response.data.data);
          FetchTotalData();
        })
        .catch(function (error) {});
    }
  };

  const del = () => {
    removeCart(props.data.product_id, props.data.product_name);
    setQty(0);
    dispatch(cart(-1));
    dispatch(refreshCart(moment("x")));

    if (Cookies.get("bhUser") != undefined) {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId").toString());
      form.append("cart_id", cartId);
      form.append("item_id", props.data.cart_item_id);
      form.append("quantity", 1);

      for (var key of form.entries()) {
        //console.log(key[0] + ', ' + key[1]);
      }

      post("cart/delete_item", form)
        .then(async function (response) {
          //setItems(response.data.data);
          _loadCartCount();
          FetchTotalData();
        })
        .catch(function (error) {});
    }
  };

  useEffect(
    function () {
      var totalPrice = unitPrice * qty;
      totalPrice = parseFloat(totalPrice).toFixed(2);
      console.log("unit price : " + unitPrice);
      setTotalPrice(totalPrice);
    },
    [qty]
  );

  const seo_url = (url) => {
    return url
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, "");
  };

  const changeQty = (val) => {
    val = parseInt(val);

    if (!isNaN(val) && val < 16) {
      var diff = val - qty;

      if (diff > 0) {
        incrementCart(props.data.product_id, Math.abs(diff));
      } else if (diff < 0) {
        decrementCart(props.data.product_id, Math.abs(diff));
      }

      if (parseInt(val) == 0) {
        setQty(0);
      }
      setQty(val);

      if (Cookies.get("bhUser") != undefined) {
        const form = new FormData();
        form.append("butcher_id", Cookies.get("bhId"));
        form.append("cart_id", Cookies.get("bhCartId"));
        form.append("item_id", props.data.product_id);
        form.append("quantity", val);

        post("cart/update_qty", form)
          .then(async function (response) {
            console.log("sss");
            dispatch(refreshCart(moment("x")));
            FetchTotalData();
          })
          .catch(function (error) {});
      } else {
        dispatch(refreshCart(moment("x")));
      }
    }
  };

  return (
    <>
      {qty > 0 ? (
        <tr>
          <td className="product-thumbnail">
            <a
              href={
                "/product/" +
                props.data.product_id +
                "/" +
                seo_url(props.data.product_name)
              }
            >
              <img
                style={{ padding: 10 }}
                className="img-fluid"
                src={
                  process.env.REACT_APP_IMG_URL +
                  props.data.product_image.replace(
                    "/images",
                    "/images/thumbnail"
                  )
                }
                alt=""
              />
            </a>
          </td>
          <td className="product-name">
            <a
              href={
                "/product/" +
                props.data.product_id +
                "/" +
                seo_url(props.data.product_name)
              }
            >
              <h6>
                {props.data.product_name} {props.data.qty_type}
              </h6>
            </a>
          </td>
          <td className="product-price-cart">
            <a
              href={
                "/product/" +
                props.data.product_id +
                "/" +
                seo_url(props.data.product_name)
              }
            >
              <h6>
                {" "}
                {unitPrice} {t("currency_symbol")}
              </h6>
            </a>
          </td>
          <td className="product-quantity">
            <div className="cart-plus-minus">
              <button className="dec qtybutton" onClick={(e) => minus()}>
                -
              </button>
              <input
                id={"qtyInput_" + props.data.product_id}
                onChange={(e) => changeQty(e.target.value)}
                className="cart-plus-minus-box"
                type="text"
                defaultValue={qty}
                style={{ textAlign: "center" }}
              />
              <button className="inc qtybutton" onClick={(e) => increment()}>
                +
              </button>
            </div>
          </td>
          <td>
            <h6>
              {" "}
              {totalPrice} {t("currency_symbol")}
            </h6>
          </td>
          <td className="product-remove">
            <span
              className="fs1"
              aria-hidden="true"
              data-icon=""
              onClick={(e) => del()}
            ></span>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default CartList;
