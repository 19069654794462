import React, { useState, useHook, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Cookies from "js-cookie";
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";

import addCart, {
  isExist,
  removeCart,
  getQty,
  decrementCart,
  incrementCart,
} from "../utility/Cart";
import veg from "../images/veg.png";
import non_veg from "../images/nonveg.png";
import frozen from "../images/frozen.png";

import jBox from "jbox";
import "jbox/dist/jBox.all.css";

import { cart } from "../actions/index";
import "../styles/detail-page.css";
import { openLogin, openLoginAfter } from "../actions/index";

const ProductView = (params) => {
  const { t } = useTranslation();
  const [addToCart, setAddToCart] = useState(false);
  const [qty, setQty] = useState(0);
  const [cartId, setCartId] = useState(params.data.cart_id);
  const isUserLogin = useSelector((state) => state.login);
  const cart1 = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [prod_id, setProd_id] = useState(0);

  useEffect(
    function () {
      console.log(cart1);
      _loadCartCount();
    },
    [cart1]
  );

  const _loadCartCount = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("cart/wb/count", form)
      .then(async function (response) {
        // dispatch(cart(response.data.data));
        // setCartItemCount(response.data.data);
        // alert(response.data.data);
        document.getElementById("cart_item_count").innerHTML =
          response.data.data;
        if (response.data.cart_id > 0) {
          setCartId(response.data.cart_id);
          // alert(response.data.cart_id);
        }
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const remove2Cart = () => {
    removeCart(params.data.id, params.data.name);

    setAddToCart(false);
    dispatch(cart(-1));

    if (
      Cookies.get("bhUser") != undefined &&
      Cookies.get("bhCartId") != undefined
    ) {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      // form.append("cart_id", Cookies.get("bhCartId"));
      form.append("cart_id", cartId);
      form.append("item_id", params.data.id);

      post("cart/delete_item_wb", form)
        .then(async function (response) {
          //setItems(response.data.data);
          _loadCartCount();
        })
        .catch(function (error) {});
    }
  };

  useEffect(
    function () {
      // alert("prod id" + prod_id);
    },
    [prod_id]
  );
  useEffect(function () {
    setAddToCart(isExist(params.data.id));
    setQty(getQty(params.data.id));
    setProd_id(params.data.id);

    new jBox("Tooltip", {
      delayOpen: 100,
      delayClose: 100,
      attach: ".tooltip3",
      pointer: "right:60",
      onCreated: function () {
        this.setContent("<div></div>");
      },
      getTitle: "data-jbox-title",
    });

    if (
      Cookies.get("bhCartId") == undefined &&
      Cookies.get("bhUser") != undefined
    ) {
      get("cart/id")
        .then(async function (response) {
          if (response.data.data != 0)
            Cookies.set("bhCartId", response.data.data, { expires: 2 });
        })
        .catch(function (error) {});
    }
  }, []);

  const add2Cart = (productId, name) => {
    //addCart(productId, name);

    if (Cookies.get("bhUser") != undefined) {
      console.log("user = " + Cookies.get("bhCartId"));
      addCart(productId, name);
      setAddToCart(true);
      dispatch(cart(1));
      setQty(1);
      console.log("product view user =" + Cookies.get("bhUser"));
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      form.append("product_id", productId);
      form.append("product_qty", 1);

      post("cart/store", form)
        .then(async function (response) {
          console.log("line no 139 " + response.data.cart_id);
          _loadCartCount();
          if (Cookies.get("bhCartId") == undefined) {
            console.log("line no 142 ");
            get("cart/id")
              .then(async function (response) {
                if (response.data.data != 0) {
                  Cookies.set("bhCartId", response.data.data, { expires: 2 });
                  // alert(response.data.data);
                  setCartId(response.data.data);
                }
              })
              .catch(function (error) {
                console.log("error = " + error);
                alert("something went wrong. " + error);
              });
          }
          setCartId(response.data.cart_id);
        })
        .catch(function (error) {
          alert("something went wrong. " + error);
          removeCart(productId, name);
          setAddToCart(false);
          dispatch(cart(-1));
          setQty(0);
          window.location.href = "/logout";
        });
    } else {
      if (isUserLogin.is_login == false || isUserLogin.is_login == undefined) {
        //please login to continue
        alert("Veuillez vous connecter pour continuer");
        dispatch(openLogin(true));
      }
    }
  };

  const seo_url = (url) => {
    return url
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, "");
  };

  const details = () => {
    window.location =
      "/product/" + params.data.id + "/" + seo_url(params.data.name);
  };

  const minus = () => {
    setQty((prevQty) => prevQty - 1);
    decrementCart(params.data.id, 1);

    var currentQty = qty - 1;
    document.getElementById("qtyInput_" + params.data.id).value = currentQty;

    if (parseInt(currentQty) == 0) {
      setAddToCart(false);
    }

    if (Cookies.get("bhUser") != undefined) {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      form.append("cart_id", cartId);
      form.append("item_id", params.data.id);
      form.append("quantity", 1);
      // alert("cart_id " + cartId);
      post("cart/decrement", form)
        .then(async function (response) {
          //setItems(response.data.data);
          _loadCartCount();
        })
        .catch(function (error) {
          alert("something went wrong. " + error);
        });
    }
  };

  const increment = () => {
    // alert(cartId);
    setQty((prevQty) => prevQty + 1);
    incrementCart(params.data.id, 1);

    document.getElementById("qtyInput_" + params.data.id).value = qty + 1;

    if (Cookies.get("bhUser") != undefined) {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      // form.append("cart_id", Cookies.get("bhCartId"));
      form.append("cart_id", cartId);
      form.append("item_id", params.data.id);
      form.append("quantity", 1);

      post("cart/increment", form)
        .then(async function (response) {
          //setItems(response.data.data);
        })
        .catch(function (error) {
          alert("something went wrong. " + error);
        });
    }
  };

  const changeQty = (val) => {
    val = parseInt(val);

    if (!isNaN(val) && val < 16) {
      var diff = val - qty;

      if (diff > 0) {
        incrementCart(params.data.id, Math.abs(diff));
      } else if (diff < 0) {
        decrementCart(params.data.id, Math.abs(diff));
      }

      if (parseInt(val) == 0) {
        setAddToCart(false);
      }

      setQty(val);

      if (Cookies.get("bhUser") != undefined) {
        const form = new FormData();
        form.append("butcher_id", Cookies.get("bhId"));
        form.append("cart_id", Cookies.get("bhCartId"));
        form.append("item_id", params.data.id);
        form.append("quantity", val);

        post("cart/update_qty", form)
          .then(async function (response) {
            //setItems(response.data.data);
          })
          .catch(function (error) {});
      }
    }
  };

  return (
    <div className="list_home">
      <ul>
        <li>
          {/* <a href="javascript:void(0)" style={{ cursor: "auto" }}> */}
          <a style={{ cursor: "auto" }}>
            <figure>
              {params.data.image != undefined ? (
                <img
                  onClick={(e) => details()}
                  title={params.data.name}
                  style={{ cursor: "pointer" }}
                  src={
                    process.env.REACT_APP_IMG_URL +
                    params.data.image.replace("/images", "/images/thumbnail")
                  }
                  data-src={process.env.REACT_APP_IMG_URL + params.data.image}
                  alt={params.data.name}
                  className="lazy"
                />
              ) : null}
            </figure>

            <div className="score" style={{ display: "none" }}>
              <strong>{params.data.rating}</strong>
            </div>
            <em style={{ display: "none" }}>Italian </em>
            <h3
              onClick={(e) => details()}
              style={{
                cursor: "pointer",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={params.data.name}
            >
              <span>
                {params.data.type == 1 ? (
                  <img
                    src={veg}
                    alt=""
                    style={{ width: 15, transform: "none" }}
                  />
                ) : (
                  <img
                    src={non_veg}
                    alt=""
                    style={{ width: 15, transform: "none" }}
                  />
                )}

                {params.data.product_frozen == 1 ? (
                  <img
                    src={frozen}
                    alt=""
                    style={{ width: 15, marginLeft: 8 }}
                  />
                ) : null}
              </span>
              {" " + params.data.name}
            </h3>
            <span style={{ marginTop: 4, display: "block" }}>
              <b>
                {" "}
                {params.data.unit_price} {t("currency_symbol")}
              </b>{" "}
              {params.data.discount > 0 ? (
                <font
                  style={{
                    textDecorationLine: "line-through",
                    fontSize: "0.8rem",
                  }}
                >
                  {params.data.original_price} {t("currency_symbol")}
                </font>
              ) : null}
            </span>
            {params.data.stock > 0 ? (
              <ul>
                <li>
                  <span
                    style={{
                      display: addToCart === false ? "block" : "none",
                      cursor: "pointer",
                    }}
                    className="ribbon off"
                    onClick={() => add2Cart(params.data.id, params.data.name)}
                  >
                    {t("add")}
                  </span>

                  {addToCart === true && qty > 0 ? (
                    <div
                      className="cart-plus-minus"
                      style={{ marginTop: 4, float: "left" }}
                    >
                      <button
                        onClick={() => minus()}
                        className="dec qtybutton"
                        style={{ height: 28 }}
                      >
                        -
                      </button>
                      <input
                        id={"qtyInput_" + params.data.id}
                        onChange={(e) => changeQty(e.target.value)}
                        className="cart-plus-minus-box"
                        type="text"
                        defaultValue={qty}
                        style={{
                          width: 40,
                          float: "left",
                          marginLeft: 4,
                          marginRight: 4,
                          border: "1px solid #e5e5e5",
                          height: 28,
                          textAlign: "center",
                        }}
                      />
                      <button
                        onClick={() => increment()}
                        className="inc qtybutton"
                        style={{ height: 28 }}
                      >
                        +
                      </button>
                    </div>
                  ) : null}

                  <span
                    onClick={(e) => remove2Cart()}
                    data-jbox-title={t("areyou")}
                    style={{
                      display: addToCart == true ? "block" : "none",
                      cursor: "pointer",
                      float: "left",
                      marginLeft: 10,
                      marginTop: 10,
                    }}
                    className="tooltip3"
                  >
                    <span
                      style={{ fontSize: 14 }}
                      className="fs1"
                      aria-hidden="true"
                      data-icon=""
                    ></span>{" "}
                  </span>
                </li>
              </ul>
            ) : (
              <span style={{ color: "#f30" }}>{t("out_stock")}</span>
            )}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ProductView;
