import React, { useState, useHook, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductView from "../components/ProductView";
import { productDetails, cart } from "../actions/index";
import "../styles/detail-page.css";
import "../styles/css_skelton.css";
import Cookies from "js-cookie";
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useParams } from "react-router";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Cache, { setCache } from "../utility/Cache";
import addCart, { isExist, removeCart } from "../utility/Cart";
import veg from "../images/veg.png";
import non_veg from "../images/nonveg.png";
import userImage from "../images/user.png";
import frozen from "../images/frozen.png";
import moment from "moment";

import jBox from "jbox";
import "jbox/dist/jBox.all.css";
import { Helmet } from "react-helmet";
import he from "he";

const ProductDetails = (props) => {
  const { t } = useTranslation();
  const pDetails = useSelector((state) => state.products.details);
  const [addToCart, setAddToCart] = useState(false);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [itemId, setItemId] = useState(0);
  const { id: productId } = useParams();
  const [loading, setLoading] = useState(1);

  const [referName, setReferName] = useState("");
  const [referLink, setReferLink] = useState("");

  useEffect(function () {
    var referrer = document.referrer;
    if (referrer.search("category") > 0) {
      var split = referrer.split("/");
      setReferName(split[5]);
      setReferLink(referrer);
      _getCategoryName(split[4]);
    } else if (referrer.search("popular") > 0) {
      setReferName("Articles les plus populaires");
      setReferLink(referrer);
    } else if (referrer.search("ratings") > 0) {
      setReferName("Articles les plus notés");
      setReferLink(referrer);
    }

    var CacheData = Cache("pdetails_" + productId);
    setAddToCart(isExist(productId));

    /*CacheData.then((data) => {
			if (data == false)
				_loadDetails();
			else {
				dispatch(productDetails(data.data));
				setLoading(2);
			}
		})*/

    _loadDetails();

    if (Cookies.get("bhId") == undefined) {
      Cookies.set("bhId", process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
    }

    new jBox("Tooltip", {
      delayOpen: 100,
      delayClose: 100,
      attach: ".tooltip3",
      pointer: "right:60",
      onCreated: function () {
        this.setContent("<div></div>");
      },
      getTitle: "data-jbox-title",
    });
  }, []);

  const add2Cart = () => {
    addCart(pDetails.id, pDetails.name);
    setAddToCart(true);
    dispatch(cart(1));

    if (Cookies.get("bhUser") != undefined) {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      form.append("product_id", pDetails.id);
      form.append("product_qty", 1);

      post("cart/store", form)
        .then(async function (response) {
          var item_id = response.data.item_id;
          setItemId(item_id);
          if (Cookies.get("bhCartId") == undefined) {
            get("cart/id")
              .then(async function (response) {
                if (response.data.data != 0)
                  Cookies.set("bhCartId", response.data.data, { expires: 2 });
              })
              .catch(function (error) {});
          }
        })
        .catch(function (error) {});
    }
  };

  const remove2Cart = () => {
    removeCart(pDetails.id, pDetails.name);
    setAddToCart(false);
    dispatch(cart(-1));

    if (
      Cookies.get("bhUser") != undefined &&
      Cookies.get("bhCartId") != undefined
    ) {
      const form = new FormData();
      form.append("butcher_id", Cookies.get("bhId"));
      form.append("cart_id", Cookies.get("bhCartId"));
      form.append("item_id", itemId);

      post("cart/delete_item", form)
        .then(async function (response) {
          //setItems(response.data.data);
        })
        .catch(function (error) {});
    }
  };

  const _loadDetails = () => {
    setLoading(1);
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("product_id", productId);

    post("wb/products/details", form)
      .then(async function (response) {
        dispatch(productDetails(response.data.data));
        setCache("pdetails_" + productId, response.data.data);
        setLoading(2);
      })
      .catch(function (error) {});
  };

  const _getCategoryName = (id) => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("product_id", productId);

    get("category/name/" + id, form)
      .then(async function (response) {
        setReferName(response.data.data);
      })
      .catch(function (error) {});
  };
  // if (loading === 1) return <>Loading</>;

  return (
    <div>
      <Header />

      <Helmet>
        <meta charSet="utf-8" />
        <title>{pDetails.title}</title>
        <meta name="description" content={pDetails.web_description} />
      </Helmet>

      <main>
        <div className="container margin_detail">
          <div className="row">
            <div className="col-lg-7">
              <div className="detail_page_head clearfix">
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Page d'accueil</a>
                    </li>
                    {referLink != "" ? (
                      <li>
                        <a
                          href={referLink}
                          style={{ textTransform: "capitalize" }}
                        >
                          {referName}
                        </a>
                      </li>
                    ) : null}
                    <li>{" " + pDetails.name}</li>
                  </ul>
                </div>

                <div className="title">
                  <h1 style={{ display: loading == 1 ? "none" : "block" }}>
                    <span>
                      {pDetails.type == 1 ? (
                        <img src={veg} alt="" />
                      ) : (
                        <img src={non_veg} alt="" />
                      )}
                      {pDetails.product_frozen == 1 ? (
                        <img
                          src={frozen}
                          alt=""
                          style={{ width: 25, marginLeft: 10 }}
                        />
                      ) : null}
                    </span>
                    {" " + pDetails.name}
                  </h1>
                  <div style={{ display: loading == 1 ? "block" : "none" }}>
                    <div
                      style={{
                        width: 24,
                        height: 24,
                        float: "left",
                        marginRight: 10,
                      }}
                      className="skeleton-loader"
                    ></div>
                    <div
                      style={{ width: 200, height: 24, float: "left" }}
                      className="skeleton-loader"
                    ></div>
                  </div>
                  &nbsp;
                </div>
                <div className="rating" style={{ display: "none" }}>
                  <div className="score">
                    <span>
                      Superb
                      <em>
                        {pDetails.rated} {t("reviews")}
                      </em>
                    </span>
                    <strong>{pDetails.rating}</strong>
                  </div>
                </div>
              </div>

              {loading === 1 ? (
                <div>
                  <OwlCarousel
                    items={1}
                    dots={true}
                    className="owl-theme magnific-gallery"
                    margin={10}
                  >
                    <div
                      style={{ width: "100%", height: 400, float: "left" }}
                      className="skeleton-loader"
                    ></div>
                  </OwlCarousel>
                </div>
              ) : (
                <OwlCarousel
                  items={1}
                  dots={true}
                  className="owl-theme magnific-gallery"
                  margin={10}
                >
                  {pDetails.images.map((i, k) => (
                    <div key={i.image} className="item">
                      <a
                        href="#"
                        title={pDetails.name}
                        data-effect="mfp-zoom-in"
                      >
                        <img
                          src={process.env.REACT_APP_IMG_URL + i.image}
                          alt=""
                          style={{ width: 640, height: 360 }}
                        />
                      </a>
                    </div>
                  ))}
                </OwlCarousel>
              )}

              <div className="tabs_detail">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      id="tab-A"
                      href="#pane-A"
                      className="nav-link active"
                      data-toggle="tab"
                      role="tab"
                    >
                      Information
                    </a>
                  </li>
                  <li className="nav-item" style={{ display: "none" }}>
                    <a
                      id="tab-B"
                      href="#pane-B"
                      className="nav-link"
                      data-toggle="tab"
                      role="tab"
                    >
                      {t("reviews")}
                    </a>
                  </li>
                </ul>

                <div className="tab-content" role="tablist">
                  <div
                    id="pane-A"
                    className="card tab-pane fade show active"
                    role="tabpanel"
                    aria-labelledby="tab-A"
                  >
                    <div className="card-header" role="tab" id="heading-A">
                      <h5>
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          href="#collapse-A"
                          aria-expanded="true"
                          aria-controls="collapse-A"
                        >
                          {t("details")}
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapse-A"
                      className="collapse"
                      role="tabpanel"
                      aria-labelledby="heading-A"
                    >
                      <div className="card-body info_content">
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {pDetails.description != undefined &&
                            he.decode(pDetails.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5" id="sidebar_fixed">
              <div className="box_booking">
                <h5 style={{ display: "none" }}>
                  {t("related_products")} <hr />
                </h5>

                {loading === 1 ? (
                  <div className="row">
                    {pDetails.similar_products != undefined
                      ? pDetails.similar_products.map((s, k) => (
                          <div
                            key={k + "1"}
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          >
                            <div
                              style={{
                                width: "100%",
                                height: 100,
                                float: "left",
                              }}
                              className="skeleton-loader"
                            ></div>
                          </div>
                        ))
                      : null}
                  </div>
                ) : (
                  <div className="row">
                    {pDetails.similar_products != undefined
                      ? pDetails.similar_products.map((s, k) => (
                          <div
                            key={k + "1"}
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          >
                            <ProductView data={s} key={k} />
                          </div>
                        ))
                      : null}
                  </div>
                )}

                <div style={{ padding: 20, display: "none" }}>
                  <h5>€ {pDetails.unit_price}</h5>
                  {loading === 2 ? (
                    pDetails.stock > 0 ? (
                      <div className="product-available in-stock">
                        <span className="stock">{t("instock")}</span>
                      </div>
                    ) : (
                      <span style={{ color: "#f30" }}>{t("out_stock")}</span>
                    )
                  ) : null}

                  <br />
                  <div>
                    {pDetails.description != undefined
                      ? pDetails.description.substring(0, 100)
                      : null}
                    ...
                  </div>

                  <br />
                  <br />
                  <br />
                  {pDetails.stock > 0 ? (
                    addToCart == false ? (
                      <a
                        onClick={(e) => add2Cart()}
                        href="#"
                        className="btn_1 full-width mb_5"
                      >
                        {t("addcart")}
                      </a>
                    ) : (
                      <span
                        onClick={(e) => remove2Cart()}
                        data-jbox-title={t("areyou")}
                        style={{
                          fontWeight: 500,
                          border: "2px solid #111",
                          borderRadius: 3,
                          padding: 5,
                          textAlign: "center",
                          display: addToCart == true ? "block" : "none",
                          cursor: "pointer",
                        }}
                        className="tooltip3 full-width outline wishlist"
                      >
                        <span
                          style={{ fontSize: 11 }}
                          className="fs1"
                          aria-hidden="true"
                          data-icon=""
                        ></span>{" "}
                        {t("remove")}
                      </span>
                    )
                  ) : null}
                  <br />
                  {loading === 2 ? (
                    <a
                      href="wishlist.html"
                      className="btn_1 full-width outline wishlist"
                    >
                      <i className="icon_heart"></i> {t("addwishlist")}
                    </a>
                  ) : null}

                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <ul className="share-buttons" style={{ display: "none" }}>
                <li>
                  <a className="fb-share" href="#0">
                    <i className="social_facebook"></i> Share
                  </a>
                </li>
                <li>
                  <a className="twitter-share" href="#0">
                    <i className="social_twitter"></i> Share
                  </a>
                </li>
                <li>
                  <a className="gplus-share" href="#0">
                    <i className="social_googleplus"></i> Share
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {loading === 1 ? (
            <div>
              <div className="row" style={{ display: "none" }}>
                <div className="col-lg-12 col-xs-12 col-sm-12">
                  <h5>{t("related_products")}</h5>

                  <div
                    style={{ width: "100%", height: 400, float: "left" }}
                    className="skeleton-loader"
                  ></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row" style={{ display: "none" }}>
              <div className="col-lg-12 col-xs-12 col-sm-12">
                <h5>{t("related_products")}</h5>
                <hr />
                <div className="row">
                  {pDetails.similar_products != undefined
                    ? pDetails.similar_products.map((s, k) => (
                        <div
                          key={k + "2"}
                          className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                        >
                          <ProductView data={s} key={k} />
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default ProductDetails;
