import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import "../styles/contacts.css";
import delivery from "../images/delivery.jpg";
import { store } from "react-notifications-component";
import "react-responsive-modal/styles.css";
import Cookies from "js-cookie";
import { post, get } from "../adapters/index";

import thumbnail2 from "../images/thumbnail2.jpg";
import chronofresh from "../images/chronofresh.mp4";
import t33 from "../images/33.png";
import t44 from "../images/44.png";
import banner_engagement from "../images/banner_engagement.jpg";

const OurCertifications = () => {
  return (
    <div>
      <HeaderHome popup={false} />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + delivery + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Commandez à tout instant</h1>
                <p>
                  & faites-vous livrer dès le lendemain suivant vos
                  disponibilités
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container margin_60_40 normal">
          <div className="main_title">
            <span>
              <em></em>
            </span>
            <h2>Nos frais de livraison</h2>
            <br />
            <p style={{ fontSize: 15 }}>
              Fini de chercher une place de parking, fini les files d’attente
              interminables aux caisses, fini de porter vos courses.
            </p>

            <p style={{ fontSize: 15 }}>
              B.E HALAL vous simplifie la vie et vous propose plus de 300
              produits afin de vous permettre de faire vos courses où que vous
              soyez, en quelques clics.
            </p>

            <p style={{ fontSize: 15 }}>
              Nous assurons des livraisons Nous assurons des livraisons en J+1
              pour toute commande faite avant 12h et en J+2 pour toute commande
              faite après 12h.
            </p>

            <p style={{ fontSize: 15 }}>
              En effet, notre partenaire Chronofresh vous livre vos colis dans
              des camions frigorifiques afin de ne pas briser la chaîne du
              froid.
            </p>

            <p style={{ fontSize: 15 }}>
              Dans tous les cas, vous êtes libre de choisir le créneau de
              livraison qui vous convient.
            </p>
            <br />
            {/* <h2>MODALITES DE LIVRAISON : </h2> */}
            <h2>Modalites de livraison : </h2>
            <br />
            <p style={{ fontSize: 15 }}>
              - Pour les départements du 75, 92, 93 et 94, il y a 3 créneaux de
              livraison : 8h-12h ; 12h-16h ; 16h-20h.
            </p>
            <p style={{ fontSize: 15 }}>
              - Pour les départements 77, 78, 91, 95 les livraisons sont
              assurées UNIQUEMENT le matin entre 8h-13h.
            </p>
            <p style={{ fontSize: 15 }}>
              - NATIONAL (tous départements hors Ile-de-France) : les livraisons
              sont assurées de 8h à 13h UNIQUEMENT.
            </p>
            <p style={{ fontSize: 15 }}>
              - Livraison uniquement en France métropolitaine.{" "}
            </p>
            <p style={{ fontSize: 15 }}>
              -  Les Livraisons sont assurées du mardi au samedi.
            </p>
          </div>
          <br />
          <br />
          <div className="row plans">
            <div className="plan col-md-3">
              <div className="plan-title">
                <h3>Départements</h3>
                <p>Hors Île de France*</p>
              </div>
              <ul className="plan-features">
                <li>
                  Montant de la commande <strong> 129€ - 189€</strong>
                </li>
              </ul>
              <p className="plan-price">14.9€</p>
              <ul className="plan-features">
                <li>
                  Montant de la commande <strong> au-delà de 189€</strong>
                </li>
              </ul>
              <p className="plan-price2">GRATUIT</p>
            </div>

            <div className="plan col-md-3">
              <div className="plan-title">
                <h3>Départements</h3>
                <p>77, 78, 91, 95</p>
              </div>
              <ul className="plan-features">
                <li>
                  Montant de la commande <strong> 89€ - 149€</strong>
                </li>
              </ul>
              <p className="plan-price">9.9€</p>
              <ul className="plan-features">
                <li>
                  Montant de la commande <strong> au-delà de 149€</strong>
                </li>
              </ul>
              <p className="plan-price2">GRATUIT</p>
            </div>

            <div className="plan col-md-3">
              <div className="plan-title">
                <h3>Départements</h3>
                <p>92, 93, 94</p>
              </div>
              <ul className="plan-features">
                <li>
                  Montant de la commande <strong> 60€ - 99€</strong>
                </li>
              </ul>
              <p className="plan-price">9.9€</p>
              <ul className="plan-features">
                <li>
                  Montant de la commande <strong> au-delà de 99€</strong>
                </li>
              </ul>
              <p className="plan-price2">GRATUIT</p>
            </div>

            <div className="plan col-md-3">
              <div className="plan-title">
                <h3>Département</h3>
                <p>75</p>
              </div>
              <ul className="plan-features">
                <li>
                  Montant de la commande <strong> 60€ - 99€</strong>
                </li>
              </ul>
              <p className="plan-price">9.9€</p>
              <ul className="plan-features">
                <li>
                  Montant de la commande <strong> au-delà de 99€</strong>
                </li>
              </ul>
              <p className="plan-price2">GRATUIT</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_gray">
        <div className="center container margin_60_40">
          <div className="main_title center">
            <span>
              <em></em>
            </span>
            <h2>Découvrez ChronoFresh !</h2>
          </div>

          <video
            className="center"
            controls
            width="600"
            height="400"
            autoplay
            loop
            preload="auto"
            poster={thumbnail2}
          >
            <source src={chronofresh} type="video/mp4" />
            <p>Your browser doesn't support HTML video.</p>
          </video>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurCertifications;
