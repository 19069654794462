import React, { useState, useEffect, useRef, useParams } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/detail-page.css";
import Cookies from "js-cookie";
import { post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CartList from "../components/CartList";
import { reactLocalStorage } from "reactjs-localstorage";
import Checkout from "./Checkout";
import { openLogin, openLoginAfter } from "../actions/index";
import PulseLoader from "react-spinners/PulseLoader";

const Popular = (props) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(0);
  const { t } = useTranslation();

  useEffect(function () {
    var c = window.client;
    if (Cookies.get("bhId") == undefined) {
      Cookies.set("bhId", process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
    }
    _loadMostPopular();
  }, []);

  const _loadMostPopular = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("wb/products/popular/all", form)
      .then(async function (response) {
        setProducts(response.data.data);
      })
      .catch(function (error) {});
  };

  const seo_url = (url) => {
    return url
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, "");
  };

  return (
    <div>
      <Header />
      <main>
        <div className="page_header element_to_stick">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
                <h1> Articles les plus populaires</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container margin_30_40">
          <div className="row">
            {loading === 1 ? (
              <div className="col-lg-12 text-center">
                <PulseLoader color="#83c55e" size={8} />
              </div>
            ) : (
              <></>
            )}

            {loading === 2 && products.length === 0 ? (
              <div className="col-lg-12 text-center">
                <span>Produits non trouvés</span>
              </div>
            ) : (
              <></>
            )}

            <div className="col-lg-12">
              <div className="row">
                {products.map((p, k) => (
                  <div
                    key={p.id}
                    className="col-xl-3 col-lg-6 col-md-6 col-sm-6"
                  >
                    <div className="strip">
                      <figure>
                        <img
                          style={{ top: "57%", height: 217 }}
                          src={process.env.REACT_APP_IMG_URL + p.image}
                          data-src={process.env.REACT_APP_IMG_URL + p.image}
                          className="img-fluid lazy"
                          alt=""
                        />
                        <a
                          href={
                            "/product/" +
                            p.id +
                            "/" +
                            seo_url(p.name + "-" + p.qty)
                          }
                          className="strip_info"
                        >
                          <div className="item_title">
                            <h3>
                              {p.name} {p.qty_type}
                            </h3>
                          </div>
                        </a>
                      </figure>
                      <ul>
                        <li>
                          <span>{p.price} Euros</span>
                        </li>
                        <li>
                          <div className="score">
                            <span>
                              <em>{p.rated} Ratings</em>
                            </span>
                            <strong>{p.rating}</strong>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination_fg" style={{ display: "none" }}>
                <a href="#">&laquo;</a>
                <a href="#" className="active">
                  1
                </a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#">&raquo;</a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Popular;
