const productReducer = (state = {popular:[], ratings: [], details: {}}, action) => {
    switch(action.type) {
        case 'POPULAR_PRODUCT':
            state.popular = action.payLoad;
            return state;
        case 'RATINGS_PRODUCT':
            state.ratings = action.payLoad;
            return state;
        case 'PRODUCT_DETAILS':
            state.details = action.payLoad;
            return state;
        default:
            return state;
    }
}

export default productReducer;