import React, { useState, useHook, useEffect, useRef } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductView from "../components/ProductView";
import { productDetails, cart } from "../actions/index";
import "../styles/detail-page.css";
import "../styles/css_skelton.css";
import Cookies from "js-cookie";
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import PulseLoader from "react-spinners/PulseLoader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProductSearch = () => {
  let query = useQuery();

  //console.log(query.get("location"))
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(0);
  const [locationError, setLocationError] = useState(false);
  const { t } = useTranslation();

  useEffect(function () {
    var c = window.client;
    if (Cookies.get("bhId") == undefined) {
      Cookies.set("bhId", process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
    }
    _loadSearchProducts();
  }, []);

  const _loadSearchProducts = () => {
    setLoading(1);
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("term", query.get("q"));
    form.append("location", query.get("location"));

    post("wb/products/search", form)
      .then(async function (response) {
        if (response.data.message === "failed") {
          //alert('invalid');
          setLocationError(true);
        } else {
          setProducts(response.data.data);
        }
        setLoading(2);
      })
      .catch(function (error) {});
  };

  const seo_url = (url) => {
    return url
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, "");
  };

  return (
    <div>
      <Header />
      <main>
        <div className="page_header element_to_stick">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
                <h1> Résultats de la recherche </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container margin_30_40">
          <div className="row">
            {loading === 1 ? (
              <div className="col-lg-12 text-center">
                <PulseLoader color="#83c55e" size={8} />
              </div>
            ) : (
              <></>
            )}

            {loading === 2 &&
            products.length === 0 &&
            locationError === false ? (
              <div className="col-lg-12 text-center">
                <span>aucun produit trouvé</span>
              </div>
            ) : (
              <></>
            )}

            {loading === 2 &&
            products.length === 0 &&
            locationError === true ? (
              <div className="col-lg-12 text-center">
                <span>
                  La livraison du produit n'est pas disponible votre emplacement
                </span>
              </div>
            ) : (
              <></>
            )}

            <div className="col-lg-12">
              <div className="row">
                {products.length > 0 && products != undefined
                  ? products.map((p, k) => (
                      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <div className="strip">
                          <figure>
                            <img
                              style={{ top: "57%", height: 217 }}
                              src={process.env.REACT_APP_IMG_URL + p.image}
                              data-src={process.env.REACT_APP_IMG_URL + p.image}
                              className="img-fluid lazy"
                              alt=""
                            />
                            <a
                              href={
                                "/product/" +
                                p.id +
                                "/" +
                                seo_url(p.name + "-" + p.qty_type)
                              }
                              className="strip_info"
                            >
                              <div className="item_title">
                                <h3>
                                  {p.name} {p.qty_type}
                                </h3>
                              </div>
                            </a>
                          </figure>
                          <ul>
                            <li>
                              <span>{p.unit_price} Euros</span>
                            </li>
                            <li>
                              <div className="score">
                                <span>
                                  <em>{p.num_ratings} Ratings</em>
                                </span>
                                <strong>{p.ratings}</strong>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <div className="pagination_fg" style={{ display: "none" }}>
                <a href="#">&laquo;</a>
                <a href="#" className="active">
                  1
                </a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#">&raquo;</a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ProductSearch;
