import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import "../styles/contacts.css";
import contact from "../images/contact.jpg";
import { store } from "react-notifications-component";
import "react-responsive-modal/styles.css";
import Cookies from "js-cookie";
import { post, get } from "../adapters/index";

const Contact = () => {
  Element.prototype.insertAfter = function (newEl) {
    this.parentNode.insertBefore(newEl, this.nextSibling);
  };

  const contactus = () => {
    var error = false;
    var ele = document.querySelectorAll(".error");

    for (var j = 0; j < ele.length; j++) {
      ele[j].remove();
    }

    var name_contact = document.getElementById("name_contact");
    var email_contact = document.getElementById("email_contact");
    var telephone_register = document.getElementById("telephone_register");
    var message_contact = document.getElementById("message_contact");

    if (name_contact.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "s'il vous plaît entrez le nom";
      name_contact.insertAfter(newEll);
      error = true;
    }

    if (email_contact.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "veuillez entrer l'adresse e-mail";
      email_contact.insertAfter(newEll);
      error = true;
    }

    if (telephone_register.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "veuillez entrer le numéro de téléphone";
      telephone_register.insertAfter(newEll);
      error = true;
    }

    if (message_contact.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "s'il vous plaît entrer un message";
      message_contact.insertAfter(newEll);
      error = true;
    }

    var details = {
      name_contact: name_contact.value,
      email_contact: email_contact.value,
      telephone_register: telephone_register.value,
      message_contact: message_contact.value,
    };

    if (error === true) {
      return false;
    }

    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("page", "contact");
    form.append("details", JSON.stringify(details));

    post("/wb/admin/contactus", form)
      .then(async function (response) {
        name_contact.value = "";
        email_contact.value = "";
        telephone_register.value = "";
        message_contact.value = "";
        alert("Soumis avec succès");
      })
      .catch(function (error) {});
  };

  return (
    <div>
      <HeaderHome />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + contact + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Contactez B.e Halal</h1>
                <p>
                  Appelez-nous, écrivez-nous Ou remplissez le formulaire
                  ci-dessous
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_gray">
        <div className="container margin_60_40">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="box_contacts">
                <i className="icon_phone"></i>
                <br />
                <br />
                <a href="tel:+330181850735">01 81 85 07 35</a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="box_contacts">
                <i className="icon_mail"></i>
                <br />
                <br />
                <a href="mailto:contact@behalal.fr">contact@behalal.fr</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container margin_60_40">
        <h5 className="mb_5">Formulaire de contact</h5>
        <div className="row">
          <div className="col-lg-12 col-md-12 add_bottom_25">
            <div id="message-contact"></div>
            <form action="javascript:void(0);">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Nom"
                  id="name_contact"
                  name="name_contact"
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  id="email_contact"
                  name="email_contact"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Numero de telephone"
                  name="telephone_register"
                  id="telephone_register"
                />
              </div>

              <div className="form-group">
                <textarea
                  className="form-control"
                  style={{ height: 150 }}
                  placeholder="Message"
                  id="message_contact"
                  name="message_contact"
                ></textarea>
              </div>
              <div className="form-group">
                <input
                  className="btn_1 full-width"
                  type="submit"
                  value="Soumettre"
                  id="submit-contact"
                  onClick={() => contactus()}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
