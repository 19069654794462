import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import "../styles/contacts.css";
import terms from "../images/terms.jpg";

const Cgv = () => {
  return (
    <div>
      <HeaderHome />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + terms + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Conditions Générales de Vente</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container margin_30_40">
        <div className="row">
          <div className="col-lg-12">
            <div className="singlepost2">
              <h1>Conditions Générales de Vente</h1>

              <div className="post-content">
                <p>
                  Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de
                  vente (&laquo; <strong>CGV</strong> &raquo;) sont
                  propos&eacute;es par la soci&eacute;t&eacute; B.e Halal,
                  soci&eacute;t&eacute; par actions simplifi&eacute;es au
                  capital de 10000 &euro;, dont le si&egrave;ge social est
                  situ&eacute; au 11 rue des Pyr&eacute;n&eacute;es 91090
                  Lisses, enregistr&eacute;e au Registre du Commerce et des
                  Soci&eacute;t&eacute;s d&rsquo;Evry sous le num&eacute;ro 897
                  857 660
                </p>
                <p>
                  Les CGV ont pour objet de r&eacute;gir les conditions
                  d&rsquo;utilisation du site accessible &agrave;
                  l&rsquo;adresse www.behalal.fr par les clients inscrits sur le
                  Site tels que d&eacute;finis ci- apr&egrave;s, ainsi que par
                  les visiteurs du Site.
                </p>
                <p>
                  L&rsquo;application est h&eacute;berg&eacute;e par B.e Halal
                </p>
                <p>
                  Le directeur de la publication est Monsieur El Kasmi
                  Sa&iuml;d.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 1 : D&Eacute;</u>
                  </strong>
                  <strong>
                    <u>FINITIONS</u>
                  </strong>
                </p>
                <p>
                  <strong>Client</strong> d&eacute;signe toute personne
                  particuli&egrave;re souhaitant faire l&rsquo;acquisition
                  d&rsquo;un produit propos&eacute; &agrave; la vente sur la
                  Plateforme.
                </p>
                <p>
                  <strong>Annonce</strong> d&eacute;signe toute offre de vente
                  d&rsquo;un ou plusieurs Produit(s) publi&eacute;e par B.E
                  HALAL.
                </p>
                <p>
                  <strong>Compte</strong> d&eacute;signe l&rsquo;espace
                  personnel de chaque Utilisateur sur la Plateforme.
                </p>
                <p>
                  <strong>Plateforme</strong> d&eacute;signe l&rsquo;outil mis
                  &agrave; disposition par la soci&eacute;t&eacute; sur le Site
                  permettant la vente de produits 100 % halal aux visiteurs.
                </p>
                <p>
                  <strong>Produit</strong> d&eacute;signe le produit
                  propos&eacute; &agrave; la vente par la soci&eacute;t&eacute;
                  sur la Plateforme.
                </p>
                <p>
                  <strong>Site</strong> d&eacute;signe le site Internet
                  accessible &agrave; l&rsquo;adresse www.behalal.fr
                </p>
                <p>
                  <strong>Soci&eacute;t&eacute;</strong> d&eacute;signe la
                  soci&eacute;t&eacute; B.e Halal, soci&eacute;t&eacute; par
                  actions simplifi&eacute;es au capital de 10000 &euro;, dont le
                  si&egrave;ge social est situ&eacute; au 11 rue des
                  Pyr&eacute;n&eacute;es 91090 &agrave; Lisses,
                  enregistr&eacute;e au Registre du Commerce et des
                  Soci&eacute;t&eacute;s d&rsquo;Evry sous le num&eacute;ro 897
                  857 660.
                </p>
                <p>
                  <strong>Utilisateur</strong> d&eacute;signe &agrave; la fois
                  un Visiteur et un Client pris individuellement ou un Visiteur
                  et un Client pris ensemble.
                </p>
                <p>
                  <strong>Visiteur</strong> d&eacute;signe toute personne
                  acc&eacute;dant au Site.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 2 : OBJET</u>
                  </strong>
                </p>
                <p>
                  La Soci&eacute;t&eacute; propose sur le Site une Plateforme
                  permettant la recherche et/ou la vente de produits 100% halal
                  par l&rsquo;interm&eacute;diaire de la plateforme (la &laquo;
                  Plateforme &raquo;).&nbsp;
                </p>
                <p>
                  Les CGV r&eacute;gissent les conditions d&rsquo;utilisation de
                  la Plateforme et les r&egrave;gles applicables &agrave; la
                  relation entre la Soci&eacute;t&eacute; et les Utilisateurs.
                </p>
                <p>
                  Les pr&eacute;sentes CGV remplacent et annulent tout accord
                  ant&eacute;rieur, &eacute;crit ou oral, entre la
                  Soci&eacute;t&eacute; et les Utilisateurs et contient
                  l&rsquo;entier accord entre eux.
                </p>
                <p>
                  Le fait pour tout Utilisateur de visiter le Site constitue une
                  acceptation irr&eacute;vocable et sans r&eacute;serve de
                  l&rsquo;int&eacute;gralit&eacute; des stipulations des
                  pr&eacute;sentes.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 3: DISPONIBILIT</u>
                  </strong>
                  <strong>
                    <u>&Eacute; </u>
                  </strong>
                  <strong>
                    <u>DE LA PLATEFORME.</u>
                  </strong>
                </p>
                <p>
                  La Plateforme est accessible 24 heures sur 24 et 7 jours sur
                  7.
                </p>
                <p>
                  Toutefois, la Soci&eacute;t&eacute; ne fournit aucune garantie
                  concernant cette disponibilit&eacute;.
                </p>
                <p>
                  Ainsi, les Utilisateurs sont inform&eacute;s et reconnaissent
                  que l&rsquo;acc&egrave;s &agrave; la Plateforme pourra
                  &ecirc;tre suspendu ou alt&eacute;r&eacute; notamment pour des
                  op&eacute;rations de maintenance et ce, sans indemnisation
                  possible des Utilisateurs.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 4 : CONDITIONS D</u>
                  </strong>
                  <strong>
                    <u>&rsquo;</u>
                  </strong>
                  <strong>
                    <u>INSCRIPTION SUR LE SITE ET LA PLATEFORME.</u>
                  </strong>
                </p>
                <p>
                  Seuls les Utilisateurs &acirc;g&eacute;s de plus de 18 ans
                  sont autoris&eacute;s &agrave; s&rsquo;inscrire sur la
                  Plateforme.
                </p>
                <p>
                  L&rsquo;Utilisateur qui souhaite b&eacute;n&eacute;ficier des
                  services offerts par la Plateforme est invit&eacute; &agrave;
                  y cr&eacute;er un Compte en se connectant au Site et en
                  suivant les instructions qui lui sont indiqu&eacute;es
                  &agrave; cet &eacute;gard :
                </p>
                <p>
                  <strong>4.1 Cr&eacute;ation d</strong>
                  <strong>&rsquo;</strong>
                  <strong>un compte</strong>
                </p>
                <p>
                  Plus particuli&egrave;rement, l&rsquo;Utilisateur doit
                  compl&eacute;ter et renseigner un certain nombre
                  d&rsquo;informations le concernant et notamment sans que cela
                  soit limitatif :
                </p>
                <p>
                  &ndash; Identit&eacute; (nom, pr&eacute;nom ou raison sociale
                  le cas &eacute;ch&eacute;ant),
                </p>
                <p>&ndash; Adresse postale,</p>
                <p>&ndash; Adresse &eacute;lectronique,</p>
                <p>&ndash; T&eacute;l&eacute;phone,</p>

                <p>
                  Il est en outre pr&eacute;cis&eacute; que la
                  Soci&eacute;t&eacute; se r&eacute;serve le droit de demander
                  des documents ou &eacute;l&eacute;ments suppl&eacute;mentaires
                  aux Utilisateurs en tant que de besoin et &agrave; sa libre
                  discr&eacute;tion tels que, sans que cela ne soit limitatif
                  des justificatifs d&rsquo;identit&eacute;.
                </p>

                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 5 : CONDITIONS DE R&Eacute;F&Eacute;</u>
                  </strong>
                  <strong>
                    <u>RENCEMENT DES PRODUITS SUR LA PLATEFORME</u>
                  </strong>
                </p>
                <p>
                  <strong>5.1 Nature des Produits</strong>
                </p>
                <p>
                  Cela inclut notamment, sans que cela soit limitatif, les
                  produits que peut proposer de fa&ccedil;on
                  r&eacute;guli&egrave;re la soci&eacute;t&eacute;.
                </p>
                <p>
                  <strong>5.2 Description des Produits</strong>
                </p>
                <p>
                  Dans le cadre du r&eacute;f&eacute;rencement d&rsquo;un
                  Produit sur la Plateforme, la soci&eacute;t&eacute; doit
                  indiquer les caract&eacute;ristiques du Produit vendu et
                  notamment sans que cela soit limitatif :
                </p>
                <p>&ndash; Photographies du bien,</p>
                <p>&ndash; Caract&eacute;ristiques du bien,</p>

                <p>&ndash; Prix de vente</p>
                <p>
                  La soci&eacute;t&eacute; s&rsquo;oblige &agrave; n&rsquo;y
                  faire figurer que des informations exactes, sinc&egrave;res et
                  l&eacute;gales, etc., excluant notamment tout contenu (texte
                  ou image) &agrave; caract&egrave;re raciste, obsc&egrave;ne,
                  pornographique, etc.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 6 : PRIX DES PRODUITS</u>
                  </strong>
                </p>
                <p>
                  Le prix des Produits vendus sur la Plateforme pourra
                  &ecirc;tre modifi&eacute; &agrave; tout moment par la
                  soci&eacute;t&eacute;.
                </p>
                <p>
                  Le prix affich&eacute; sur les Annonces est exprim&eacute;
                  TTC.
                </p>
                <p>Il ne tient pas compte des frais de livraison du Produit.</p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 7 : PAIEMENT DES PRODUITS</u>
                  </strong>
                </p>
                <p>
                  Outre les services de r&eacute;f&eacute;rencement, la
                  Soci&eacute;t&eacute; propose un service de paiement
                  int&eacute;gr&eacute; &agrave; la Plateforme par lequel les
                  Clients paient les produits.
                </p>
                <p>
                  Pour ce faire, la Soci&eacute;t&eacute; utilise les services
                  de la soci&eacute;t&eacute; PayGreen.
                </p>
                <p>
                  Ainsi, les Clients qui souhaitent faire l&rsquo;acquisition
                  d&rsquo;un Produit sur la Plateforme sont dirig&eacute;s vers
                  un serveur de paiement s&eacute;curis&eacute;
                  d&eacute;di&eacute; mis en place par la soci&eacute;t&eacute;
                  B.e Halal.
                </p>
                <p>
                  Les Clients proc&egrave;dent ainsi au paiement du prix
                  indiqu&eacute; et les &eacute;ventuels frais de livraison. Par
                  cet acte d&rsquo;achat, le Client accepte que les informations
                  n&eacute;cessaires &agrave; la finalisation de la transaction
                  soient communiqu&eacute;es sur la Plateforme.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 8 : EXP&Eacute;</u>
                  </strong>
                  <strong>
                    <u>DITION ET LIVRAISON DES PRODUITS</u>
                  </strong>
                </p>
                <p>
                  La Soci&eacute;t&eacute; proc&egrave;de directement &agrave;
                  la pr&eacute;paration des Produits et les remet ensuite
                  &agrave; la soci&eacute;t&eacute;
                  &laquo;&nbsp;CHRONOFRESH&nbsp;&raquo; pour effectuer la
                  livraison chez les Clients.
                </p>
                <p>
                  La soci&eacute;t&eacute; B.E HALAL prend soin d&rsquo;assurer
                  la livraison en bonne et due forme, en proc&eacute;dant
                  notamment &agrave; un emballage soigneux du Produit et
                  d&rsquo;en assurer la tra&ccedil;abilit&eacute; en conservant
                  et partageant sur son Compte les &eacute;l&eacute;ments
                  transmis par l&rsquo;op&eacute;rateur de livraison (date
                  d&rsquo;exp&eacute;dition, date de livraison pr&eacute;vue,
                  etc.).
                </p>
                <p>
                  D&egrave;s confirmation du paiement du Produit par le Client,
                  la soci&eacute;t&eacute; B.E HALAL s&rsquo;engage &agrave;
                  exp&eacute;dier dans un délai de 24h pour toute commande faite
                  avant 12h et 48h pour toute commande faite après 12h le
                  Produit au Client en utilisant le mode
                  d&rsquo;exp&eacute;dition convenu.
                </p>
                <p>
                  &Agrave; r&eacute;ception du Produit par le Client, ce dernier
                  s&rsquo;engage &agrave; confirmer sa bonne livraison et sa
                  conformit&eacute; en signant le bon de livraison
                  pr&eacute;sent&eacute; par le livreur.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 9: LIMITATION DE LA RESPONSABILIT</u>
                  </strong>
                  <strong>&Eacute; </strong>
                  <strong>DE LA SOCI</strong>
                  <strong>
                    <u>&Eacute;T&Eacute;</u>
                  </strong>
                </p>
                <p>
                  La Soci&eacute;t&eacute; s&rsquo;engage &agrave; mettre en
                  &oelig;uvre tous les moyens n&eacute;cessaires &agrave; sa
                  disposition afin d&rsquo;assurer la fourniture et la mise
                  &agrave; disposition de la Plateforme aux Utilisateurs.
                </p>
                <p>
                  Toutefois, la Soci&eacute;t&eacute; ne saurait &ecirc;tre
                  tenue responsable dans les cas suivants :
                </p>
                <p>
                  &ndash; interruptions, pannes, modifications et
                  dysfonctionnements de la Plateforme ;
                </p>
                <p>&ndash; perte de donn&eacute;es ou d&rsquo;informations ;</p>
                <p>
                  &ndash; impossibilit&eacute; d&rsquo;acc&eacute;der &agrave;
                  la Plateforme ;
                </p>
                <p>
                  &ndash; dommages directs ou indirects caus&eacute;s aux
                  Utilisateurs, quelle qu&rsquo;en soit la raison,
                  r&eacute;sultant des Produits et/ou de l&rsquo;acc&egrave;s et
                  de l&rsquo;utilisation de la Plateforme.
                </p>
                <p>
                  &ndash; &eacute;ventuelles d&eacute;gradations de
                  mat&eacute;riel, logiciel, donn&eacute;es subies par
                  l&rsquo;Utilisateur du fait de son utilisation du Site,
                  notamment dans le cas d&rsquo;une attaque virale ou de la
                  propagation d&rsquo;un logiciel malveillant.
                </p>
                <p>
                  La responsabilit&eacute; de la Soci&eacute;t&eacute; ne pourra
                  &ecirc;tre engag&eacute;e que pour les dommages directs subis
                  par un Utilisateur, r&eacute;sultant uniquement d&rsquo;un
                  manquement &agrave; ses obligations contractuelles telles que
                  d&eacute;finies aux pr&eacute;sentes CGV.
                </p>
                <p>
                  Les Utilisateurs renoncent par avance &agrave; toute demande
                  de r&eacute;paration &agrave; quelque titre que ce soit, des
                  dommages indirects tels que le manque &agrave; gagner, le
                  pr&eacute;judice commercial ou financier r&eacute;sultant de
                  l&rsquo;utilisation de la Plateforme.
                </p>
                <p>
                  Tout Utilisateur est seul responsable des dommages
                  caus&eacute;s aux tiers et des cons&eacute;quences des
                  r&eacute;clamations ou actions qui pourraient en
                  d&eacute;couler.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>
                      ARTICLE 10 : Gestion des réclamations, Rétractation,
                      Retour
                    </u>
                  </strong>
                </p>
                <p>
                  Le r&egrave;glement des &eacute;ventuelles r&eacute;clamations
                  &agrave; propos de l&rsquo;achat d&rsquo;un Produit sur la
                  Plateforme par un Client est de sa seule
                  responsabilit&eacute;.
                </p>
                <p>
                  <strong> 10.1 R</strong>
                  <strong>&egrave;</strong>
                  <strong>gles g&eacute;n&eacute;</strong>
                  <strong>rales</strong>
                </p>
                <p>
                  Les signalements de r&eacute;clamations sont &agrave; adresser
                  par mail &agrave; l&rsquo;adresse{" "}
                  <a href="mailto:support@behalal.fr">support@behalal.fr</a> ou
                  par Lettre recommand&eacute;e avec accus&eacute; de
                  r&eacute;ception (LRAR) avec toutes les pr&eacute;cisions
                  n&eacute;cessaires &agrave; leur compr&eacute;hension.
                </p>
                <p>
                  La Soci&eacute;t&eacute; est seule arbitre des
                  r&eacute;clamations qui lui sont adress&eacute;es. Elle peut
                  notamment et sans avoir &agrave; s&rsquo;en expliquer, les
                  rejeter si elle les estime infond&eacute;es. La
                  Soci&eacute;t&eacute; n&rsquo;assure aucune garantie de
                  r&egrave;glement des r&eacute;clamations.
                </p>
                <p>
                  La proc&eacute;dure de traitement des r&eacute;clamations ne
                  s&rsquo;exerce que dans les conditions suivantes :
                </p>
                <p>
                  -Le Client qui souhaite b&eacute;n&eacute;ficier de la
                  proc&eacute;dure de gestion des r&eacute;clamations peut le
                  faire dans les 48 heures apr&egrave;s confirmation de sa
                  r&eacute;ception.
                </p>
                <p>
                  &ndash; la r&eacute;clamation doit &ecirc;tre
                  accompagn&eacute;e des pi&egrave;ces ou &eacute;l&eacute;ments
                  justificatifs (bon de livraison, photos, etc.) qui en
                  permettent l&rsquo;appr&eacute;ciation par la
                  Soci&eacute;t&eacute;.
                </p>
                <p>
                  <strong>10.2 Droit de r&eacute;tractation</strong>
                </p>
                <p>
                  Le droit de r&eacute;tractation ne s&rsquo;exerce pas
                  s&rsquo;agissant de produits p&eacute;rissables.
                </p>
                <p>
                  <strong>10.3 D&eacute;gradation lors de la livraison</strong>
                </p>
                <p>
                  Les r&eacute;clamations pour d&eacute;gradation du Produit
                  lors de la livraison ne s&rsquo;exercent que si le colis est
                  endommag&eacute;. Il est de la responsabilit&eacute; du Client
                  d&rsquo;&eacute;mettre des r&eacute;serves sur le bon de
                  livraison fourni par le livreur.
                </p>
                <p>
                  Lorsque le Client adresse sa r&eacute;clamation &agrave; la
                  Soci&eacute;t&eacute; B.E HALAL, celle-ci informe la
                  soci&eacute;t&eacute; de livraison
                  &laquo;&nbsp;CHRONOFRESH&nbsp;&raquo;.
                </p>
                <p>
                  <strong>10.4 Non-conformit</strong>
                  <strong>&eacute; du Produit</strong>
                </p>
                <p>
                  Les r&eacute;clamations relatives &agrave; la
                  non-conformit&eacute; d&rsquo;un produit couvrent les cas
                  suivants :
                </p>
                <p>
                  &ndash; le Produit a &eacute;t&eacute; mal emball&eacute; par
                  la soci&eacute;t&eacute; B.E HALAL et a &eacute;t&eacute;
                  alt&eacute;r&eacute; pendant le transport ;
                </p>
                <p>
                  &ndash; le Produit n&rsquo;est pas conforme &agrave; la
                  description de l&rsquo;annonce.
                </p>
                <p>
                  La Soci&eacute;t&eacute; B.E HALAL inform&eacute;e d&rsquo;une
                  r&eacute;clamation pour non-conformit&eacute;, propose au
                  Client un d&eacute;dommagement.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 11 : PROPRI&Eacute;T</u>
                  </strong>
                  <strong>&Eacute; </strong>
                  <strong>
                    <u>INTELLECTUELLE</u>
                  </strong>
                </p>
                <p>
                  Les Utilisateurs sont inform&eacute;s que la Plateforme et
                  l&rsquo;ensemble des &eacute;l&eacute;ments (vid&eacute;os,
                  textes, contenus audios, etc.) la composant sont couverts par
                  des droits de propri&eacute;t&eacute; intellectuelle dont la
                  Soci&eacute;t&eacute; est seule titulaire.
                </p>
                <p>
                  Cela inclut &eacute;galement l&rsquo;ensemble des
                  d&eacute;nominations utilis&eacute;es pour d&eacute;signer la
                  Plateforme et le Site.
                </p>
                <p>
                  &Agrave; cet &eacute;gard, tout acte de reproduction ou
                  d&rsquo;utilisation non autoris&eacute; par la
                  Soci&eacute;t&eacute; constitue un acte de contrefa&ccedil;on
                  qui peut &ecirc;tre poursuivi comme tel tant sur le plan civil
                  que sur le plan p&eacute;nal.
                </p>
                <p>
                  &Agrave; cet &eacute;gard, la Soci&eacute;t&eacute; autorise
                  uniquement les Utilisateurs &agrave; utiliser les
                  &eacute;l&eacute;ments couverts par un droit de
                  propri&eacute;t&eacute; intellectuelle aux seules fins
                  d&rsquo;ex&eacute;cution des pr&eacute;sentes et pendant la
                  dur&eacute;e d&rsquo;application des pr&eacute;sentes.
                </p>
                <p>
                  Les Utilisateurs s&rsquo;engagent &agrave; ne modifier aucun
                  de ces &eacute;l&eacute;ments d&rsquo;aucune mani&egrave;re ou
                  &agrave; en faire une utilisation non conforme aux
                  pr&eacute;sentes.
                </p>
                <p>
                  <strong>11.1 Propri</strong>
                  <strong>&eacute;t&eacute; des annonces</strong>
                </p>
                <p>
                  Par ailleurs, la Soci&eacute;t&eacute; reconna&icirc;t et
                  accepte que les contenus des annonces publi&eacute;es sur la
                  Plateforme leur appartiennent et qu&rsquo;ils
                  d&eacute;tiennent l&rsquo;ensemble des droits de
                  propri&eacute;t&eacute; intellectuelle sur ces contenus.
                </p>

                <p>
                  <strong>
                    <u>ARTICLE 12 : DONN</u>
                  </strong>
                  <strong>&Eacute;</strong>
                  <strong>
                    <u>ES PERSONNELLES</u>
                  </strong>
                </p>
                <p>
                  Les Clients sont inform&eacute;s que la Soci&eacute;t&eacute;
                  collecte des donn&eacute;es &agrave; caract&egrave;re
                  personnel les concernant aux seules fins de permettre
                  l&rsquo;ex&eacute;cution des pr&eacute;sentes et la mise
                  &agrave; disposition de la Plateforme.
                </p>
                <p>
                  Ces donn&eacute;es &agrave; caract&egrave;re personnel sont
                  collect&eacute;es et trait&eacute;es conform&eacute;ment
                  &agrave; la l&eacute;gislation applicable et notamment la loi
                  information et libert&eacute; n&deg; 78-17 du 6 janvier 1978.
                </p>
                <p>
                  <strong>12.1 Quelles donn&eacute;es &agrave; caract</strong>
                  <strong>&egrave;</strong>
                  <strong>re personnel sont collect&eacute;es ?</strong>
                </p>
                <p>
                  La Soci&eacute;t&eacute; collecte et traite les donn&eacute;es
                  suivantes relatives aux Clients : adresse postale, adresse
                  &eacute;lectronique, adresse IP, t&eacute;l&eacute;phone,
                  ainsi que les informations recueillies &agrave; l&rsquo;aide
                  de cookies.
                </p>
                <p>
                  <strong>
                    12.2 Pour quelles raisons les données sont-elles collectées
                    ?
                  </strong>
                </p>
                <p>
                  Les donn&eacute;es &agrave; caract&egrave;re personnel sont
                  collect&eacute;es aux fins de permettre &agrave; la
                  Soci&eacute;t&eacute; de fournir ses services sur le Site et
                  la Plateforme.
                </p>
                <p>
                  Il est rappel&eacute; aux Clients que les donn&eacute;es ainsi
                  collect&eacute;es visent &agrave; permettre le bon
                  fonctionnement du Site et de la Plateforme.
                </p>
                <p>
                  En outre, les Clients sont inform&eacute;s que seules les
                  donn&eacute;es strictement n&eacute;cessaires aux
                  finalit&eacute;s recherch&eacute;es sont collect&eacute;es.
                  &Agrave; cet &eacute;gard, la Soci&eacute;t&eacute; applique
                  le principe de la minimisation de la collecte des
                  donn&eacute;es. La Soci&eacute;t&eacute; ne collectera pas
                  plus de donn&eacute;es que ce dont elle a besoin pour assurer
                  la fourniture du Site et de la Plateforme.
                </p>
                <p>
                  <strong>12.3 Quelle dur&eacute;e de conservation ?</strong>
                </p>
                <p>
                  Les donn&eacute;es &agrave; caract&egrave;re personnel sont
                  collect&eacute;es et trait&eacute;es uniquement afin de
                  r&eacute;pondre aux objectifs poursuivis par la
                  Soci&eacute;t&eacute;.
                </p>
                <p>
                  Dans ce cadre, la Soci&eacute;t&eacute; ne conservera pas les
                  donn&eacute;es collect&eacute;es pour une dur&eacute;e
                  exc&eacute;dant l&rsquo;atteinte de l&rsquo;objectif
                  qu&rsquo;elle s&rsquo;est fix&eacute;e.
                </p>
                <p>
                  <strong>
                    12.4 Les droits des personnes concern&eacute;es ?
                  </strong>
                </p>
                <p>
                  La Soci&eacute;t&eacute; s&rsquo;engage &agrave; assurer un
                  traitement des donn&eacute;es conforme aux droits des
                  personnes concern&eacute;es par un traitement de
                  donn&eacute;es &agrave; caract&egrave;re personnel.
                </p>
                <p>
                  Ainsi, la Soci&eacute;t&eacute; garantit que les Clients
                  b&eacute;n&eacute;ficient notamment d&rsquo;un droit
                  d&rsquo;acc&egrave;s, de suppression, de modification,
                  d&rsquo;opposition et de rectification concernant les
                  donn&eacute;es collect&eacute;es les concernant.
                </p>
                <p>
                  <strong>12.5 Les transferts de donn&eacute;es</strong>
                </p>
                <p>
                  Les Clients sont inform&eacute;s de ce que leurs
                  donn&eacute;es sont susceptibles d&rsquo;&ecirc;tre
                  communiqu&eacute;es &agrave; des tiers domicili&eacute;s dans
                  l&rsquo;Union europ&eacute;enne ou dans des &Eacute;tats
                  assurant un niveau &eacute;quivalent de protection et ce, aux
                  seules fins de permettre le bon fonctionnement du Site et de
                  la Plateforme.
                </p>
                <p>
                  <strong>12.6 La s</strong>
                  <strong>
                    &eacute;curit&eacute; des donn&eacute;es &agrave; caract
                  </strong>
                  <strong>&egrave;</strong>
                  <strong>
                    re personnel
                    <br />{" "}
                  </strong>
                </p>
                <p>
                  La Soci&eacute;t&eacute; assure la s&eacute;curit&eacute; des
                  donn&eacute;es &agrave; caract&egrave;re personnel concernant
                  les Clients en prenant toutes les mesures appropri&eacute;es
                  de fa&ccedil;on &agrave; garantir l&rsquo;absence
                  d&rsquo;intrusion dans ses fichiers.
                  <br /> Ce faisant, la Soci&eacute;t&eacute; contr&ocirc;le
                  notamment l&rsquo;acc&egrave;s aux donn&eacute;es des Clients.
                </p>
                <p>
                  <strong>12.7 Cookies</strong>
                </p>
                <p>
                  La Soci&eacute;t&eacute; utilise des cookies pour lui
                  permettre de r&eacute;aliser des statistiques de visites du
                  Site ainsi que pour assurer un fonctionnement optimal du Site
                  et de la Plateforme et la diffusion &eacute;ventuelle de
                  publicit&eacute;es cibl&eacute;es.
                </p>
                <p>
                  Par l&rsquo;application des pr&eacute;sentes, les Utilisateurs
                  consentent au d&eacute;p&ocirc;t de cookies sur leur
                  mat&eacute;riel informatique.
                </p>
                <p>
                  <strong>12.8 Newsletter</strong>
                </p>
                <p>
                  Dans le cadre de l&rsquo;utilisation de la Plateforme,
                  l&rsquo;Utilisateur accepte de recevoir des emails de la
                  Plateforme, sous forme de newsletter ou autre.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 13 : MODIFICATION DES CGV</u>
                  </strong>
                </p>
                <p>
                  Les Utilisateurs sont inform&eacute;s que la
                  Soci&eacute;t&eacute; pourra proc&eacute;der &agrave; des
                  modifications des CGV &agrave; sa discr&eacute;tion ou pour
                  r&eacute;pondre &agrave; la l&eacute;gislation applicable.
                </p>
                <p>
                  &Agrave; ce titre, les Utilisateurs s&rsquo;engagent &agrave;
                  consulter r&eacute;guli&egrave;rement les CGV.
                </p>
                <p>
                  Toute modification est consid&eacute;r&eacute;e comme
                  accept&eacute;e d&egrave;s lors que les Utilisateurs
                  continuent d&rsquo;utiliser la Plateforme.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>
                      ARTICLE 14 : LOI APPLICABLE ET JURIDICTION
                      COMP&Eacute;TENTE
                    </u>
                  </strong>
                </p>
                <p>
                  Les pr&eacute;sentes CGV sont r&eacute;gies par la
                  l&eacute;gislation fran&ccedil;aise.
                </p>
                <p>
                  En cas de difficult&eacute; concernant leur
                  interpr&eacute;tation ou leur ex&eacute;cution, les parties
                  entendent donner comp&eacute;tence &agrave; toute juridiction
                  comp&eacute;tente selon le droit commun applicable en la
                  mati&egrave;re.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 15 : LIENS HYPERTEXTES</u>
                  </strong>
                </p>
                <p>
                  Le Site peut contenir des liens dirigeant les Utilisateurs
                  vers d&rsquo;autres sites Internet qui ne sont pas
                  contr&ocirc;l&eacute;s par la Soci&eacute;t&eacute;.
                </p>
                <p>
                  Ce faisant, la Soci&eacute;t&eacute; n&rsquo;assume aucune
                  responsabilit&eacute; quant &agrave; ces liens ni au contenu
                  des sites ainsi vis&eacute;s.
                </p>
                <p>
                  Il ne s&rsquo;agit que de liens donn&eacute;s &agrave; titre
                  informatif ; ce que les Utilisateurs reconnaissent et
                  acceptent.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>ARTICLE 16 : DISPOSITIONS DIVERSES</u>
                  </strong>
                </p>
                <p>
                  Il est consid&eacute;r&eacute; qu&rsquo;en application de la
                  l&eacute;gislation en vigueur, les Utilisateurs ont
                  re&ccedil;u une information claire, loyale et transparente
                  contenue notamment dans les pr&eacute;sentes ; ce qu&rsquo;ils
                  reconnaissent et acceptent.
                </p>
                <p>
                  <strong>
                    <u>Article 17 : Nos viandes fraiches en livraison </u>
                  </strong>
                </p>
                <p>
                  Les produits de notre offre sont conformes à la réglementation
                  et aux normes en vigueur en France à la date de leur
                  livraison. Chaque produit sur le site est accompagné d'un
                  descriptif permettant au client de connaître les
                  caractéristiques essentielles du produit ; les photos et
                  illustrations n’ont toutefois qu’une valeur indicative. De
                  même, pour les produits à la découpe, leur poids est
                  susceptible de varier selon la découpe dans une fourchette de
                  +/- 5 % par rapport au poids indiqué sur le descriptif du
                  produit. Si le poids du produit à la découpe est supérieur ou
                  inférieur de 5 % au poids indiqué dans le descriptif,
                  l'excédent ou l'insuffisance de poids ne donnera lieu à aucune
                  augmentation ou diminution proportionnelle du prix. En
                  conséquence, notre responsabilité ne saurait être recherchée
                  ni engagée en cas d’erreurs, imprécisions ou omissions
                  relativement aux informations de notre offre mises en ligne
                  sur ce site.
                </p>
                <p>
                  Certains produits sont vendus au poids. Les articles peuvent
                  varier légèrement en taille et en poids, nous vous indiquons
                  sur notre site internet un poids moyen estimatif. Votre
                  facture peut être différente car elle tient compte du poids
                  réellement constaté lors de la préparation de votre commande.
                </p>
                <p>
                  Nous nous réservons le droit de modifier à tout moment et sans
                  préavis notre offre. En conséquence, les produits de notre
                  offre sont disponibles tant qu’ils figurent sur le site et
                  dans la limite des stocks. La disponibilité ou non d’un
                  produit ainsi que le délai de livraison est indiquée au client
                  lors de la saisie du bon de commande et lui est confirmée dans
                  l’accusé de réception de commande visé ci-dessous.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Cgv;
