import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import about from "../images/about.jpg";
import about1 from "../images/about_1.svg";
import arrow_about from "../images/arrow_about.png";
import about_2 from "../images/about_2.svg";
import about_3 from "../images/about_3.svg";
import thumbnail from "../video/thumbnail.jpg";
import video from "../video/intro.mp4";
import Cookies from "js-cookie";
import { post, get } from "../adapters/index";

const About = () => {
  Element.prototype.insertAfter = function (newEl) {
    this.parentNode.insertBefore(newEl, this.nextSibling);
  };

  const add = () => {
    var error = false;
    var ele = document.querySelectorAll(".error");

    for (var j = 0; j < ele.length; j++) {
      ele[j].remove();
    }

    var name_register = document.getElementById("name_register");
    var email_register = document.getElementById("email_register");
    var telephone_register = document.getElementById("telephone_register");
    var restaurantname_register = document.getElementById(
      "restaurantname_register"
    );
    var address_register = document.getElementById("address_register");

    if (name_register.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "s'il vous plaît entrez le nom";
      name_register.insertAfter(newEll);
      error = true;
    }

    if (email_register.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "veuillez entrer l'adresse e-mail";
      email_register.insertAfter(newEll);
      error = true;
    }

    if (telephone_register.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "veuillez entrer le numéro de téléphone";
      telephone_register.insertAfter(newEll);
      error = true;
    }

    if (restaurantname_register.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Veuillez saisir le nom de la boucherie";
      restaurantname_register.insertAfter(newEll);
      error = true;
    }

    if (address_register.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Veuillez saisir l'adresse";
      address_register.insertAfter(newEll);
      error = true;
    }

    var details = {
      name_register: name_register.value,
      email_register: email_register.value,
      telephone_register: telephone_register.value,
      restaurantname_register: restaurantname_register,
      address_register: address_register,
      city_register: "",
    };

    if (error === true) {
      return false;
    }

    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("page", "about");
    form.append("details", JSON.stringify(details));

    post("/wb/admin/contactus", form)
      .then(async function (response) {
        name_register.value = "";
        email_register.value = "";
        telephone_register.value = "";
        restaurantname_register.value = "";
        address_register.value = "";
        alert("Soumis avec succès");
      })
      .catch(function (error) {});
  };

  return (
    <div>
      <HeaderHome popup={false} />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + about + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Découvrez</h1>
                <p>
                  en EXCLUSIVITÉ sur B.e Halal des centaines de produits haut de
                  gamme certifiés 100% Halal
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container margin_60_40">
          <div className="main_title center">
            <span>
              <em></em>
            </span>
            <h2>
              Qu’est ce que B.e Halal ? C’est votre partenaire du quotidien !
            </h2>
            <p>
              B.e Halal est votre nouvelle plateforme de livraison en ligne
              certifiée 100% Halal. Fini de chercher une place de parking, fini
              les files d’attente interminables aux caisses, fini de porter vos
              courses…simplifiez-vous la vie, venez chez B.e Halal.{" "}
            </p>
          </div>

          <div className="row justify-content-center align-items-center add_bottom_15">
            <div className="col-lg-5">
              <div className="box_about">
                <h3>Téléchargez & installez l’application</h3>
                <p className="lead">Disponible sur Apple store et Play store</p>
                <p>
                  B.e Halal est disponible sur Apple store et Play store.
                  Renseignez vos Informations & voilà …vous faites partie de la
                  communauté B.e Halal.
                </p>
                <img src={arrow_about} alt="" className="arrow_1" />
              </div>
            </div>
            <div className="col-lg-5 pl-lg-5 text-center d-none d-lg-block">
              <img src={about1} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row justify-content-center align-items-center add_bottom_15">
            <div className="col-lg-5 pr-lg-5 text-center d-none d-lg-block">
              <img src={about_2} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-5">
              <div className="box_about">
                <h3>Choisissez vos produits préférés</h3>
                <p className="lead">
                  Parmi tous nos produits innovants certifiés halal
                  soigneusement sélectionnés
                </p>
                <p>
                  B.e Halal vous donne l'opportunité de faire vos courses en
                  ligne, de choisir vos viandes fraiches ou surgelées, vos
                  produits secs, vos charcuteries, vos épices, vos sauces, vos
                  fromages, vos boissons & bien plus encore…sans bouger de chez
                  vous.
                </p>
                <img src="img/arrow_about.png" alt="" className="arrow_2" />
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              <div className="box_about">
                <h3>Faites livrer vos articles chez vous</h3>
                <p className="lead">
                  Faites livrer vos viandes de qualité en quelques clics
                </p>
                <p>
                  Une livraison fraîche et rapide, livré chez vous quand vous le
                  souhaitez.
                </p>
              </div>
            </div>
            <div className="col-lg-5 pl-lg-5 text-center d-none d-lg-block">
              <img src={about_3} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg_offwhite">
        <div className="center container margin_60_40">
          <div className="main_title center">
            <span>
              <em></em>
            </span>
            <h2>Jetez un œil à notre présentation</h2>
          </div>

          <video
            className="center"
            controls
            width="600"
            height="400"
            autoplay
            loop
            preload="auto"
            poster={thumbnail}
          >
            <source
              src={"https://behalal.fr/public/videos/BeHalal1080p-220312.mp4"}
              type="video/mp4"
            />
            <p>Your browser doesn't support HTML video.</p>
          </video>
        </div>
      </div>

      <section id="form" style={{ display: "none" }}>
        <div className="bg_gray pattern" id="submit">
          <div className="container margin_60_40">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="text-center add_bottom_15">
                  <h2>Devenir membre B.e Halal ?</h2>
                  <p>Remplissez le formulaire</p>
                </div>
                <div id="message-register"></div>
                <form method="post" action="javascript:void(0);" id="register">
                  <h6>Vos coordonnés</h6>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Nom"
                          name="name_register"
                          id="name_register"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Prénom"
                          name="restaurantname_register"
                          id="restaurantname_register"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row add_bottom_15">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Numero de telephone"
                          name="telephone_register"
                          id="telephone_register"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Adresse postale"
                          name="address_register"
                          id="address_register"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Adresse e-mail"
                          name="email_register"
                          id="email_register"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group text-center">
                    <input
                      type="submit"
                      className="btn_1"
                      value="Soumettre"
                      id="submit-register"
                      onClick={() => add()}
                    ></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
