import React, { useState, useEffect, useRef, useParams } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/detail-page.css";
import Cookies from "js-cookie";
import { post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CartList from "../components/CartList";
import { reactLocalStorage } from "reactjs-localstorage";
import Checkout from "./Checkout";
import { openLogin, openLoginAfter } from "../actions/index";
import PulseLoader from "react-spinners/PulseLoader";

import { store } from "react-notifications-component";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";

const MyOrders = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(0);
  const [ticketLoading, setTicketLoading] = useState(0);
  const [ordersDetails, setOrderDetails] = useState([]);
  const [filter, setFilter] = useState("all");

  const [open, setOpen] = useState(false);
  const onOpenModal = (orderId) => {
    setOpen(true);
    setOrderId(orderId);
  };
  const onCloseModal = () => setOpen(false);
  const [orderId, setOrderId] = useState(0);
  const [error, setError] = useState(0);
  let history = useHistory();

  useEffect(function () {
    var c = window.client;
    if (Cookies.get("bhId") == undefined) {
      Cookies.set("bhId", process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
    }
    _loadMyOrders();
  }, []);

  const _loadMyOrders = () => {
    setLoading(1);
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("order/my_orders/" + filter, form)
      .then(async function (response) {
        setOrders(response.data.data);
        setOrderDetails(response.data.data[0]);
        setLoading(2);
      })
      .catch(function (error) {});
  };

  const orderDetailsC = (o, e) => {
    setOrderDetails(o);

    var ele = document.querySelectorAll(".my");

    for (var j = 0; j < ele.length; j++) {
      ele[j].classList.remove("myactive");
    }

    if (e.target.className === "") {
      e.target.parentElement.classList.add("myactive");
    } else {
      e.target.classList.add("myactive");
    }
  };

  const alerts = (msg) => {
    store.addNotification({
      title: "Alerte",
      message: msg,
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  const create = () => {
    var subject = document.getElementById("subject");
    var content = document.getElementById("content");

    if (!!subject.value === false) {
      setError(1);
      return false;
    } else if (!!content.value === false) {
      setError(2);
      return false;
    }

    setTicketLoading(1);
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("order_id", orderId);
    form.append("subject", subject.value);
    form.append("content", content.value);

    post("/support/create", form)
      .then(async function (response) {
        setTicketLoading(0);
        onCloseModal();
        store.addNotification({
          title: "Succès",
          message: "Enregistré avec succès",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      })
      .catch(function (error) {
        setTicketLoading(0);
      });
  };

  return (
    <div>
      <Header />

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{ modal: "customModal" }}
      >
        <h5>Créer un ticket d'assistance</h5>
        <hr />

        <div className="form-group">
          <label>Sujet</label>
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
          />
          {error === 1 ? (
            <div style={{ color: "red", fontSize: 12 }}>
              Veuillez saisir un sujet valide
            </div>
          ) : null}
        </div>

        <div className="form-group">
          <label>Votre commentaire</label>
          <textarea
            className="form-control"
            name="content"
            id="content"
          ></textarea>
          {error === 2 ? (
            <div style={{ color: "red", fontSize: 12 }}>
              Veuillez saisir une description valide
            </div>
          ) : null}
        </div>

        <a
          href="javascript:void(0);"
          className="btn_1 full-width mb_5"
          onClick={() => create()}
        >
          {ticketLoading == 0 ? (
            "Soumettre"
          ) : (
            <PulseLoader size={8} color="#ffffff" />
          )}
        </a>
      </Modal>

      <main>
        <div className="page_header element_to_stick">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 d-none d-md-block">
                <div style={{ float: "left" }}>
                  <h1>
                    <button
                      style={{ border: 0, background: "transparent" }}
                      onClick={() => history.goBack()}
                    >
                      <i
                        style={{ fontSize: 16, marginRight: 10 }}
                        className="fas fa-chevron-left"
                      ></i>
                    </button>
                    <span style={{ textTransform: "capitalize" }}>
                      {t("my_orders")}
                    </span>{" "}
                  </h1>
                </div>
                <div
                  style={{
                    float: "right",
                    display: "none",
                    justifyContent: "space-between",
                  }}
                >
                  <a style={{ marginRight: 30, cursor: "pointer" }}>
                    Commandes actives
                  </a>
                  <a style={{ marginRight: 30, cursor: "pointer" }}>
                    Commandes annulées
                  </a>
                  <a style={{ marginRight: 30, cursor: "pointer" }}>
                    Commandes passées
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container margin_30_40 order">
          <div className="row">
            {orders.length > 0 && orders != undefined ? (
              <div className="col-sm-3">
                {orders.map((o, k) => (
                  <div
                    key={o.order_no}
                    className={k === 0 ? "myactive my main" : "my main"}
                    style={{
                      borderBottom: "1px solid #d2cbcb",
                      paddingBottom: 10,
                      marginBottom: 20,
                      padding: 4,
                      cursor: "pointer",
                    }}
                    onClick={(e) => orderDetailsC(o, e)}
                  >
                    <div style={{ fontWeight: "bold" }}>
                      #{o.order_no}
                      <span style={{ float: "right", fontWeight: "bold" }}>
                        {" "}
                        {o.total_price} {t("currency_symbol")}
                      </span>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <span>{o.order_date}</span>
                      <span style={{ float: "right" }}>
                        {o.order_delivery_timeslot}
                      </span>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <span>
                        {o.order_status === "processing" && "Traitement"}
                        {o.order_status === "delivered" && "Livrés"}
                        {o.order_status === "failed" && "échec"}
                        {o.order_status === "pending" && "en attendant"}
                        {o.order_status === "picked up" && "Récupérer"}
                      </span>
                      <span style={{ float: "right" }}>
                        {o.payment_status === "success"
                          ? "Payés"
                          : "en attente/échoué"}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="col-sm-12" style={{ textAlign: "center" }}>
                Il n'y a pas de commandes
              </div>
            )}

            {ordersDetails != undefined ? (
              <div
                className="col-lg-9 col-md-9 col-xs-12 col-sm-12"
                style={{ padding: 10, border: "1px solid rgb(240, 233, 233)" }}
              >
                <div className="row">
                  <div className="col-sm-12">
                    <h5>N ° de commande : {ordersDetails.order_no}</h5>
                    {ordersDetails.payment_status === "success" && (
                      <span style={{ marginTop: -27, cursor: "pointer" }}>
                        <a
                          target="_blank"
                          href={
                            "https://behalal.fr/index.php/order/download/" +
                            ordersDetails.order_id
                          }
                        >
                          Télécharger la facture
                        </a>
                      </span>
                    )}
                    <span
                      onClick={(e) => onOpenModal(ordersDetails.order_no)}
                      className="fs1"
                      aria-hidden="true"
                      data-icon="L"
                      style={{
                        float: "right",
                        marginTop: -27,
                        cursor: "pointer",
                      }}
                    >
                      Un problème ? Demande d'aide
                    </span>
                    <hr />
                  </div>
                  <div className="col-sm-4">
                    <b>{t("order_no")}</b> <br />
                    {ordersDetails.order_no}
                  </div>

                  <div className="col-lg-4 col-sm-12">
                    <b>{t("order_date")}</b> <br />
                    {ordersDetails.order_date}
                  </div>

                  <div className="col-lg-4 col-sm-12">
                    <b>{t("time_slot")}</b> <br />
                    {ordersDetails.order_delivery_timeslot}
                  </div>

                  <div className="col-lg-4 col-sm-12">
                    <br />
                    <b>{t("order_status")}</b> <br />
                    {ordersDetails.order_status === "processing" &&
                      "Traitement"}
                    {ordersDetails.order_status === "delivered" && "Livrés"}
                    {ordersDetails.order_status === "failed" && "échec"}
                    {ordersDetails.order_status === "pending" && "en attendant"}
                    {ordersDetails.order_status === "picked up" && "Récupérer"}
                  </div>

                  <div className="col-lg-4 col-sm-12">
                    <br />
                    <b>{t("payment_status")}</b> <br />
                    {ordersDetails.payment_status === "success"
                      ? "Payés"
                      : "en attente/échoué"}
                  </div>

                  <div className="col-lg-4 col-sm-12">
                    <br />
                    <b>{t("total_price")}</b> <br />
                    {ordersDetails.total_price} {t("currency_symbol")}
                  </div>

                  <div className="col-lg-12 col-xs-12 col-sm-12">
                    <br />
                    <b>{t("delivery_address")}</b> <br />
                    {ordersDetails.delivery_address}
                  </div>

                  <div className="col-sm-12">
                    <br />
                    <br />
                    <br />
                    <b>{t("order_summary")}</b> <hr />
                  </div>

                  <div className="col-sm-12">
                    <br />

                    <table width="100%">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nom du produit</th>
                          <th>La quantité de produit</th>
                          <th>Prix</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordersDetails.items != undefined
                          ? ordersDetails.items.map((p, k) => (
                              <tr key={p.product_name}>
                                <td>{k + 1}</td>
                                <td>{p.product_name}</td>
                                <td>{p.product_qty}</td>
                                <td>
                                  {" "}
                                  {p.product_price} {t("currency_symbol")}
                                </td>
                              </tr>
                            ))
                          : null}
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {t("delivery_charge")}
                          </td>
                          <td>
                            {" "}
                            {ordersDetails.delivery_charge}{" "}
                            {t("currency_symbol")}
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td colSpan={2}>
                            <table
                              style={{
                                width: "100%",
                                border: "1px solid #fff",
                              }}
                            >
                              {ordersDetails.vat_details != undefined &&
                                ordersDetails.vat_details.map((v, k) => (
                                  <tr key={v.id}>
                                    <td
                                      style={{
                                        width: "73%",
                                        border: "1px solid #fff",
                                        fontWeight: "bold",
                                        textAlign: "right",
                                      }}
                                    >
                                      Dont T.V.A ({v.percentage}%){" "}
                                    </td>
                                    <td style={{ border: "1px solid #fff" }}>
                                      {v.total} {t("currency_symbol")}
                                    </td>
                                  </tr>
                                ))}
                            </table>
                          </td>
                        </tr>
                        {/*<tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Dont T.V.A.</td>
                                                        <td> {ordersDetails.vat} {t('currency_symbol')}</td>
                                                    </tr>*/}
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {t("total_price")}
                          </td>
                          <td>
                            {" "}
                            <b>
                              {" "}
                              {ordersDetails.total_price} {t("currency_symbol")}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default MyOrders;
