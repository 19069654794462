import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import "../styles/contacts.css";
import privacy from "../images/privacy.jpg";

const Privacy = () => {
  return (
    <div>
      <HeaderHome />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + privacy + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Politique de Confidentialité</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container margin_30_40">
        <div className="row">
          <div className="col-lg-12">
            <div className="singlepost2">
              <h1>Politique de Confidentialité</h1>

              <div className="post-content">
                <p>
                  B.e Halal collecte et utilise les donn&eacute;es &agrave;
                  caract&egrave;re personnel que vous lui transmettez,
                  directement ou indirectement, et qui sont n&eacute;cessaires
                  &agrave; l'utilisation et &agrave; votre navigation sur la
                  Plateforme.
                </p>
                <p>
                  La pr&eacute;sente notice de protection de vos donn&eacute;es
                  personnelles a pour objectif de vous informer au mieux sur les
                  donn&eacute;es &agrave; caract&egrave;re personnel que nous
                  recueillons aupr&egrave;s de vous lors de votre navigation sur
                  la Plateforme, sur la mani&egrave;re dont nous les utilisons,
                  les communiquons et les prot&eacute;geons et, enfin, sur la
                  mani&egrave;re dont vous pouvez contr&ocirc;ler l'utilisation
                  et la divulgation de ces donn&eacute;es.
                </p>
                <p>
                  Vous &ecirc;tes invit&eacute;s &agrave; lire attentivement
                  notre Notice et plus g&eacute;n&eacute;ralement les notices de
                  protection de vos donn&eacute;es personnelles, et toute
                  politique de confidentialit&eacute; de chaque site Internet
                  que vous visitez. Cette Notice ne s'applique qu'&agrave; la
                  Plateforme et non pas aux donn&eacute;es recueillies par le
                  biais de tout autre site Internet (ou application mobile) vers
                  lequel vous pourriez &ecirc;tre redirig&eacute; ou aux
                  pratiques des entreprises que <strong>B.e Halal </strong>ne
                  contr&ocirc;le pas.
                  <br />
                  <br />
                </p>
                <p>
                  <strong>
                    <u>Nature des donn&eacute;es &agrave; caract</u>
                  </strong>
                  <strong>
                    <u>&egrave;</u>
                  </strong>
                  <strong>
                    <u>re personnel collect&eacute;es sur la Plateforme</u>
                  </strong>
                </p>
                <p>
                  Nous collectons vos informations personnelles afin de vous
                  proposer et d&rsquo;am&eacute;liorer continuellement nos
                  services.
                </p>
                <p>
                  Pour utiliser les services propos&eacute;s par B.e Halal, tout
                  utilisateur doit imp&eacute;rativement s&rsquo;enregistrer en
                  compl&eacute;tant un formulaire d&rsquo;inscription lui
                  permettant d&rsquo;ouvrir un compte personnel sur la
                  Plateforme.
                </p>
                <p>
                  B.e Halal collecte et traite les donn&eacute;es que vous lui
                  communiquez afin de vous permettre d&rsquo;utiliser les
                  services propos&eacute;s, et notamment les donn&eacute;es
                  suivantes :
                </p>
                <ul>
                  <li>
                    Vos informations personnelles : nom, pr&eacute;nom, adresse
                    e-mail;
                  </li>
                  <li>
                    Vos coordonn&eacute;es de livraison : adresse de livraison ;
                    num&eacute;ro de t&eacute;l&eacute;phone ; modalit&eacute;
                    d&rsquo;acc&egrave;s &agrave; votre point de livraison
                  </li>
                  <li>
                    Votre adresse IP et l&rsquo;interface &agrave; travers
                    laquelle vous vous connectez
                  </li>
                  <li>
                    Les pages et sections de la Plateforme que vous avez
                    visit&eacute;
                  </li>
                </ul>
                <p>
                  En outre, lorsque vous passez commande sur la Plateforme, nous
                  conservons un historique de vos commandes.
                </p>
                <p>
                  Ces donn&eacute;es sont collect&eacute;es par B.e Halal dans
                  le cadre des op&eacute;rations suivantes :
                </p>
                <ul>
                  <li>
                    Lors de votre visite de notre Plateforme s&rsquo;agissant de
                    votre adresse IP ;
                  </li>
                  <li>
                    Lors de la cr&eacute;ation de votre compte personnel au
                    moment de votre inscription sur la Plateforme ;
                  </li>
                  <li>
                    Lors de l&rsquo;enregistrement d&rsquo;une commande sur la
                    Plateforme.
                    <br />
                    <br />
                  </li>
                </ul>
                <p>
                  <strong>
                    <u>Finalit</u>
                  </strong>
                  <strong>
                    <u>&eacute;s des traitements de vos donn&eacute;es</u>
                  </strong>
                </p>
                <p>
                  En tant que responsable de traitement, B.e Halal collecte vos
                  donn&eacute;es &agrave; caract&egrave;re personnel de
                  fa&ccedil;on licite et loyale et en respectant vos droits.
                  L&rsquo;objectif principal de la collecte de donn&eacute;es
                  &agrave; caract&egrave;re personnel est de vous offrir une
                  exp&eacute;rience sure, optimale, efficace et
                  personnalis&eacute;e.
                </p>
                <p>
                  Vous acceptez que nous puissions utiliser vos donn&eacute;es
                  &agrave; caract&egrave;re personnel pour, notamment :
                </p>
                <ul>
                  <li>
                    vous permettre l&rsquo;utilisation de nos services,
                    c&rsquo;est-&agrave;-dire enregistrer votre commande et
                    proc&eacute;der &agrave; sa livraison&nbsp;;
                  </li>
                  <li>
                    vous communiquer des informations : B.e Halal pourra vous
                    envoyer des e-mails et des SMS aux coordonn&eacute;es que
                    vous avez renseign&eacute;s en cr&eacute;ant votre compte
                    personnel pour vous transmettre des informations sur les
                    services de B.e Halal ou sur le suivi de votre
                    commande&nbsp;;
                  </li>
                  <li>
                    satisfaire vos demandes : B.e Halal pourra utiliser les
                    donn&eacute;es collect&eacute;es pour r&eacute;pondre
                    &agrave; vos demandes relatives au fonctionnement de la
                    Plateforme, aux services de B.e Halal etc.
                  </li>
                  <li>
                    effectuer des analyses statistiques et d&eacute;velopper la
                    Plateforme&nbsp;: B.e Halal pourra effectuer des analyses
                    statistiques sur la fr&eacute;quentation et
                    l&rsquo;utilisation de la Plateforme pour lui permettre de
                    personnaliser et d&rsquo;am&eacute;liorer ses services et
                    contenus en fonction de vos besoins et de vos envies.
                  </li>
                </ul>
                <p>
                  B.e Halal pourra &ecirc;tre amen&eacute;e &agrave; utiliser
                  les donn&eacute;es collect&eacute;es &agrave; d&rsquo;autres
                  fins, sous r&eacute;serve de votre consentement, ce dont vous
                  serez inform&eacute; au moment de la collecte des
                  donn&eacute;es.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    <u>Conservation de vos donn&eacute;es</u>
                  </strong>
                </p>
                <p>
                  Nous conservons vos informations personnelles afin de vous
                  permettre d'utiliser en continu les services B.e Halal, pour
                  une dur&eacute;e n&eacute;cessaire &agrave; la
                  r&eacute;alisation des finalit&eacute;s pertinentes dans les
                  conditions d&eacute;crites dans la pr&eacute;sente Notice,
                  pour une dur&eacute;e n&eacute;cessaire &agrave; la
                  r&eacute;alisation d'obligations l&eacute;gales comme par
                  exemple des obligations fiscales ou comptables, ou pour toute
                  autre dur&eacute;e qui aura pu vous &ecirc;tre
                  communiqu&eacute;e.
                </p>
                <p>
                  Les donn&eacute;es collect&eacute;es par B.e Halal sont
                  conserv&eacute;es sur les serveurs d&rsquo;un prestataire
                  situ&eacute; dans l&rsquo;Espace Economique Europ&eacute;en.
                  B.e Halal s&rsquo;engage &agrave; prendre toutes les mesures
                  n&eacute;cessaires permettant de garantir la
                  s&eacute;curit&eacute; et la confidentialit&eacute; des
                  donn&eacute;es que vous lui avez fournies.
                </p>
                <p>
                  Vous avez la possibilit&eacute; de fermer votre espace
                  &agrave; tout moment en adressant une demande au support
                  d&rsquo;B.e Halal. A votre demande, B.e Halal supprimera
                  l'int&eacute;gralit&eacute; des donn&eacute;es &agrave;
                  caract&egrave;re personnel dont elle dispose sur votre espace
                  personnel &agrave; l&rsquo;exception des donn&eacute;es qui
                  lui sont n&eacute;cessaires pour remplir ses obligations
                  contractuelles ou l&eacute;gales, notamment comptables. Les
                  donn&eacute;es seront supprim&eacute;es &agrave; l&rsquo;issue
                  du d&eacute;lai de prescription applicable en la
                  mati&egrave;re.
                  <br />
                  <br />
                </p>
                <p>
                  <strong>
                    <u>Communication de vos donn&eacute;es</u>
                  </strong>
                </p>
                <p>
                  B.e Halal s&rsquo;engage &agrave; conserver toutes les
                  donn&eacute;es &agrave; caract&egrave;re personnel
                  collect&eacute;es via la Plateforme et ne les partage que dans
                  certaines circonstances et conform&eacute;ment aux
                  dispositions de la loi n&deg; 78-17 du 6 janvier 1978 relative
                  &agrave; l&rsquo;informatique, aux fichiers et aux
                  libert&eacute;s, telle que modifi&eacute;e par la loi
                  n&deg;2018-493 du 20 juin 2018.
                </p>
                <p>
                  B.e Halal pourra donner un acc&egrave;s &agrave; vos
                  donn&eacute;es &agrave; caract&egrave;re personnel &agrave;
                  des prestataires pour ex&eacute;cuter des services relatifs
                  &agrave; la Plateforme, notamment la maintenance, les
                  analyses, le marketing, la communication, le traitement de
                  donn&eacute;es, le paiement, la distribution d&rsquo;emails,
                  etc. Ces prestataires n&rsquo;auront acc&egrave;s &agrave; vos
                  donn&eacute;es que pour ex&eacute;cuter ces services et seront
                  tenus aux m&ecirc;mes obligations de s&eacute;curit&eacute; et
                  de confidentialit&eacute; que B.e Halal qui restera
                  responsable de traitement &agrave; votre &eacute;gard.
                </p>
                <p>
                  B.e Halal pourra partager vos donn&eacute;es pour
                  pr&eacute;venir, d&eacute;tecter, enqu&ecirc;ter et prendre
                  des mesures contre toutes activit&eacute;s potentiellement
                  interdites et ill&eacute;gales, toute situation
                  mena&ccedil;ant la s&eacute;curit&eacute; ou tout autre droit
                  et int&eacute;r&ecirc;t des utilisateurs ou de tout individu,
                  toute violation de ses conditions g&eacute;n&eacute;rales
                  d&rsquo;utilisation, si cela est autoris&eacute; ou requis par
                  la loi et si B.e Halal estime de bonne foi qu&rsquo;il est
                  n&eacute;cessaire de les partager. B.e Halal pourra
                  &eacute;galement transmettre vos donn&eacute;es &agrave;
                  caract&egrave;re personnel pour r&eacute;pondre aux poursuites
                  judiciaires de toute nature, aux ordonnances des tribunaux,
                  &agrave; toutes proc&eacute;dures juridiques ou mesures
                  d&rsquo;application de la loi r&eacute;clam&eacute;es par les
                  autorit&eacute;s comp&eacute;tentes.
                </p>
                <p>
                  B.e Halal pourra partager vos donn&eacute;es &agrave;
                  d&rsquo;autres fins et pourra permettre &agrave; ses
                  partenaires de vous adresser des informations lorsque vous
                  visitez la Plateforme (et nous permettre
                  d&rsquo;ex&eacute;cuter nos obligations contractuelles
                  &agrave; leur &eacute;gard) sous r&eacute;serve de votre
                  consentement expr&egrave;s et pr&eacute;alable.
                </p>
                <p>
                  &nbsp;
                  <br />
                  <strong>
                    <u>Donn</u>
                  </strong>
                  <strong>
                    <u>&eacute;es de trafic et cookies</u>
                  </strong>
                </p>
                <p>
                  <strong>Donn</strong>
                  <strong>&eacute;</strong>
                  <strong>es de trafic</strong>
                </p>
                <p>
                  Les donn&eacute;es de trafic sont
                  g&eacute;n&eacute;r&eacute;es lorsque vous &ecirc;tes
                  connect&eacute; &agrave; Internet et &agrave; la Plateforme.
                  Ces donn&eacute;es sont collect&eacute;es par B.e Halal dans
                  un but statistique pour effectuer des analyses de
                  fr&eacute;quentation de la Plateforme et am&eacute;liorer la
                  Plateforme pour le rendre plus performant et plus
                  adapt&eacute; &agrave; vos besoins et &agrave; vos gouts.
                </p>
                <p>
                  B.e Halal est amen&eacute;, notamment, &agrave; collecter les
                  donn&eacute;es suivantes : les pages visit&eacute;es, les
                  formulaires compl&eacute;t&eacute;s, les clicks
                  effectu&eacute;s.
                </p>
                <p>
                  <strong>Qu</strong>
                  <strong>&rsquo;</strong>
                  <strong>est-ce qu</strong>
                  <strong>&rsquo;</strong>
                  <strong>un cookie ?</strong>
                </p>
                <p>
                  Les cookies sont des petits fichiers qui sont
                  d&eacute;pos&eacute;s sur votre ordinateur lors de votre
                  navigation sur un site Internet et qui pourront &ecirc;tre lus
                  lors de vos visites sur ce m&ecirc;me site Internet. Un cookie
                  ne sert pas &agrave; recueillir des donn&eacute;es &agrave;
                  caract&egrave;re personnel &agrave; votre insu mais &agrave;
                  enregistrer des informations relatives &agrave; votre
                  navigation, qui pourront &ecirc;tre directement lues par B.e
                  Halal lors de vos visites et requ&ecirc;tes ult&eacute;rieures
                  sur la Plateforme (telles que par exemple les pages que vous
                  avez consult&eacute;es, ou la date et l&rsquo;heure de la
                  consultation de ces pages, ou vos identifiants de compte et
                  param&egrave;tre de session).
                </p>
                <p>
                  B.e Halal stocke des cookies sur votre disque dur contenant
                  par exemple des informations relatives &agrave; votre
                  navigation sur la Plateforme et &agrave; vos
                  pr&eacute;f&eacute;rences et qui ont pour finalit&eacute; de
                  permettre ou de faciliter la communication, de permettre la
                  fourniture du service demand&eacute; par les utilisateurs, de
                  reconnaitre les utilisateurs lors d'une prochaine visite, de
                  personnaliser la Plateforme pour les utilisateurs et de
                  permettre &agrave; B.e Halal en interne, de proc&eacute;der
                  &agrave; des analyses de fr&eacute;quentation des pages
                  d'information de la Plateforme afin d'en am&eacute;liorer le
                  contenu.
                </p>
                <p>
                  <strong>
                    Pour pouvoir naviguer sur la Plateforme et utiliser les
                    services de B.e Halal, vous devez imp&eacute;rativement
                    accepter l
                  </strong>
                  <strong>&rsquo;</strong>
                  <strong>
                    installation des cookies sur votre ordinateur.&nbsp;
                  </strong>
                  Si vous &ecirc;tes opposes &agrave; l&rsquo;enregistrement de
                  cookies sur votre ordinateur, vous pouvez configurer votre
                  navigateur afin que ce dernier refuse certains cookies ou
                  interdise tout d&eacute;p&ocirc;t de cookies. Toutefois, si
                  vous choisissez de refuser l&rsquo;ensemble des cookies, votre
                  navigation sur la Plateforme sera r&eacute;duite et
                  l&rsquo;acc&egrave;s &agrave; certaines fonctions des services
                  de B.e Halal limit&eacute;.
                </p>
                <p>
                  Votre consentement expr&egrave;s et pr&eacute;alable sera
                  requis pour l&rsquo;implantation de tous autres cookies que
                  ceux d&eacute;crits dans la pr&eacute;sente section. Afin
                  d&rsquo;&eacute;viter toute g&ecirc;ne occasionn&eacute;e par
                  ces demandes d&rsquo;autorisation syst&eacute;matiques, et
                  profiter d&rsquo;une navigation ininterrompue, B.e Halal
                  pourra m&eacute;moriser votre refus ou acceptation relatifs
                  &agrave; certains cookies.
                </p>
                <p>
                  &nbsp;
                  <br />
                  <strong>
                    <u>L</u>
                  </strong>
                  <strong>
                    <u>&rsquo;</u>
                  </strong>
                  <strong>
                    <u>exercice de vos droits</u>
                  </strong>
                </p>
                <p>
                  Conform&eacute;ment &agrave; la loi n&deg; 78-17 du 6 janvier
                  1978 relative &agrave; l&rsquo;informatique, aux fichiers et
                  aux libert&eacute;s, telle que modifi&eacute;e par la loi
                  n&deg;2018-493 du 20 juin 2018, vous disposez d&rsquo;un droit
                  d&rsquo;opposition et d&rsquo;un droit &agrave; la limitation
                  du traitement des donn&eacute;es vous concernant, ainsi que
                  d&rsquo;un droit d&rsquo;acc&egrave;s, de rectification, de
                  portabilit&eacute; et d&rsquo;effacement de vos
                  donn&eacute;es.&nbsp;Vous disposez &eacute;galement de la
                  facult&eacute; de donner des instructions &agrave; B.e Halal
                  sur le sort de vos donn&eacute;es apr&egrave;s votre
                  d&eacute;c&egrave;s.
                </p>
                <p>
                  Vous &ecirc;tes inform&eacute;s que l&rsquo;exercice des
                  droits d&rsquo;opposition, d&rsquo;effacement et de
                  portabilit&eacute; des donn&eacute;es, ainsi que le droit
                  &agrave; la limitation des traitements, est soumis &agrave;
                  conditions et peut &ecirc;tre refus&eacute; par B.e
                  Halal&nbsp;si les demandes d&rsquo;exercice de ces droits ne
                  correspondent pas aux situations pr&eacute;vues par la
                  r&egrave;glementation en mati&egrave;re de donn&eacute;es
                  &agrave; caract&egrave;re personnel.
                </p>
                <p>
                  Les demandes sont &agrave; exercer aupr&egrave;s de B.e
                  Halal&nbsp;:
                </p>
                <ul>
                  <li>
                    par courrier &eacute;lectronique &agrave;
                    l&rsquo;adresse&nbsp;:
                    <a href="mailto:support@behalal.fr">
                      &nbsp;support@behalal.fr
                    </a>
                    , ou
                  </li>
                  <li>
                    par courrier &agrave; l&rsquo;adresse suivante B.e halal
                    &nbsp;<em>Service Protection des Donn&eacute;</em>
                    <em>es - </em>
                    <em>11 rue des Pyr&eacute;n&eacute;es, 91090 Lisses.</em>
                  </li>
                </ul>
                <p>
                  Conform&eacute;ment &agrave; la r&eacute;glementation en
                  vigueur, votre demande doit &ecirc;tre sign&eacute;e,
                  accompagn&eacute;e de la photocopie d&rsquo;un titre
                  d&rsquo;identit&eacute; et pr&eacute;ciser l&rsquo;adresse
                  &agrave; laquelle doit vous parvenir la r&eacute;ponse.
                </p>
                <p>
                  La r&eacute;ponse de B.e Halal doit intervenir dans un
                  d&eacute;lai de 3 mois &agrave; compter de la r&eacute;ception
                  de votre demande. En l&rsquo;absence de r&eacute;ponse dans ce
                  d&eacute;lai, vous aurez la possibilit&eacute;
                  d&rsquo;introduire une r&eacute;clamation aupr&egrave;s
                  d&rsquo;une autorit&eacute; de protection des donn&eacute;es
                  &agrave; caract&egrave;re personnel, &agrave; savoir la
                  Commission Nationale de l&rsquo;Informatique et des
                  Libert&eacute;s (CNIL).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
