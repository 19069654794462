import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import "../styles/contacts.css";
import faq from "../images/faq.jpg";

const Legal = () => {
  return (
    <div>
      <HeaderHome />
      <div className="hero_single inner_pages background-image">
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Mentions légales</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_gray">
        <div className="container margin_30_40">
          <div className="row">
            <div className="col-lg-12">
              <div className="singlepost">
                <div className="post-content">
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <b>1. Propri&eacute;taire</b>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; B.E HALAL &ndash; SAS au capital de 10 000
                    &euro; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; Si&egrave;ge social : 11 rue des
                    Pyr&eacute;n&eacute;es 91090 LISSES
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; N&deg; de t&eacute;l&eacute;phone : 01 81 85
                    07 35
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp;
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; SIREN : 897 857 660
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; Code Naf : 9609Z
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp;
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <b>2. Webmaster</b>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp;{" "}
                    <a href="mailto:support@behalal.fr" target="_blank">
                      support@behalal.fr
                    </a>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp;
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <b>3. H&eacute;bergeur</b>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; 1&amp;1 IONOS SARL
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; 7, place de la Gare
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; BP 70109
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "rgb(34, 34, 34)",
                      textAlign: "start",
                      textIndent: 0,
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; 57201 Sarreguemines Cedex
                  </p>
                  <br />
                  <br />
                  <br />
                  <h5>
                    CONDITIONS G&Eacute;N&Eacute;RALES D&rsquo;UTILISATION DU
                    SITE ET DES SERVICES PROPOS&Eacute;S.
                  </h5>
                  <p>&nbsp;</p>
                  <p>
                    L&rsquo;utilisation du site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> implique
                    l&rsquo;acceptation pleine et enti&egrave;re des conditions
                    g&eacute;n&eacute;rales d&rsquo;utilisation ci-apr&egrave;s
                    d&eacute;crites.
                  </p>
                  <p>
                    Ces conditions d&rsquo;utilisation sont susceptibles
                    d&rsquo;&ecirc;tre modifi&eacute;es ou
                    compl&eacute;t&eacute;es &agrave; tout moment, les
                    utilisateurs du site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> sont donc
                    invit&eacute;s &agrave; les consulter de mani&egrave;re
                    r&eacute;guli&egrave;re.
                  </p>
                  <p>
                    Ce site est normalement accessible 24 h/24 et 7 jours/7
                    &agrave; l'exception des cas de force majeure ou de faits
                    ind&eacute;pendants de la volont&eacute; de B.E HALAL et
                    pouvant notamment r&eacute;sulter de difficult&eacute;s
                    informatiques ou de difficult&eacute;s li&eacute;es aux
                    r&eacute;seaux de communications &eacute;lectroniques.
                  </p>
                  <p>
                    B.E HALAL pourra interrompre, pour des raisons de
                    maintenance notamment, l'acc&egrave;s &agrave; son site sans
                    &ecirc;tre tenu responsable de ces interruptions et des
                    cons&eacute;quences qui pourraient en d&eacute;couler pour
                    l'utilisateur.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>DESCRIPTION DES SERVICES FOURNIS</b>
                  </p>
                  <p>
                    Le site <a href="http://www.behalal.fr">www.behalal.fr</a> a
                    pour objet de fournir une information concernant
                    l&rsquo;ensemble des activit&eacute;s de la
                    soci&eacute;t&eacute;.
                  </p>
                  <p>
                    La soci&eacute;t&eacute; B.E HALAL s&rsquo;efforce de
                    fournir sur le site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> &nbsp;des
                    informations aussi pr&eacute;cises que possible, et se
                    r&eacute;serve le droit de corriger, lorsqu'elle le jugera
                    opportun, le contenu de ce site.
                  </p>
                  <p>
                    Toutefois, et malgr&eacute; toutes les pr&eacute;cautions
                    prises, B.E HALAL ne peut garantir l'exactitude et
                    l'exhaustivit&eacute; des informations diffus&eacute;es sur
                    son site ainsi que la permanence de son bon fonctionnement
                    et de sa totale s&eacute;curit&eacute; informatique.
                  </p>
                  <p>
                    <b>
                      LIMITATIONS CONTRACTUELLES SUR LES DONN&Eacute;ES
                      TECHNIQUES
                    </b>
                  </p>
                  <p>
                    Le site Internet ne pourra &ecirc;tre tenu responsable de
                    dommages mat&eacute;riels li&eacute;s &agrave;
                    l&rsquo;utilisation du site. De plus, l&rsquo;utilisateur du
                    site s&rsquo;engage &agrave; acc&eacute;der au site en
                    utilisant un mat&eacute;riel r&eacute;cent, ne contenant pas
                    de virus et avec un navigateur de derni&egrave;re
                    g&eacute;n&eacute;ration mis a jour.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>
                      PROPRI&Eacute;T&Eacute; INTELLECTUELLE ET
                      CONTREFA&Ccedil;ONS
                    </b>
                  </p>
                  <p>
                    B.E HALAL est propri&eacute;taire des droits de
                    propri&eacute;t&eacute; intellectuelle ou d&eacute;tient les
                    droits d&rsquo;usage sur tous les &eacute;l&eacute;ments
                    accessibles sur le site, notamment les textes, images,
                    graphismes, logo, ic&ocirc;nes, sons, logiciels.
                  </p>
                  <p>
                    Toute reproduction, repr&eacute;sentation, modification,
                    publication, adaptation de tout ou partie des
                    &eacute;l&eacute;ments du site, quel que soit le moyen ou le
                    proc&eacute;d&eacute; utilis&eacute;, est interdite, sauf
                    autorisation &eacute;crite pr&eacute;alable de B.E HALAL.
                  </p>
                  <p>
                    Toute exploitation non autoris&eacute;e du site ou de
                    l&rsquo;un quelconque des &eacute;l&eacute;ments qu&rsquo;il
                    contient sera consid&eacute;r&eacute;e comme constitutive
                    d&rsquo;une contrefa&ccedil;on et poursuivie
                    conform&eacute;ment aux dispositions des articles L.335-2 et
                    suivants du Code de Propri&eacute;t&eacute; Intellectuelle.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>LIMITATIONS DE RESPONSABILIT&Eacute;</b>
                  </p>
                  <p>
                    B.E HALAL ne pourra &ecirc;tre tenu responsable des dommages
                    directs et indirects caus&eacute;s au mat&eacute;riel de
                    l&rsquo;utilisateur, lors de l&rsquo;acc&egrave;s au site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> , et
                    r&eacute;sultant soit de l&rsquo;utilisation d&rsquo;un
                    mat&eacute;riel non conforme, soit de l&rsquo;apparition
                    d&rsquo;un bug ou d&rsquo;une incompatibilit&eacute;.
                  </p>
                  <p>
                    B.E HALAL ne pourra &eacute;galement &ecirc;tre tenue
                    responsable des dommages indirects (tels par exemple
                    qu&rsquo;une perte de march&eacute; cons&eacute;cutifs)
                    &agrave; l&rsquo;utilisation du site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> .
                  </p>
                  <p>
                    Des espaces interactifs (possibilit&eacute; de poser des
                    questions dans l&rsquo;espace contact ou service client)
                    sont &agrave; la disposition des utilisateurs. B.E HALAL se
                    r&eacute;serve le droit de supprimer, sans mise en demeure
                    pr&eacute;alable, tout contenu d&eacute;pos&eacute; dans cet
                    espace qui contreviendrait &agrave; la l&eacute;gislation
                    applicable en France, en particulier aux dispositions
                    relatives &agrave; la protection des donn&eacute;es. Le cas
                    &eacute;ch&eacute;ant, B.E HALAL se r&eacute;serve
                    &eacute;galement la possibilit&eacute; de mettre en cause la
                    responsabilit&eacute; civile et/ou p&eacute;nale de
                    l&rsquo;utilisateur, notamment en cas de message &agrave;
                    caract&egrave;re raciste, injurieux, diffamant, ou
                    pornographique, quel que soit le support utilis&eacute;
                    (texte, photographie&hellip;).
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>GESTION DES DONN&Eacute;ES PERSONNELLES</b>
                  </p>
                  <p>
                    En France, les donn&eacute;es personnelles sont notamment
                    prot&eacute;g&eacute;es par la loi n&deg; 78-87 du 6 janvier
                    1978, la loi n&deg; 2004-801 du 6 ao&ucirc;t 2004, l'article
                    L. 226-13 du Code p&eacute;nal et la Directive
                    Europ&eacute;enne du 24 octobre 1995.
                  </p>
                  <p>
                    A l'occasion de l'utilisation du site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> , peuvent
                    &ecirc;tre recueillies : l'URL des liens par
                    l'interm&eacute;diaire desquels l'utilisateur a
                    acc&eacute;d&eacute; au site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> , le
                    fournisseur d'acc&egrave;s de l'utilisateur, l'adresse de
                    protocole Internet (IP) de l'utilisateur.
                  </p>
                  <p>
                    En tout &eacute;tat de cause B.E HALAL ne collecte des
                    informations personnelles relatives &agrave; l'utilisateur
                    que pour le besoin de certains services propos&eacute;s par
                    le site <a href="http://www.behalal.fr">www.behalal.fr</a> .
                    L'utilisateur fournit ces informations en toute connaissance
                    de cause, notamment lorsqu'il proc&egrave;de par
                    lui-m&ecirc;me &agrave; leur saisie. Concernant notamment
                    les informations relatives aux formulaires, les
                    donn&eacute;es collect&eacute;es identifi&eacute;es par un
                    ast&eacute;risque sont obligatoires.
                  </p>
                  <p>
                    Conform&eacute;ment aux dispositions des articles 38 et
                    suivants de la loi 78-17 du 6 janvier 1978 relative &agrave;
                    l&rsquo;informatique, aux fichiers et aux libert&eacute;s,
                    tout utilisateur dispose :
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    D&rsquo;un droit d&rsquo;acc&egrave;s, de modification, de
                    rectification et de suppression des donn&eacute;es
                    personnelles le concernant ;
                  </p>
                  <p>
                    D&rsquo;un droit d&rsquo;opposition au traitement de ses
                    donn&eacute;es personnelles pour des motifs l&eacute;gitimes
                    ainsi qu'un droit d'opposition &agrave; ce que ces
                    donn&eacute;es soient utilis&eacute;es &agrave; des fins de
                    prospection commerciale.
                  </p>
                  <p>Pour exercer ses droits, l'utilisateur peut au choix :</p>
                  <p>
                    Utiliser le formulaire de contact mis &agrave; sa
                    disposition via l'adresse url{" "}
                    <a href="https://www.behalal.fr/tell-us-everything.html">
                      https://www.behalal.fr/tell-us-everything.html
                    </a>
                  </p>
                  <p>
                    Adresser un e-mail &agrave; notre service
                    client&egrave;le&nbsp;:{" "}
                    <a href="mailto:support@behalal.fr">support@behalal.fr</a>
                  </p>
                  <p>
                    Envoyer une demande &eacute;crite et sign&eacute;e &agrave;
                    B.E HALAL, accompagn&eacute;e d&rsquo;une copie du titre
                    d&rsquo;identit&eacute; avec signature du titulaire de la
                    pi&egrave;ce, en pr&eacute;cisant l&rsquo;adresse &agrave;
                    laquelle la r&eacute;ponse doit &ecirc;tre envoy&eacute;e.
                  </p>
                  <p>
                    Aucune information personnelle de l'utilisateur du site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a>{" "}
                    &nbsp;n'est publi&eacute;e &agrave; l'insu de l'utilisateur,
                    &eacute;chang&eacute;e, transf&eacute;r&eacute;e,
                    c&eacute;d&eacute;e ou vendue sur un support quelconque
                    &agrave; des tiers. Seule l'hypoth&egrave;se du rachat de
                    B.E HALAL et de ses droits permettrait la transmission des
                    dites informations &agrave; l'&eacute;ventuel
                    acqu&eacute;reur qui serait &agrave; son tour tenu de la
                    m&ecirc;me obligation de conservation et de modification des
                    donn&eacute;es vis &agrave; vis de l'utilisateur du site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a>.
                  </p>
                  <p>
                    Le site susnomm&eacute; est d&eacute;clar&eacute; &agrave;
                    la INPI sous le num&eacute;ro 214781726.
                  </p>
                  <p>
                    Les bases de donn&eacute;es sont prot&eacute;g&eacute;es par
                    les dispositions de la loi du 1er juillet 1998 transposant
                    la directive 96/9 du 11 mars 1996 relative &agrave; la
                    protection juridique des bases de donn&eacute;es.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>LIENS HYPERTEXTES ET COOKIES</b>
                  </p>
                  <p>
                    Le site <a href="http://www.behalal.fr">www.behalal.fr</a>{" "}
                    &nbsp;peut contenir un certain nombre de liens hypertextes
                    vers d&rsquo;autres sites, mis en place avec
                    l&rsquo;autorisation de B.E HALAL. Cependant, B.E HALAL
                    n&rsquo;a pas la possibilit&eacute; de v&eacute;rifier le
                    contenu des sites ainsi visit&eacute;s, et n&rsquo;assumera
                    en cons&eacute;quence aucune responsabilit&eacute; de ce
                    fait.
                  </p>
                  <p>
                    La navigation sur le site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> &nbsp;est
                    susceptible de provoquer l&rsquo;installation de cookie(s)
                    sur l&rsquo;ordinateur de l&rsquo;utilisateur. Un cookie est
                    un fichier de petite taille, qui ne permet pas
                    l&rsquo;identification de l&rsquo;utilisateur, mais qui
                    enregistre des informations relatives &agrave; la navigation
                    d&rsquo;un ordinateur sur un site. Les donn&eacute;es ainsi
                    obtenues visent &agrave; faciliter la navigation
                    ult&eacute;rieure sur le site, et ont &eacute;galement
                    vocation &agrave; permettre diverses mesures de
                    fr&eacute;quentation.
                  </p>
                  <p>
                    Le refus d&rsquo;installation d&rsquo;un cookie peut
                    entra&icirc;ner l&rsquo;impossibilit&eacute;
                    d&rsquo;acc&eacute;der &agrave; certains services.
                    L&rsquo;utilisateur peut toutefois configurer son ordinateur
                    de la mani&egrave;re suivante, pour refuser
                    l&rsquo;installation des cookies :
                  </p>
                  <p>
                    Sous Internet Explorer : onglet outil (pictogramme en forme
                    de rouage en haut &agrave; droite) / options internet.
                    Cliquez sur Confidentialit&eacute; et choisissez Bloquer
                    tous les cookies. Validez sur Ok.
                  </p>
                  <p>
                    Sous Firefox : en haut de la fen&ecirc;tre du navigateur,
                    cliquez sur le bouton Firefox, puis aller dans l'onglet
                    Options. Cliquer sur l'onglet Vie priv&eacute;e.
                    Param&eacute;trez les R&egrave;gles de conservation sur :
                    utiliser les param&egrave;tres personnalis&eacute;s pour
                    l'historique. Enfin d&eacute;cochez-la pour
                    d&eacute;sactiver les cookies.
                  </p>
                  <p>
                    Sous Safari : Cliquez-en haut &agrave; droite du navigateur
                    sur le pictogramme de menu (symbolis&eacute; par un rouage).
                    S&eacute;lectionnez Param&egrave;tres. Cliquez sur Afficher
                    les param&egrave;tres avanc&eacute;s. Dans la section
                    "Confidentialit&eacute;", cliquez sur Param&egrave;tres de
                    contenu. Dans la section "Cookies", vous pouvez bloquer les
                    cookies.
                  </p>
                  <p>
                    Sous Chrome : Cliquez-en haut &agrave; droite du navigateur
                    sur le pictogramme de menu (symbolis&eacute; par trois
                    lignes horizontales). S&eacute;lectionnez Param&egrave;tres.
                    Cliquez sur Afficher les param&egrave;tres avanc&eacute;s.
                    Dans la section "Confidentialit&eacute;", cliquez sur
                    pr&eacute;f&eacute;rences. Dans l'onglet
                    "Confidentialit&eacute;", vous pouvez bloquer les
                    cookies.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>DROIT D'OPPOSITION</b>
                  </p>
                  <p>
                    Si vous ne souhaitez pas faire l'objet de d&eacute;marchage
                    t&eacute;l&eacute;phonique par des soci&eacute;t&eacute;s
                    tierces, vous pouvez gratuitement vous inscrire sur la liste
                    d'opposition{" "}
                    <a href="http://www.bloctel.fr">www.bloctel.fr</a>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION</b>
                  </p>
                  <p>
                    Tout litige en relation avec l&rsquo;utilisation du site{" "}
                    <a href="http://www.behalal.fr">www.behalal.fr</a> &nbsp;est
                    soumis au droit fran&ccedil;ais. Il est fait attribution
                    exclusive de juridiction aux tribunaux comp&eacute;tents de
                    Paris.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>LES PRINCIPALES LOIS CONCERN&Eacute;ES</b>
                  </p>
                  <p>
                    Loi n&deg; 78-87 du 6 janvier 1978, notamment
                    modifi&eacute;e par la loi n&deg; 2004-801 du 6 ao&ucirc;t
                    2004 relative &agrave; l'informatique, aux fichiers et aux
                    libert&eacute;s.
                  </p>
                  <p>
                    Loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans
                    l'&eacute;conomie num&eacute;rique.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>LEXIQUE</b>
                  </p>
                  <p>
                    Utilisateur : d&eacute;signe &agrave; la fois un Visiteur et
                    un Client pris individuellement ou un Visiteur et un Client
                    pris ensemble.
                  </p>
                  <p>
                    Informations personnelles : &laquo; les informations qui
                    permettent, sous quelque forme que ce soit, directement ou
                    non, l'identification des personnes physiques auxquelles
                    elles s'appliquent &raquo; (article 4 de la loi n&deg; 78-17
                    du 6 janvier 1978).
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Legal;
