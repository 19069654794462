import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import partner from "../images/partner.jpg";
import partner1 from "../images/partner1.svg";
import partner2 from "../images/partner2.svg";
import avatar_placeholder from "../images/avatar-placeholder.jpg";
import banner_partner from "../images/banner_partner.jpg";

const Partner = () => {
  return (
    <div>
      <HeaderHome />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + partner + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Les avantages B.e Halal</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container margin_30_40">
        <div className="row">
          <div className="col-lg-12">
            <div className="singlepost">
              <h1>Les avantages B.e Halal</h1>

              <div className="post-content">
                <p className="add_bottom_15">
                  <i className="icon_circle-slelected"></i> Première application
                  pour les courses 100% Halal. Une application très simple
                  d’utilisation, qui facilite les achats de TOUS vos produits
                  Halal
                </p>
                <h5>Nous vous proposons</h5>
                <p>
                  <i className="icon_circle-slelected"></i> Un produit digital,
                  très demandé par les consommateurs
                </p>
                <p>
                  <i className="icon_circle-slelected"></i> Une solution 100%
                  française avec une application et une entreprise Made in
                  France!
                </p>
                <p className="add_bottom_15">
                  <i className="icon_circle-slelected"></i> Un support français
                  intégralement géré par l’entreprise B.e Halal
                </p>
                <h5>Un confort d’achat </h5>
                <p>
                  <i className="icon_circle-slelected"></i> Faire plus de
                  courses, avec moins d’effort sans que vous ayez besoin de
                  bouger de chez vous!
                </p>
                <p className="add_bottom_15">
                  <i className="icon_circle-slelected"></i> La plupart de nos
                  produits référencés sont en ventes EXCLUSIVEMENT sur notre
                  plateforme B.E HALAL
                </p>
                <h5>Une consommation responsable</h5>
                <p className="add_bottom_15">
                  <i className="icon_circle-slelected"></i>2.5 % des bénéfices
                  de B.e Halal seront reversez directement à des œuvres
                  caritatives musulmanes
                </p>
                <h5>B.e Halal vous garantit</h5>
                <p>
                  <i className="icon_circle-slelected"></i>La traçabilité de ses
                  produits, particulièrement celle des viandes
                </p>
                <p>
                  <i className="icon_circle-slelected"></i>Une certification 100
                  % Halal par des organismes de certifications reconnus : AVS,
                  Deeni, Achahada et HIMS
                </p>
                <p>
                  <i className="icon_circle-slelected"></i>Grace à notre
                  partenaire de livraison Chronofresh nous nous engageons à vous
                  livrer dans un délais de 24h/48h chrono TOUT en respectant la
                  chaine du froid* afin de profiter pleinement des valeurs
                  nutritionnelles de vos produits
                </p>
                <p>
                  <b>
                    <font size="3" color="#349d00">
                      *
                    </font>
                  </b>
                  <font size="1">
                    En matière de respect de la chaîne du froid, les exigences
                    des réglementations française et européenne s'appliquent à
                    tous les acteurs des différentes filières alimentaires et
                    agroalimentaires. Le respect des conditions requises dans
                    toutes les phases allant de la fabrication à la distribution
                    de produits en passant par le stockage et le transport offre
                    les garanties sanitaires attendues.{" "}
                  </font>
                </p>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>

      <div>
        {/* <div className="text-center add_bottom_15">
          <h2>Comment fonctionne B.e Halal ?</h2>
        </div> */}

        <div className="owl-carousel owl-theme carousel_1 testimonials add_top_30">
          <div className="item">
            <blockquote>Les clients passent commande</blockquote>
            <p>
              <h5>
                Le client choisit ses produits préférés & passe commande
                directement sur l’application B.e Halal
              </h5>
            </p>
            <figure>
              <img
                src={avatar_placeholder}
                data-src={partner1}
                alt=""
                className="lazy"
              />
            </figure>
          </div>
          <div className="item">
            <blockquote>Nous préparons votre commande B.e Halal</blockquote>
            <p>
              <h5>
                Vous êtes livrés chez vous en J+1 pour toute commande faite
                avant 12h et en J+2 pour toute commande faite après 12h
              </h5>
            </p>
            <figure>
              <img
                src={avatar_placeholder}
                data-src={partner2}
                alt=""
                className="lazy"
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="container margin_60_40"></div>

      <Footer />
    </div>
  );
};

export default Partner;
