export const addFav = (num) => {
    return {
        type: 'INCREMENT',
        payLoad: num
    }
}

export const removeFav = (num) => {
    return {
        type: 'DECREMENT',
        payLoad: num
    }
}

//FOR LOGIN - IS USER LOGIN 
export const isLogin = (data) => {
    return {
        type: 'IS_LOGIN',
        payLoad: data
    }
}

//LOGIN - MOBILE VERIFIED
export const loginStep = (data) => {
    return {
        type: 'LOGIN_STEP',
        payLoad: data
    }
}

export const loading = (data) => {
    return {
        type: 'LOADER',
        payLoad: data
    }
}

//DEVICE FINGER PRINT
export const fingerprint = (data) => {
    return {
        type: 'DEVICE_FINGERPRINT',
        payLoad: data
    }
}

//USER DETAILS
export const user = (data) => {
    return {
        type: 'USER',
        payLoad: data
    }
}

//CATEGORIES
export const categories = (data) => {
    return {
        type: 'CATEGORIES',
        payLoad: data
    }
}

//Most Popular products
export const popular = (data) => {
    return {
        type: 'POPULAR_PRODUCT',
        payLoad: data
    }
}

//Ratings products
export const ratings = (data) => {
    return {
        type: 'RATINGS_PRODUCT',
        payLoad: data
    }
}

//Product Details
export const productDetails = (data) => {
    return {
        type: 'PRODUCT_DETAILS',
        payLoad: data
    }
}

export const cart = (data) => {
    return {
        type: 'ADD_CART',
        payLoad: data
    }
}

export const refreshCart = (data) => {
    return {
        type: 'REFRESH_CART',
        payLoad: data
    }
}

export const openLogin = (data) => {
    return {
        type: 'OPEN',
        payLoad: data
    }
}

export const openLoginAfter = (data) => {
    return {
        type: 'LOGIN_AFTER',
        payLoad: data
    }
}

