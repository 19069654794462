import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import "../styles/contacts.css";
import faq from "../images/faq.jpg";

const Faq = () => {
  return (
    <div>
      <HeaderHome />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + faq + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>FAQ</h1>
                <p>Toutes vos questions</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_gray">
        <div className="container margin_60_40">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="main_title center">
                <h3 style={{ marginBottom: 0 }}>
                  Questions fréquemment posées!
                </h3>
                <p>Jetez un œil à toutes les questions fréquemment posées</p>
              </div>

              <div
                role="tablist"
                className="add_bottom_15 accordion_2"
                id="accordion_group"
              >
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        data-toggle="collapse"
                        href="#collapseOne"
                        aria-expanded="true"
                      >
                        <i className="indicator icon_minus-06"></i>Qui
                        sommes-nous?
                      </a>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        B.e Halal est notre nouvelle plateforme de livraison
                        certifiée 100% halal qui vous permet de faire vos
                        courses 100% halal en ligne depuis notre site ou notre
                        application mobile.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseTwo"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Comment obtenir
                        un reçu ou une facture?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Pour obtenir un reçu vous pouvez le télécharger dans
                        votre historique de commandes dans la rubrique « mes
                        commandes »
                      </p>
                      <p>
                        Si toutefois vous souhaitez une facture, vous pouvez en
                        faire la demande à notre service client à l’adresse{" "}
                        <a href="mailto:support@behalal.fr">
                          support@behalal.fr
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseThree"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Comment commander
                        vos produits avec B.e Halal ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        1. Directement sur notre site ou en téléchargeant
                        l’application B.e Halal.
                      </p>
                      <p>2. Choisissez vos articles.</p>
                      <p>
                        2. Effectuez votre commande tous simplement en suivant
                        les indications.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseFive"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Vous souhaitez
                        nous contacter? Vous avez une suggestion, une question
                        sur notre service? Une erreur sur votre commande?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Vous pouvez nous contacter directement en nous écrivant
                        à{" "}
                        <a href="mailto:support@behalal.fr">
                          support@behalal.fr
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseSix"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Comment s’assurer
                        qu’on vous livrera des produits de qualité ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseSix"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Afin de répondre à vos attentes, nous sélectionnons des
                        produits pour leur qualité et leurs certifications 100%
                        Halal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseSeven"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Quels sont les
                        délais de livraison ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Nous vous livrons selon vos disponibilités à J+1 pour
                        toute commande faite avant 12h.
                      </p>
                      <p>À J+2 pour toute commande faite après 12h.</p>
                      <p>
                        Il s’agit peut être d’un produit indisponible et l’un
                        des membres de l’équipe B.e Halal a déjà essayé de vous
                        joindre afin de vous prévenir !
                      </p>
                      <p>Veuillez bien vérifier vos mails.</p>
                      <p>Dans ce cas, votre facture est déjà ajustée.</p>
                      <p>
                        Si ce n’est pas le cas, contactez-nous par mail{" "}
                        <a href="mailto:support@behalal.fr">
                          support@behalal.fr
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseEight"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Peut-on
                        programmer une livraison pour le lendemain?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseEight"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Oui : vous pouvez vous faire livrer le lendemain selon
                        votre disponibilité si vous commandez avant 12h.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseNine"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Qu’est-il de la
                        chaine du froid ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseNine"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Évidement B.E HALAL s’occupe de tout ! Notre partenaire
                        est équipé de camions frigorifiques pour maintenir la
                        température de vos produits tout au long du trajet.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseTen"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Que faire si ma
                        commande est en retard ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseTen"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Il peut y avoir des retards qui sont malheureusement
                        indépendant de notre volonté. Le numéro B.e Halal est
                        disponible afin qu’on puisse rapidement remédier à ce
                        type d’imprévu. L’ensemble des membres de l’équipe fera
                        son maximum pour vous livrer aussi rapidement que
                        possible.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseTwelve"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Je ne trouve pas
                        un produit, comment faire ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseTwelve"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Contactez-nous vite en nous écrivant à l’adresse{" "}
                        <a href="mailto:support@behalal.fr">
                          support@behalal.fr
                        </a>
                        . Un membre B.e Halal s’occupera de vous contacter dans
                        les meilleurs délais.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseThirteen"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Un produit n’est
                        pas conforme ou est abîmé, que faire ? Vous avez
                        réceptionné un produit avarié ? Nous nous sommes trompés
                        dans la quantité que vous avez reçue ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseThirteen"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Veuillez nous envoyer une photo du produit concerné
                        ainsi que votre nom et le numéro de votre commande par
                        mail à{" "}
                        <a href="mailto:support@behalal.fr">
                          support@behalal.fr
                        </a>{" "}
                        l’équipe B.e Halal fera son maximum pour traiter votre
                        requête, améliorer la qualité du service et vous
                        dédommager si besoin.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseFourteen"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Y a-t-il une
                        quantité limite de produit que je peux commander ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFourteen"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Vous avez sur B.e Halal la possibilité de commander
                        autant de produits que vous le souhaitez ; néanmoins
                        notre service ne s’adressant qu’aux particuliers, le
                        poids d’un colis ne pourra pas dépasser 30kg. Si votre
                        colis a un poids supérieur à ces limites, un second
                        colis sera automatiquement confectionné et compté comme
                        un colis supplémentaire.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseFifteen"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Comment sont
                        préparées mes commandes ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFifteen"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Votre commande est préparée suivant le type de produits
                        : secs, frais, surgelés. Ces produits sont conditionnés
                        dans des cartons résistants placés eux-mêmes dans des
                        bacs réfrigérés, scellés, adaptés à leurs protections
                        pendant le transport et à une livraison de vos achats
                        dans les meilleures conditions. Le transport s’effectue
                        dans des véhicules sous température dirigée (un
                        compartiment froid positif +4°C et une cellule froid
                        négatif -22°C pour les surgelés). Ainsi vos produits ne
                        subissent aucun choc de température, la chaîne du froid
                        est rigoureusement respectée jusqu’à la livraison à
                        votre domicile.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseSixteen"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Que se passe-t-il
                        si je ne suis pas chez moi le jour de la livraison ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseSixteen"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Votre présence à l’adresse de livraison indiquée est
                        indispensable le jour et pendant toute la durée du
                        créneau horaire choisi. Si vous étiez dans l’incapacité
                        d’être présent (cas de force majeure), nous vous
                        invitons à prendre contact le plus tôt possible avec
                        notre service client afin de convenir d’un nouvel
                        horaire de livraison.
                      </p>
                      <p>
                        En cas d’absence au moment de la livraison, le chauffeur
                        essayera d’entrer en contact avec vous par téléphone.
                      </p>
                      <p>
                        Veuillez donner toutes les consignes particulières
                        permettant au livreur de vous livrer dans de meilleures
                        conditions.
                      </p>
                      <p>
                        IMPORTANT : En cas d’absence, nous vous facturons une
                        nouvelle fois les frais de livraison, compte tenu du
                        double passage de notre livreur.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseSeventeen"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Pourquoi un
                        emballage sous vide ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseSeventeen"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        L'emballage sous vide pour une meilleure conservation
                        des aliments.
                      </p>
                      <p>
                        Les méthodes d’emballage ont définitivement un impact
                        sur la conservation des produits et sur le goût final de
                        ces derniers.
                      </p>
                      <p>
                        Les emballages réguliers en barquette (sur styromousse
                        ou carton imperméable) ne permettant pas de prolonger la
                        durée de conservation des produits et la présence
                        d’additifs pour en augmenter artificiellement le temps
                        de conservation, en plus d’en altérer le goût ont vite
                        fait d’encourager l’industrie agroalimentaire à trouver
                        une solution de meilleure qualité.
                      </p>
                      <p>
                        L’emballage sous vide augmente la durée de conservation
                        des viandes de façon naturelle, sans en altérer le goût.
                        C’est pourquoi, depuis les dernières années, nous
                        pouvons observer de plus en plus de producteurs adopter
                        cette méthode d’emballage améliorée et délaisser la
                        méthode standard d’emballage des produits de viande.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseEighteen"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Qu’est-ce que
                        l’emballage sous vide ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseEighteen"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        C’est un procédé visant à réduire l’oxygène
                        atmosphérique sous emballage hermétique et à prolonger
                        la qualité/durée de vie des produits de façon naturelle,
                        en plus d’en préserver les saveurs.
                      </p>
                      <p>
                        <b>Temps de conservation</b>
                      </p>
                      <p>
                        Dépendamment des viandes, un emballage sous vide permet
                        de les conserver le double du temps de conservation
                        régulier au réfrigérateur, tandis qu’on parle plutôt de
                        20 mois au congélateur (versus 3 mois dans un emballage
                        standard). En règle générale, on peut tout simplement se
                        référer à la date de péremption du produit emballé sous
                        vide.
                      </p>
                      <p>
                        Plus spécifiquement en retirant l’oxygène de
                        l’emballage, la durée de vie du produit est prolongée,
                        en plus de préserver sa saveur jusqu’à notre table.
                        Ainsi l’emballage sous vide et la fraîcheur de nos
                        produits est garantie 21 jours, alors que la moyenne de
                        l’industrie se situe à 10 jours.
                      </p>
                      <p>
                        L’emballage sous vide de nos produits permet également
                        de prolonger leur durée de conservation et d’en
                        préserver les saveurs. La durée de vie de nos produits
                        est ainsi plus longue que la concurrence qui utilise
                        encore des produits emballés de façon standard.
                      </p>
                      <p>
                        Ainsi, puisqu’il est important pour <b>B.e Halal</b>{" "}
                        d’offrir à sa clientèle ce qu’il y a de meilleur, c’est
                        pourquoi nous avons sélectionné des producteurs qui ont
                        à cœur la qualité et la conservation naturelle de nos
                        produits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseNineteen"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Pourquoi ma
                        viande sous vide a cette drôle de couleur ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseNineteen"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        Lorsque la viande n’est plus en contact avec l’oxygène,
                        elle perd sa couleur. Elle passe d’un rouge éclatant à
                        un rouge sombre. Ne vous inquiétez pas, c’est normal.
                      </p>
                      <p>
                        La myoglobine est la grande responsable de cette
                        transformation ! Cette molécule a pour rôle de
                        transporter l’oxygène dans le sang, surtout au niveau
                        des muscles de l’animal. Lorsque la myoglobine perd son
                        oxygène elle perd aussi sa couleur.
                      </p>
                      <p>
                        <b>Astuce</b>
                        <br />
                        Retirez la viande de l’emballage et laissez reposer 30
                        minutes à l’air libre…vous constaterez qu’en présence
                        d’oxygène, la viande reprend sa couleur d’origine.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab">
                    <h5>
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseTwenty"
                        aria-expanded="false"
                      >
                        <i className="indicator icon_plus"></i>Pourquoi ma
                        viande sous vide à cette drôle d’odeur ?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseTwenty"
                    className="collapse"
                    role="tabpanel"
                    data-parent="#accordion_group"
                  >
                    <div className="card-body">
                      <p>
                        L’emballage sous vide concentre les saveurs et les
                        odeurs. À l’ouverture du sachet, toutes ces odeurs se
                        libèrent d’un même coup ce qui a pour effet de nous
                        surprendre !
                      </p>
                      <p>
                        De plus, une viande de producteur est souvent plus
                        parfumée que celles que l’on retrouve dans nos réseaux
                        de grande distribution. Ceci est dû au type d’élevage et
                        à l’alimentation du bétail.
                      </p>
                      <p>
                        <b>Astuce</b>
                        <br />
                        Retirez la viande de l’emballage et laissez reposer 30
                        minutes à l’air libre…vous constaterez le retour d’une
                        odeur délicate et appétissante.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Faq;
