import React from "react";
import ReactDOM from "react-dom";
//import './index.css';
//import App from './App';
import reportWebVitals from "./reportWebVitals";
import { Route, Link, BrowserRouter, Redirect, Switch } from "react-router-dom";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import allReducer from "./reducers/index";
import thunk from "redux-thunk";

import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailed from "./pages/PaymentFailed";
import Category from "./pages/Category";
import MyOrders from "./pages/MyOrders";
import Dashboard from "./pages/Dashboard";
import Logout from "./pages/Logout";
import ProductSearch from "./pages/ProductSearch";
import About from "./pages/About";
import TellUs from "./pages/TellUs";
import Partner from "./pages/Partner";
import Contact from "./pages/Contact";
import Cgv from "./pages/Cgv";
import Privacy from "./pages/Privacy";
import Faq from "./pages/Faq";
import Popular from "./pages/Popular";
import Ratings from "./pages/Ratings";
import OurCertifications from "./pages/OurCertifications";
import OurEngagements from "./pages/OurEngagements";
import Delivery from "./pages/Delivery";
import Legal from "./pages/Legal";
import GoToTop from "./GoToTop";

const store = createStore(allReducer, applyMiddleware(thunk));

const routes = (
  <BrowserRouter>
    <Switch>
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/cgv" component={Cgv} />
      <Route exact path="/products/popular" component={Popular} />
      <Route exact path="/products/ratings" component={Ratings} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/about" component={About} />
      <Route exact path="/tell-us-everything" component={TellUs} />
      <Route exact path="/become-partner" component={Partner} />
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/checkout" component={Checkout} />
      <Route exact path="/checkout/back-shop/:id" component={Checkout} />
      <Route exact path="/my_orders" component={MyOrders} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/logout" component={Logout} />

      <Route exact path="/category/:id/:name" component={Category} />
      <Route exact path="/product/:id/:name" component={ProductDetails} />
      <Route exact path="/payment/success/:id" component={PaymentSuccess} />
      <Route exact path="/payment/failed/:id" component={PaymentFailed} />
      <Route exact path="/products" component={ProductSearch} />
      <Route exact path="/our-certifications" component={OurCertifications} />
      <Route exact path="/our-engagements" component={OurEngagements} />
      <Route exact path="/delivery" component={Delivery} />
      <Route exact path="/legal" component={Legal} />
      <Route path="/" component={Home} />
    </Switch>
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>{routes}</Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
