import React, { useState, useEffect, useRef, useParams } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import Cookies from 'js-cookie';

const Logout = () => {

    
    useEffect(function () {
        Cookies.remove('bhUser');
        Cookies.remove('bhUser', { path: '/' });
        Cookies.remove('bhCartId', { path: '/', sameSite: 'None'});
        Cookies.remove('bhCartId', { path: '' });
        reactLocalStorage.remove("bhCart");


        window.location = '/';
    }, []);

    return(
        <>
        </>
    )
}


export default Logout;