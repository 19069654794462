import React, { useState, useEffect, useRef, useParams } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/detail-page.css";
import Cookies from "js-cookie";
import { post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CartList from "../components/CartList";
import { reactLocalStorage } from "reactjs-localstorage";
import Checkout from "./Checkout";
import { openLogin, openLoginAfter } from "../actions/index";
import PulseLoader from "react-spinners/PulseLoader";
import { Helmet } from "react-helmet";

import veg from "../images/veg.png";
import non_veg from "../images/nonveg.png";
import frozen from "../images/frozen.png";

import { Link, useHistory } from "react-router-dom";

const Category = (props) => {
  const history = useHistory();

  const [products, setProducts] = useState([]);
  const [sub_categories, SetSub] = useState([]);
  const [title, setTitle] = useState(
    "B.e Halal - Découvrez nos sélections de produits certifiés 100% halal"
  );
  const [description, setDescription] = useState(
    "B.e Halal - Découvrez nos sélections de produits certifiés 100% halal"
  );
  const [loading, setLoading] = useState(0);
  const [bannerUrl, setBannerUrl] = useState("");
  const [searchProducts, setSearchProducts] = useState([]);
  const { t } = useTranslation();
  const [name, setName] = useState("");

    const [sub_cat_id, setSubCatId] = useState(0);

  useEffect(function () {
    var c = window.client;
    if (Cookies.get("bhId") == undefined) {
      Cookies.set("bhId", process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
    }
    _loadCategoryProducts();
  }, []);


    const _loadSubProds = (sub_cat_id_2) => {
        setLoading(1);
        setProducts([]);
        //goto("/category/" + sub_cat_id + "/" + seo_url(cname))

        const form = new FormData();
        form.append("butcher_id", Cookies.get("bhId"));
        form.append("category_id", sub_cat_id_2);

       // alert(sub_cat_id);
        //alert(sub_cat_id_2);

        console.log(form);
        post('wb/category/products', form)
            .then(async function (response) {
                setProducts(response.data.data.products);
                if (response.data.data.sub_category.length > 0) {
                    SetSub(response.data.data.sub_category);
                }
                

                setLoading(2);

            }).catch(function (error) { });

    }



  const _loadCategoryProducts = () => {
    setLoading(1);
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("category_id", props.match.params.id);

    post("wb/category/products", form)
      .then(async function (response) {
        setProducts(response.data.data.products);
        setTitle(response.data.data.title);
        setDescription(response.data.data.description);
        setBannerUrl(response.data.data.web_banner);
        setName(response.data.data.name);
        

          SetSub(response.data.data.sub_category);

          setLoading(2);
        setTimeout(function () {
          window.$(".responsive").slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 7,
            slidesToScroll: 7,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ],
          });
        }, 10);
      })
      .catch(function (error) {});
  };

  const seo_url = (url) => {
    return url
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, "");
  };

  const searchP = (e) => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("term", e.target.value);
    form.append("location", "");

    post("wb/products/search", form)
      .then(async function (response) {
        if (response.data.message === "failed") {
          setSearchProducts([]);
        } else {
          setSearchProducts(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //alert("You clicked outside of me!");
          setSearchProducts([]);
          var ele = document.getElementById("q");
          if (ele != null) {
            ele.value = "";
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const goto = (url) => {
      history.push(url);
      //window.location.reload(false)
      
  };

    const [activeCategory, setActiveCategory] = useState("all");
    function handleClick(category) {
        setActiveCategory(category);
    }


    useEffect(() => {
       // _loadSubProds();
    }, [sub_cat_id]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header />

      <div
        className="page_header element_to_stick"
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 10,
          color: "#fff",
          backgroundColor: "transparent",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
              <div className="breadcrumbs" style={{ color: "#fff" }}>
                <ul>
                  <li>
                    <a href="/" style={{ color: "#fff" }}>
                      Page d’accueil
                    </a>
                  </li>
                  <li style={{ textTransform: "capitalize" }}>{name}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="hero_single inner_pages background-image"
        style={{
          backgroundImage:
            "url(" + process.env.REACT_APP_IMG_URL + bannerUrl + ")",
          height: 380,
        }}
        data-background={
          "url(" + process.env.REACT_APP_IMG_URL + bannerUrl + ")"
        }
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1 style={{ textTransform: "capitalize" }}>{name}</h1>
                <div
                  className="form-group no-gutters custom-search-input"
                  style={{ marginTop: 50 }}
                >
                  <input
                    autoComplete="off"
                    id="q"
                    name="q"
                    onChange={(e) => searchP(e)}
                    className="form-control"
                    type="text"
                    placeholder="Que cherchez-vous..."
                  />
                  <i className="icon_search"></i>
                </div>
                {searchProducts != undefined ? (
                  <ul
                    ref={wrapperRef}
                    style={{
                      margin: 0,
                      marginTop: -18,
                      padding: 0,
                      maxHeight: 180,
                      overflow: "auto",
                    }}
                  >
                    {searchProducts.map((p, k) => (
                      <li
                        key={p.id}
                        style={{
                          borderBottom: "1px solid #e3e0e0",
                          color: "#0d0d0d",
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#fff",
                          padding: 4,
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ marginRight: 10 }}>
                            <a
                              href={
                                "/product/" +
                                p.id +
                                "/" +
                                seo_url(p.name + "-" + p.qty_type)
                              }
                            >
                              <img
                                style={{ width: 100 }}
                                src={process.env.REACT_APP_IMG_URL + p.image}
                                data-src={
                                  process.env.REACT_APP_IMG_URL + p.image
                                }
                                className="img-fluid lazy"
                                alt=""
                              />
                            </a>
                          </div>
                          <div>
                            <div
                              className="item_title"
                              style={{ textAlign: "left" }}
                            >
                              <span style={{ color: "#686767", fontSize: 12 }}>
                                {p.category_name}
                              </span>
                              <a
                                href={
                                  "/product/" + p.id + "/" + seo_url(p.name)
                                }
                              >
                                <h3 style={{ fontSize: 14, color: "#000" }}>
                                  {p.name}
                                </h3>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ justifyContent: "flex-end", float: "right" }}
                        >
                          <span
                            style={{
                              marginTop: 19,
                              display: "block",
                              marginRight: 20,
                            }}
                          >
                            <b>{p.unit_price} Euros</b>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* sub category start */}

          {sub_categories.length > 0 ? (
              <div style={{
                  paddingLeft: 70,
                  paddingRight:70
              }}>
        <div
          className="slider responsive"
          style={{
            marginTop: -45,
            zIndex: 2,
          }}
              >

                  <div
                      
                      className={activeCategory === "all" ? "active-category" : ""}

                      onClick={() => {

                          _loadSubProds(props.match.params.id);
                          handleClick("all");
                      }}
                      
                  >
                      <div
                          style={{
                              borderRadius: 4,
                              cursor: "pointer",
                              boxShadow: "0 1px 4px rgba(0,0,0,.08)",
                              border: "1px solid #f4f4f4",
                              width: 168,
                              height: 88,

                             
                             
                              backgroundSize: "cover",
                              backgroundPosition: "50%",
                                  opacity: 1,
                                  backgroundColor: "grey",
                                  color: "#589442",
                                  backgroundBlendMode: "multiply",
                                  // override styles for elements with the "active-category" class
                                  ...(activeCategory === "all" && {
                                      backgroundColor: "white",
                                      backgroundBlendMode: "",
                                      color: "#589442"

                                  })
                          }}
                      >
                              <h3 style={{
                                 
                                  marginTop: "39px",
                                  marginLeft: "-5px",
                                  textAlign: "center",

                                  ...(activeCategory === "all" && {
                                      color: "#589442",

                                  })
        
                              }} className="carousel_slack" > Tout</h3>
                      </div>
                  </div>


          {sub_categories &&
            sub_categories.map((c, v) => (
              <div
                    key={c.id}
                    className="multiple"
                    
                    onClick={() => {
                        //  goto("/category/" + c.id + "/" + seo_url(c.name)),
                        //  setSubCatId(c.id)
                        handleClick(c.name);
                        _loadSubProds(c.id)

                    }}
              >
                    <div
                        className={activeCategory === c.name ? "active-category" : ""}
                  style={{
                    borderRadius: 4,
                    cursor: "pointer",
                    boxShadow: "0 1px 4px rgba(0,0,0,.08)",
                    border: "1px solid #f4f4f4",
                    width: 168,
                    height: 88,
                    backgroundImage:
                      "url(" + process.env.REACT_APP_IMG_URL + c.image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    opacity: 1,
                          
                      // default styles for all elements
                      backgroundColor: "grey",
                      backgroundBlendMode: "multiply",
                      // override styles for elements with the "active-category" class
                      ...(activeCategory === c.name && {
                          backgroundColor: "",
                          backgroundBlendMode: ""
                      })
                                
                            

                  }}
                    >
                        <h3 className="carousel_slack" style={{

                            marginTop: "50px",
                            marginLeft:"10px",
                        }} >{c.name}</h3>
                </div>
              </div>
            ))}
                  </div>
              </div>
      ) : (
        <></>
      )}

      {/* sub category ends */}

      <main>
        <div className="container margin_30_40">
          <div className="row">
            {loading === 1 ? (
              <div className="col-lg-12 text-center">
                <PulseLoader color="#83c55e" size={8} />
              </div>
            ) : (
              <></>
            )}

            {loading === 2 && products.length === 0 ? (
              <div className="col-lg-12 text-center">
                <span>{t("category_product_not")}</span>
              </div>
            ) : (
              <></>
            )}

            <div className="col-lg-12">
              <div className="row">
                {products.map((p, k) => (
                  <div
                    key={p.name}
                    className="col-xl-3 col-lg-6 col-md-6 col-sm-6"
                  >
                    <div className="strip">
                      <figure>
                        <img
                          style={{ top: "43%" }}
                          src={process.env.REACT_APP_IMG_URL + p.image}
                          data-src={process.env.REACT_APP_IMG_URL + p.image}
                          className="img-fluid lazy"
                          alt=""
                        />
                        <Link
                          to={"/product/" + p.id + "/" + seo_url(p.name)}
                          className="strip_info"
                        >
                          <div className="item_title">
                            <h3>{p.name}</h3>
                          </div>
                        </Link>
                      </figure>
                      <ul>
                        <li>
                          <b>
                            {" "}
                            <span>{p.unit_price} Euros</span>{" "}
                          </b>
                        </li>{" "}
                        {p.discount > 0 ? (
                          <li>
                            <span
                              style={{
                                textDecorationLine: "line-through",
                                marginLeft: "10px",
                                fontSize: "0.8rem",
                              }}
                            >
                              {p.original_price} Euros
                            </span>
                          </li>
                        ) : null}
                        <li>
                          <div className="score">
                            {p.type == 1 ? (
                              <img src={veg} alt="" />
                            ) : (
                              <img src={non_veg} alt="" />
                            )}
                            {p.product_frozen == 1 ? (
                              <img
                                src={frozen}
                                alt=""
                                style={{ width: 25, marginLeft: 10 }}
                              />
                            ) : null}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination_fg" style={{ display: "none" }}>
                <a href="#">&laquo;</a>
                <a href="#" className="active">
                  1
                </a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#">&raquo;</a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Category;
