import React, { useState, useHook, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import facebook_icon from "../images/facebook_icon.svg";
import instagram_icon from "../images/instagram_icon.svg";
import cards from "../images/cards_all.svg";
import stores from "../images/stores.png";

import appstore from "../images/logo-appstore.svg";
import playstore from "../images/logo-playstore.svg";

import { useTranslation } from "react-i18next";
import "../translations/i18n";
import Cookies from "js-cookie";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import GoToTop from "./../GoToTop";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  AndroidView,
  IOSView,
} from "react-device-detect";

const Footer = (props) => {
  const { t } = useTranslation();
  const inputM = useRef(null);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const login = () => {
    alert(inputM.current.value);
  };

  const myFunction = () => {
    Cookies.set("cookie_policy", "accept");
    setShowCookieBanner(false);
  };

  useEffect(function () {
    if (Cookies.get("cookie_policy") == undefined) {
      setShowCookieBanner(true);
    } else {
      setShowCookieBanner(false);
    }
  }, []);

  const newsLetter = () => {
    var url = "https://behalal.fr/assets/newsletter.php";
    //Your registration has been successful taken into account.

    var formdata = new FormData();
    var email_newsletter = document.getElementById("email_newsletter").value;
    formdata.append("email_newsletter", email_newsletter);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://behalal.fr/assets/newsletter.php", requestOptions)
      .then((response) => response.text())
      .then((result) =>
        alert("Your registration has been successful taken into account.")
      )
      .catch((error) => console.log("error", error));
  };

  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-6">
              <h3 data-target="#collapse_1">Entreprise</h3>
              <div className="collapse dont-collapse-sm links" id="collapse_1">
                <ul>
                  <li>
                    <Link to={"/about"}>A propos de nous</Link>
                  </li>

                  {/*<li><a href="/contact">Contactez nous</a></li>*/}
                  <li>
                    <Link to={"/tell-us-everything"}>Dites-nous tout</Link>
                  </li>

                  <li>
                    <Link to={"/become-partner"}> Pourquoi B.E HALAL ?</Link>
                  </li>
                  <li>
                    <Link to={"/our-engagements"}> Nos engagements</Link>
                  </li>
                </ul>
              </div>
              <GoToTop />
            </div>
            <div className="col-lg-2 col-md-6">
              <h3 data-target="#collapse_2">Liens Rapides</h3>
              <div className="collapse dont-collapse-sm links" id="collapse_2">
                <ul>
                  <li>
                    <Link to={"/cgv"}>CGV</Link>
                  </li>
                  <li>
                    <Link to={"/privacy"}>Politique de confidentialité</Link>
                  </li>
                  <li>
                    <Link to={"/faq"}>FAQ</Link>
                  </li>
                  <li>
                    <Link to={"/legal"}>Mentions légales</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <h3 data-target="#collapse_3">Nos Coordonnées</h3>
              <div
                className="collapse dont-collapse-sm contacts"
                id="collapse_3"
              >
                <ul>
                  <li>
                        <i className="icon_mobile"></i>33 1 62 20 09 25
                  </li>
                  <li>
                    <i className="icon_mail_alt"></i>
                    <a href="mailto:contact@behalal.fr">contact@behalal.fr</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 data-target="#collapse_4">
                inscrivez vous à notre newsletter
              </h3>
              <div className="collapse dont-collapse-sm" id="collapse_4">
                <div id="newsletter">
                  <div id="message-newsletter"></div>
                  <form
                    method="post"
                    action="#"
                    name="newsletter_form"
                    id="newsletter_form"
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        name="email_newsletter"
                        id="email_newsletter"
                        className="form-control"
                        placeholder="Entrez votre adresse email"
                      />
                      <button
                        onClick={() => newsLetter()}
                        type="submit"
                        id="submit-newsletter"
                      >
                        <i className="arrow_carrot-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="follow_us">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/Be-Halal-103746578512759/"
                        target="_blank"
                      >
                        <img
                          src={facebook_icon}
                          data-src={facebook_icon}
                          alt=""
                          className="lazy"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/b.ehalal/"
                        target="_blank"
                      >
                        <img
                          src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                          data-src={instagram_icon}
                          alt=""
                          className="lazy"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12"
              style={{
                textAlign: isMobile == true ? "center" : "right",
                marginTop: isMobile == true ? 15 : 0,
              }}
            >
              <div>
                <a
                  href="https://apps.apple.com/us/app/b-e-halal/id1611233109"
                  target="_blank"
                >
                  <img src={appstore} />
                </a>
              </div>
              <br />
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.behalal"
                  target="_blank"
                >
                  <img src={playstore} />
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className="row add_bottom_25">
            <div className="col-lg-6">
              <ul
                className="footer-selector clearfix"
                style={{ display: "none" }}
              >
                <li>
                  <div className="styled-select lang-selector">
                    <select defaultValue={"French"}>
                      <option value="French">French</option>
                      <option value="English">English</option>
                    </select>
                  </div>
                </li>
                <li style={{ display: "none" }}>
                  <img
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                    data-src={cards}
                    alt=""
                    width="198"
                    height="30"
                    className="lazy"
                  />
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <ul className="additional_links">
                <li>
                  <a href="cgv">CGV</a>
                </li>
                <li>
                  <span>© 2023 B.e Halal</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <div id="toTop"></div>

      <div className="layer"></div>

      <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">
        <div className="modal_header">
          <h3>{t("sign_in")}</h3>
        </div>

        <div className="sign-in-wrapper">
          <a
            href="#0"
            className="social_bt facebook"
            style={{ display: "none" }}
          >
            Login with Facebook
          </a>
          <a href="#0" className="social_bt google" style={{ display: "none" }}>
            Login with Google
          </a>
          <div className="divider" style={{ display: "none" }}>
            <span>Or</span>
          </div>
          <div className="form-group">
            <label>{t("mobile_number")}</label>
            <input
              type="number"
              ref={inputM}
              className="form-control"
              name="mobile"
              id="mobile"
              min="9"
              max="9"
            />
            <i className="icon_phone"></i>
          </div>
          <div className="form-group" style={{ display: "none" }}>
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              id="password"
              defaultValue=""
            />
            <i className="icon_lock_alt"></i>
          </div>
          <div className="clearfix add_bottom_15">
            <div className="checkboxes float-left">
              <label className="container_check">
                {t("rebember")}
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="float-right mt-1">
              {/* <a id="forgot" href="javascript:void(0);"> */}
              <a id="forgot">{t("forgot")}</a>
            </div>
          </div>
          <div className="text-center">
            <button className="btn_1 full-width mb_5 one">{t("login")}</button>
            {t("dontaccount")} <a href="account.html">{t("signup")}</a>
          </div>
          <div id="forgot_pw">
            <div className="form-group">
              <label>Please confirm login email below</label>
              <input
                type="email"
                className="form-control"
                name="email_forgot"
                id="email_forgot"
              />
              <i className="icon_mail_alt"></i>
            </div>
            <p>
              You will receive an email containing a link allowing you to reset
              your password to a new preferred one.
            </p>
            <div className="text-center">
              <input type="submit" value="Reset Password" className="btn_1" />
            </div>
          </div>
        </div>
      </div>
      {showCookieBanner === true ? (
        <div
          style={{
            position: "fixed",
            height: 250,
            paddingTop: 18,
            bottom: 0,
            zIndex: 1000,
            background: "rgba(0, 0, 0, 0.8)",
            width: "100%",
            textAlign: "center",
          }}
          id="cookie_policy"
        >
          <span
            style={{
              color: "#FFF",
              textAlign: "center",
              fontSize: ".9em",
              fontWeight: "bold",
            }}
          >
            Informations relatives aux cookies
          </span>
          <br />
          <span style={{ color: "#fff", fontSize: 13 }}>
            En poursuivant votre navigation sans modifier vos paramètres, vous
            acceptez l'utilisation des cookies ou technologies similaires pour
            disposer de services et d'offres
          </span>
          <br />
          <span style={{ color: "#fff", fontSize: 13 }}>
            adaptés à vos centres d'intérêts, la possibilité de partager les
            contenus sur les réseaux sociaux ainsi que pour la sécurisation des
            transactions sur notre site.{" "}
          </span>
          <br />
          <span style={{ color: "#fff", fontSize: 13 }}>
            Pour plus d'informations{" "}
            <a
              target="_blank"
              href="https://behalal.fr/cookies.html"
              style={{ color: "#76CDEB" }}
            >
              cliquez-ici
            </a>
          </span>
          <br />
          <button
            style={{
              borderWidth: 0,
              background: "#1c844c",
              outline: "none",
              cursor: "pointer",
              display: "inline-block",
              textDecoration: "none",
              padding: 8,
              color: "#fff",
              fontWeight: 500,
              marginTop: 20,
              textAlign: "center",
            }}
            onClick={() => myFunction()}
          >
            {" "}
            j'ai compris{" "}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Footer;
