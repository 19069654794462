import axios from 'axios';
import Cookies from 'js-cookie';

import {createStore, applyMiddleware} from 'redux';
import userReducer from '../reducers/user';
import { user } from '../actions/index';

const store = createStore(userReducer);
var c = window.client;
//console.log("1111111111:",c.getFingerprint())

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Accept': 'application/json','Content-Type':'multipart/form-data' }
});

instance.defaults.headers.post['Accept'] = 'application/json';
instance.defaults.headers.get['Accept'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
instance.defaults.headers.get['Content-Type'] = 'multipart/form-data';

instance.interceptors.request.use(
     config => {

        if (!config.headers.Authorization) {
            const token = getData()

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
                config.headers.Fingerprint = c.getFingerprint()
            }
        }

        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    res => res,
    err => {
        if (err.response.status === 401) {
            store.dispatch(user({token: '', mobile: '', name: ''}));
            //Cookies.remove('bhUser');
            throw new Error('login expired');
        }
        throw err;
    }
);

const getData = () => {
    try {
        const value = Cookies.getJSON('bhUser')
        if (value.token !== undefined) {
            return value.token;
        }
    } catch (e) {
        // error reading value
    }
}

function returnAxiosInstance() {
    return instance
}

export function get(url) {
    const axios = returnAxiosInstance();
    return axios.get(url);
}

export function post(url, requestData) {

    console.log(' requestData ::',requestData)


    const axios = returnAxiosInstance();
    return axios.post(url, requestData);
}