import {reactLocalStorage} from 'reactjs-localstorage';
import moment from 'moment';

export default Cache = async (key) => {

    const cacheStorage = await caches.open(key);
    const cachedResponse = await cacheStorage.match('http://localhost:3000');

    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    return cachedResponse.json().then((CacheData) => {
        if (CacheData.expiry_time == undefined)
            return false;

        var endTime = moment(CacheData.expiry_time);
        var currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

        console.log('currentTime', moment(currentTime).format("YYYY-MM-DD HH:mm:ss"))
        console.log('endTime', moment(endTime).format("YYYY-MM-DD HH:mm:ss"))

        var duration = moment.duration(moment(currentTime).diff(endTime));
        var minutes = duration.asMinutes();

        console.log('minutes', minutes)
        
        if (minutes >= 0) 
            return false;

        return CacheData;
    });

    /*var CacheData = reactLocalStorage.getObject(key);

    if (Object.keys(CacheData).length == 0) {
        return false;
    }
    else {
        if (CacheData.expiry_time == undefined)
            return false;

        var endTime = moment(CacheData.expiry_time);
        var currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

        console.log('currentTime', moment(currentTime).format("YYYY-MM-DD HH:mm:ss"))
        console.log('endTime', moment(endTime).format("YYYY-MM-DD HH:mm:ss"))

        var duration = moment.duration(moment(currentTime).diff(endTime));
        var minutes = duration.asMinutes();

        console.log('minutes', minutes)
        
        if (minutes >= 0) 
            return false;

        return CacheData;
    }*/
}

export const setCache = (key, data) => {
    var objectData = {
        data : data,
        expiry_time : moment(moment().format("YYYY-MM-DD HH:mm:ss")).add(2, 'minutes')
    }
    //reactLocalStorage.setObject(key, objectData)
    //console.log('objectData:: ', objectData);
    objectData = new Response(JSON.stringify(objectData));
    //console.log('objectData: ', objectData);

    if ('caches' in window) {
		caches.open(key).then((cache) => {
            cache.put('http://localhost:3000', objectData);
        });
    }

}