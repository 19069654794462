import React, { useState, useEffect, useRef, useParams } from "react";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";
import "../styles/submit.css";
import everything from "../images/everything.jpg";
import Cookies from "js-cookie";
import { post, get } from "../adapters/index";

const TellUs = () => {
  Element.prototype.insertAfter = function (newEl) {
    this.parentNode.insertBefore(newEl, this.nextSibling);
  };

  const contactus = () => {
    var error = false;
    var ele = document.querySelectorAll(".error");

    for (var j = 0; j < ele.length; j++) {
      ele[j].remove();
    }

    var name_contact = document.getElementById("name_contact");
    var email_contact = document.getElementById("email_contact");
    var telephone_register = document.getElementById("telephone_register");
    var message_contact = document.getElementById("message_contact");

    if (name_contact.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "s'il vous plaît entrez le nom";
      name_contact.insertAfter(newEll);
      error = true;
    }

    if (email_contact.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "veuillez entrer l'adresse e-mail";
      email_contact.insertAfter(newEll);
      error = true;
    }

    if (telephone_register.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "veuillez entrer le numéro de téléphone";
      telephone_register.insertAfter(newEll);
      error = true;
    }

    if (message_contact.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "s'il vous plaît entrer un message";
      message_contact.insertAfter(newEll);
      error = true;
    }

    var details = {
      name_contact: name_contact.value,
      email_contact: email_contact.value,
      telephone_register: telephone_register.value,
      message_contact: message_contact.value,
    };

    if (error === true) {
      return false;
    }

    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("page", "tellus");
    form.append("details", JSON.stringify(details));

    post("https://behalal.fr/assets/contact.php", form)
      .then(async function (response) {
        name_contact.value = "";
        email_contact.value = "";
        telephone_register.value = "";
        message_contact.value = "";
        alert("Soumis avec succès");
      })
      .catch(function (error) {});
  };

  return (
    <div>
      <HeaderHome popup={false} />
      <div
        className="hero_single inner_pages background-image"
        data-background={"url(" + everything + ")"}
      >
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1>Dites-nous tout</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container margin_60_40 bg_gray">
          <div className="main_title center">
            <span>
              <em></em>
            </span>
            <h2>Dites-nous tout</h2>
          </div>

          <div className="row justify-content-center align-items-center add_bottom_15">
            <div className="col-lg-8">
              <div className="box_about">
                <h3>Cher(e) client(e),</h3>
                <p className="lead">
                  Vous nous avez fait confiance et nous vous en remercions.
                </p>
                <p>
                  Il nous importe désormais de garder cette confiance, et ainsi
                  de préserver votre fidélité.
                </p>
                <p>
                  Afin de mesurer le degré de votre satisfaction, nous vous
                  serions reconnaissants de bien vouloir nous faire part de vos
                  souhaits;
                </p>
                <p>
                  Cela nous permettra de connaître avec précision vos
                  impressions sur nos prestations actuelles.
                </p>
                <p>
                  La qualité s’obtient par une remise en question constante et
                  vos réponses serviront à orienter nos efforts pour
                  l’amélioration de nos offres.
                </p>
                <p>Nous vous remercions de votre participation.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="form">
        <div className="bg_white pattern" id="submit">
          <div className="container margin_60_40">
            <div className="text-center add_bottom_15">
              <h2>Nous voulons en savoir plus !</h2>
              <p>
                Remplissez le formulaire ci-dessous, nous reviendrons vers vous
                très rapidement
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 add_bottom_25">
                <div id="message-contact"></div>
                <form
                  method="post"
                  action="javascript:void(0);"
                  id="contactform"
                  autoComplete="off"
                />
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nom"
                    id="name_contact"
                    name="name_contact"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    id="email_contact"
                    name="email_contact"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Numéro de téléphone"
                    name="telephone_register"
                    id="telephone_register"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    style={{ height: 150 }}
                    placeholder="Message"
                    id="message_contact"
                    name="message_contact"
                  ></textarea>
                </div>
                <div className="form-group">
                  <input
                    className="btn_1 full-width"
                    type="submit"
                    value="Soumettre"
                    onClick={() => contactus()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TellUs;
