import systemReducer from './system';
import favoriteReducer from './favorite';
import loginReducer from './login';
import loadingReducer from './loading';
import userReducer from './user';
import categoryReducer from './categories';
import productReducer from './products';
import cartReducer from './cart';
import openReducer from './open';
import {combineReducers} from 'redux';


const allReducers = combineReducers({
    favorite: favoriteReducer,
    login: loginReducer,
    loading: loadingReducer,
    fingerprint: systemReducer,
    user: userReducer,
    categories : categoryReducer,
    products: productReducer,
    cart: cartReducer,
    open: openReducer
});

export default allReducers;