import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/css_skelton.css";
import Cookies from "js-cookie";
import { post, get } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CartList from "../components/CartList";
import { reactLocalStorage } from "reactjs-localstorage";

import i18n from "../translations/i18n";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import addCart from "../utility/Cart";
import axios from "axios";
import moment from "moment";
//import 'moment/locale/en';

import { store } from "react-notifications-component";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import { getDay } from "date-fns";
import { subDays } from "date-fns";

import fr from "date-fns/locale/fr"; // the locale you want
registerLocale("fr", fr); // register it with the name you want

const Checkout = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState([]);
  const [dates, setDeliveryDates] = useState([]);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [pincodeValid, setPincodeValid] = useState("invalid");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTimeSlot, setDeliveryTimeSlot] = useState("");
  const [deliveryPincode, setDeliveryPincode] = useState(0);
  const [deliveryId, setDeliveryId] = useState(0);
  const [items, setItems] = useState([]);
  const [vatDetails, setVatDetails] = useState([]);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total_product_price, setTotalProductPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [cartId, setCartId] = useState(0);
  const [checkout, setCheckout] = useState(false);
  const [coupon_code, setCouponCode] = useState("");
  const [vat, setVat] = useState(0);
  const [freeOrderBalanceAmt, setFreeOrderBalanceAmt] = useState(0);
  const [type, setType] = useState("home");
  const [min_order, setMinOrder] = useState(0);

  const [coupon_amount, setCouponAmount] = useState(0);

  // for dates start
  //for date picker
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [addressPincode, setAddressPincode] = useState("");
  const [excludeDates, setExcludeDates] = useState([]);
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 1;
  };

  useEffect(function () {
    // alert(startDate.getDate() + 2);
    // setExcludeDates([new Date(), addDays(new Date(), 2)]);
    setExcludeDates([
      new Date("November 1, 2022"),
      new Date("November 11, 2022"),
    ]);

    if (new Date().getHours() >= 12) {
      setStartDate(addDays(new Date(), 2));
    } else {
      setStartDate(addDays(new Date(), 1));
    }
  }, []);

  const changeDeliveryDate = (date) => {
    setSelectedDate(date);
  };

  useEffect(
    function () {
      fetchDeliveryDates();
      // alert(selectedDate);
    },
    [selectedDate]
  );

  useEffect(function () {}, [deliveryPincode]);

  useEffect(
    function () {
      fetchDeliveryDates();
    },
    [addressPincode]
  );
  //date ends
  // for date ends

  useEffect(
    function () {
      if (
        cartId != 0 &&
        deliveryTimeSlot != "" &&
        deliveryPincode != 0 &&
        deliveryId != 0
      ) {
        //updateDeliveryDetails(cartId, deliveryTimeSlot, deliveryPincode, deliveryId);
      }
    },
    [address, cartId, deliveryPincode, deliveryId]
  );

  useEffect(function () {
    //moment.locale('fr');
    //var dd = moment().add(1,'days').format('DD-MM-YYYY').toString();
    //setDeliveryDate(dd)

    if (Cookies.get("bhUser") != undefined) {
      fetchUserCartData();
      //   fetchDeliveryDates();
      fetchAddress();
    } else {
      window.location = "/";
    }
  }, []);

  const updateDeliveryDetails = (
    cartId,
    deliveryTimeSlot,
    deliveryPincode,
    deliveryId
  ) => {
    const form = new FormData();

    form.append("butcher_id", Cookies.get("bhId"));
    form.append("cart_id", cartId);
    form.append("delivery_date", deliveryDate);
    form.append("delivery_timeslot", deliveryTimeSlot);
    form.append("pincode", deliveryPincode);
    form.append("delivery_id", deliveryId);

    post("cart/save_delivery_date_pincode_wb", form)
      .then(async function (response) {
        setDeliveryCharge(response.data.delivery_charge);
        setTotal(response.data.total);
        setCheckout(response.data.checkout);
        setDeliveryDate(response.data.delivery_date);

        setMinOrder(response.data.minimum_order);

        // if (response.data.minimum_order > 0) {
        //   $("#minimum_order_para").show();
        // } else {
        //   $("#minimum_order_para").hide();
        // }
        var free_amt =
          response.data.delivery_charge_details.free_order -
          response.data.product_total_price;
        if (free_amt > 0) {
          free_amt = parseFloat(free_amt).toFixed(2);
          setFreeOrderBalanceAmt(free_amt);
        } else {
          setFreeOrderBalanceAmt(0);
        }
      })
      .catch(function (error) {});
  };

  const fetchDeliveryCharge = (pincode, cartId) => {
    //alert(pincode);
    //alert(cartId)
  };

  const fetchDeliveryDates = () => {
    if (!selectedDate) {
      return;
    }
    const temp_date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(selectedDate);
    const dd = moment(temp_date).format("YYYY-MM-DD");

    const form = new FormData();
    form.append("pincode", addressPincode);
    // alert(addressPincode+ ' ---- '+dd);
    form.append("day", dd);

    post("slots_with_zone", form)
      .then(async function (response) {
        const temp_date = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(selectedDate);
        const dd = moment(temp_date).format("YYYY-MM-DD");
        setDeliveryDate(dd);
        setDeliveryDates(response.data.data);
        setDeliveryTimeSlot(response.data.data);
      })
      .catch(function (error) {});
  };

  const fetchAddress = () => {
    get("customer/address-list")
      .then(async function (response) {
        setAddress(response.data.data.customeraddress);

        if (response.data.data.customeraddress.length == 0) {
          onOpenModal();
        } else {
          fetchDeliveryCharge(
            response.data.data.customeraddress[0].pincode,
            cartId
          );
          setDeliveryPincode(response.data.data.customeraddress[0].pincode);
          setAddressPincode(response.data.data.customeraddress[0].pincode);
          setDeliveryId(response.data.data.customeraddress[0].id);
          setMinOrder(response.data.data.customeraddress[0].min_order_amount);
          onCloseModal();
        }
      })
      .catch(function (error) {});
  };

  const fetchUserCartData = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("cart/wb/checkout_view", form)
      .then(async function (response) {
        if (response.data.data.length == 0) {
          window.location = "/";
        }
        console.log("cart view api -- " + response);
        setItems(response.data.data);
        setTotal(response.data.total_cart_price);
        setCartId(response.data.data[0].cart_id);
        setDeliveryCharge(response.data.delivery_charge);
        setCheckout(response.data.checkout);
        setVat(response.data.product_total_vat);
        setVatDetails(response.data.vat_details);

        setCouponCode(response.data.coupon_code);
        setCouponAmount(response.data.coupon_discount_amount);

        setTotalProductPrice(response.data.total_product_price);
        //setFreeOrderBalanceAmt
        console.log(response.data);

        var free_amt =
          response.data.delivery_charge_details.free_order -
          response.data.total_product_price;
        if (free_amt > 0) {
          free_amt = parseFloat(free_amt).toFixed(2);
          setFreeOrderBalanceAmt(free_amt);
        } else {
          setFreeOrderBalanceAmt(0);
        }
      })
      .catch(function (error) {
        //window.location = '/';
      });
  };

  const validatePincode = async (pincode) => {
    //customer/validate/pincode

    /*const form = new FormData();
        form.append("pincode", pincode);
        post('customer/validate/pincode', form).then(async function (response) {
            setPincodeValid(...response.data.data.pincode);
        }).catch(function (error) {});*/

    const form = new FormData();
    form.append("pincode", pincode);

    const options = {
      method: "POST",
      url: process.env.REACT_APP_API_URL + "customer/validate/pincode",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Accept: "application/json",
        Authorization: "Bearer " + Cookies.getJSON("bhUser"),
      },
      data: form,
    };

    await axios
      .request(options)
      .then(function (response) {
        setPincodeValid(...response.data.data.pincode);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const alerts = (msg) => {
    store.addNotification({
      title: "Alerte",
      message: msg,
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  const info = (msg) => {
    store.addNotification({
      title: "Alerte",
      message: msg,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  Element.prototype.insertAfter = function (newEl) {
    this.parentNode.insertBefore(newEl, this.nextSibling);
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const add = () => {
    var error = false;
    var ele = document.querySelectorAll(".error");

    for (var j = 0; j < ele.length; j++) {
      ele[j].remove();
    }

    const newEl = document.createElement("div");
    newEl.classList.add("error");

    var first_name = document.getElementById("first_name");
    var last_name = document.getElementById("last_name");
    var phone = document.getElementById("phone");
    var email = document.getElementById("email");
    var address1 = document.getElementById("address1");
    var address2 = document.getElementById("address2");
    var address3 = document.getElementById("address3");

    var ville = document.getElementById("ville");
    var land_mark = ""; //document.getElementById('land_mark');
    var pincode = document.getElementById("pincode");

    if (first_name.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Entrez s'il vous plait prénom";
      first_name.insertAfter(newEll);
      error = true;
    }

    if (last_name.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Entrez s'il vous plait nom de famille";
      last_name.insertAfter(newEll);
      error = true;
    }
    if (email.value.length == 0 || !validateEmail(email.value)) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Veuillez saisir une adresse e-mail valide";
      email.insertAfter(newEll);
      error = true;
    }
    if (phone.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Entrez s'il vous plait numéro de portable";
      phone.insertAfter(newEll);
      error = true;
    }
    if (address1.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Entrez s'il vous plait adresse ligne 1";
      address1.insertAfter(newEll);
      error = true;
    }
    /*if (address2.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait adresse ligne 2";
            address2.insertAfter(newEll);
            error = true;
        }
        if (address3.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait adresse ligne 3";
            address3.insertAfter(newEll);
            error = true;
        }*/
    if (ville.value.length == 0) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Entrez s'il vous plait ville";
      ville.insertAfter(newEll);
      error = true;
    }
    /*if (land_mark.value.length == 0) {
            var newEll = document.createElement("div");
            newEll.classList.add('error');
            newEll.innerHTML = "Entrez s'il vous plait point de repère";
            land_mark.insertAfter(newEll);
            error = true;
        }*/
    var filterPincode = parseInt(pincode.value);

    if (pincode.value.length < 5 || isNaN(filterPincode)) {
      var newEll = document.createElement("div");
      newEll.classList.add("error");
      newEll.innerHTML = "Entrez s'il vous plait code postal";
      pincode.insertAfter(newEll);
      error = true;
    }

    if (error === true) {
      return false;
    }

    const form = new FormData();
    form.append("pincode", pincode.value);
    post("customer/validate/pincode", form)
      .then(async function (response) {
        if (response.data.data.pincode == "invalid") {
          alerts(
            "Désolé, cet article ne peut pas être expédié à l'adresse que vous avez sélectionnée"
          );
          return false;
        } else {
          var getSelectedValue = document.querySelector(
            'input[name="type"]:checked'
          );

          const form = new FormData();
          form.append("deliver_to_fname", first_name.value);
          form.append("deliver_to_lname", last_name.value);
          form.append("deliver_to_phone", phone.value);
          form.append("address_line1", address1.value);
          form.append("address_line2", address2.value);
          form.append("address_line3", address3.value);
          form.append("email", email.value);
          form.append("landmark", "");
          form.append("city", ville.value);
          form.append("pincode", pincode.value);
          form.append("type", getSelectedValue.value);

          post("customer/add-address", form)
            .then(async function (response) {
              fetchAddress();
            })
            .catch(function (error) {});
        }
      })
      .catch(function (error) {});
  };

  const addAddress = () => {
    onOpenModal();
  };

  const changeLabel = (e, id, pincode) => {
    var ele = document.querySelectorAll(".dd");

    for (var j = 0; j < ele.length; j++) {
      ele[j].classList.remove("active");
    }

    if (e.target.localName !== "label") {
      if (e.target.parentElement.localName === "label") {
        e.target.parentElement.classList.add("active");
      }
    } else {
      e.target.classList.add("active");
    }

    setDeliveryPincode(pincode);
    updateDeliveryDetails(cartId, deliveryTimeSlot, pincode, id);
  };

  const changeLabelTi = (e, timeslot) => {
    var ele = document.querySelectorAll(".timeslot");

    for (var j = 0; j < ele.length; j++) {
      ele[j].classList.remove("active");
    }

    if (e.target.localName !== "label") {
      if (e.target.parentElement.localName === "label") {
        e.target.parentElement.classList.add("active");
      }
    } else {
      e.target.classList.add("active");
    }

    setDeliveryTimeSlot(timeslot);
    updateDeliveryDetails(cartId, timeslot, deliveryPincode, deliveryId);
  };

  const placeOrder = () => {
    var getSelectedAddressValue = document.querySelector(
      'input[name="delivery_address"]:checked'
    );

    if (getSelectedAddressValue === null) {
      //Please select delivery address
      alerts("Veuillez sélectionner/ajouter l'adresse de livraison");
      return false;
    }

    var getSelectedValue = document.querySelector(
      'input[name="delivery_timeslot"]:checked'
    );

    if (!selectedDate) {
      //Please select delivery date
      alerts("veuillez sélectionner la date de livraison");
      return false;
    }

    if (getSelectedValue === null) {
      //Please select time slot
      alerts("Veuillez sélectionner une plage horaire");
      return false;
    }

    var timeSlot = getSelectedValue.value;
    var address = getSelectedAddressValue.value;

    ///order/store/

    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("cart_id", cartId);
    form.append("coupon_id", 0);
    form.append("shipping_id", address);
    form.append("distance", 5);
    form.append("delivery_date", deliveryDate);
    form.append("order_from", "web");

    post("order/store", form)
      .then(async function (response) {
        reactLocalStorage.remove("bhCart");
        window.location = response.data.data;
      })
      .catch(function (error) {});
  };

  // apply coupon

  const apply_coupon = () => {
    var code = document.querySelector('input[id="coupon_code_to_apply"]').value;

    // apply coupon
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("cart_id", cartId);
    form.append("coupon_code", code);
    console.log("request for coupon -- " + form);
    post("cart/apply_coupon", form)
      .then(async function (response) {
        console.log(response);

        if (response.data.status == true) {
          setCouponCode(code);
          fetchUserCartData();
          info(t("coupon_applied_successfully"));
        } else {
          alerts(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const remove_coupon = () => {
    // var code = document.querySelector('input[id="coupon_code_to_apply"]').value;

    // apply coupon
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("cart_id", cartId);

    post("cart/remove_coupon", form)
      .then(async function (response) {
        console.log(response);

        if (response.data.status == true) {
          setCouponCode("");
          fetchUserCartData();
          document.querySelector('input[id="coupon_code_to_apply"]').value = "";
          info("Coupon removed succesfully");
        } else {
          alerts(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Header />

      <main className="bg_gray pattern">
        <div className="container margin_60_40">
          <div className="row justify-content-center">
            <div
              className="col-xl-6 col-lg-8"
              style={{ display: open == false ? "block" : "none" }}
            >
              <div className="checkout">
                <div className="head">
                  {t("delivery_address")}
                  <a href="javascript:void(0);" onClick={(e) => addAddress()}>
                    + {t("add_address")}
                  </a>
                </div>

                <div className="row">
                  {address.map((a, k) => (
                    <div key={a.id} className="col-sm-4">
                      <label
                        style={{ cursor: "pointer" }}
                        onClick={(e) => changeLabel(e, a.id, a.pincode)}
                        htmlFor={a.id + "DA"}
                        className={k === 0 ? "card active dd" : "card dd"}
                      >
                        <div className="address-type">
                          {a.type === "home" && "Maison"}{" "}
                          {a.type === "work" && "Travail"}{" "}
                          {a.type === "other" && "Autre"}
                        </div>
                        <div>
                          {a.deliver_to_fname} {a.deliver_to_lname}
                          <br />
                          {a.address_line1} <br />
                          {a.city} {a.pincode}
                        </div>
                      </label>
                      <input
                        id={a.id + "DA"}
                        defaultChecked={k === 0 ? true : false}
                        type="radio"
                        name="delivery_address"
                        value={a.id}
                        style={{ opacity: 0 }}
                        onClick={(e) => setAddressPincode(a.pincode)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="checkout">
                <div className="head">{t("apply_coupon")}</div>

                {coupon_code === null || coupon_code === "" ? (
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        name="code"
                        id="coupon_code_to_apply"
                      ></input>
                    </div>
                    <div className="col-md-6">
                      <a
                        href="javascript:void(0);"
                        onClick={() => apply_coupon()}
                        className="btn_1 full-width mb_5"
                      >
                        {t("apply_coupon")}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        name="code"
                        id="coupon_code_to_apply"
                        value={coupon_code}
                        disabled
                      ></input>
                    </div>
                    <div className="col-md-6">
                      <a
                        href="javascript:void(0);"
                        onClick={() => remove_coupon()}
                        className="btn_1 danger full-width mb_5"
                        style={{ background: "#841c26" }}
                      >
                        {t("remove_coupon")}
                      </a>
                    </div>
                  </div>
                )}

                {coupon_amount > 0 ? (
                  <span
                    style={{
                      color: "green",
                      marginTop: "10px",
                    }}
                  >
                    FÉLICITATIONS, VOUS AVEZ ECONOMISÉ {coupon_amount} € SUR
                    VOTRE COMMANDE
                  </span>
                ) : null}
              </div>

              <div className="checkout">
                <div className="head">{t("delivery_schedule")}</div>

                <div className="row">
                  <div className="col-md-6">
                    <center>
                      <DatePicker
                        locale="fr"
                        selectsDisabledDaysInRange
                        inline
                        filterDate={isWeekday}
                        selected={selectedDate}
                        startDate={startDate}
                        excludeDates={excludeDates}
                        minDate={startDate}
                        maxDate={addDays(new Date(), 30)}
                        onChange={(date) => setSelectedDate(date)}
                      />
                    </center>
                  </div>
                  <div className="col-md-4">
                    {dates.map((d, k) => (
                      <div key={k}>
                        <center>
                          <label
                            style={{ cursor: "pointer" }}
                            onClick={(e) => changeLabelTi(e, d)}
                            htmlFor={k + "DT"}
                            className={
                              k === 0 ? "card timeslot" : "card timeslot"
                            }
                          >
                            <div className="address-type">{d}</div>
                          </label>
                          <input
                            id={k + "DT"}
                            type="radio"
                            name="delivery_timeslot"
                            value={d}
                            style={{ opacity: 0 }}
                          />
                        </center>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-8 card active"
              style={{ display: open == true ? "block" : "none" }}
            >
              <div className="row">
                <div
                  className="col-sm-12 col-xl-12 col-lg-12"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <h3>{t("add_address")}</h3>
                  <hr />
                </div>
                <div className="col-sm-12 col-xl-6 col-lg-6">
                  <div className="form-group">
                    <label>Prénom*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      id="first_name"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 col-lg-6">
                  <div className="form-group">
                    <label>Nom de famille*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      id="last_name"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 col-lg-6">
                  <div className="form-group">
                    <label>E-mail*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 col-lg-6">
                  <div className="form-group">
                    <label>Numéro de portable*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      id="phone"
                      maxLength="15"
                    />
                  </div>
                </div>

                <div
                  className="col-sm-12 col-xl-12 col-lg-12"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <label>Adresse*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address1"
                    id="address1"
                  />
                </div>

                <div
                  className="col-sm-12 col-xl-12 col-lg-12"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <label>Complément d'adresse</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address2"
                    id="address2"
                  />
                </div>

                {/*<div className="col-sm-12 col-xl-12 col-lg-12" style={{ marginTop: 10, marginBottom: 10 }}>
                                    <label>Adresse ligne 3</label>
                                    <input type="text" className="form-control" name="address3" id="address3" />
                                </div>*/}

                <input
                  type="hidden"
                  className="form-control"
                  name="address3"
                  id="address3"
                />

                <div className="col-sm-12 col-xl-8 col-lg-8">
                  <div className="form-group">
                    <label>Ville*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ville"
                      id="ville"
                    />
                  </div>
                </div>
                {/*<div className="col-sm-12 col-xl-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Point de repère</label>
                                        <input type="text" className="form-control" name="land_mark" id="land_mark" />
                                    </div>
                                </div>*/}
                <div className="col-sm-12 col-xl-4 col-lg-4">
                  <div className="form-group">
                    <label>Code Postal*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="pincode"
                      id="pincode"
                      maxLength="7"
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-xl-4 col-lg-4">
                  <input
                    onChange={() => setType("home")}
                    checked={type == "home" ? true : false}
                    type="radio"
                    name="type"
                    value="home"
                    id="maison"
                  />{" "}
                  <label htmlFor="maison"> MAISON </label>
                </div>

                <div className="col-sm-12 col-xl-4 col-lg-4">
                  <input
                    onChange={() => setType("work")}
                    type="radio"
                    name="type"
                    value="work"
                    id="travail"
                  />{" "}
                  <label htmlFor="travail">TRAVAIL</label>
                </div>

                <div className="col-sm-12 col-xl-4 col-lg-4">
                  <input
                    onChange={() => setType("other")}
                    type="radio"
                    name="type"
                    value="other"
                    id="autre"
                  />{" "}
                  <label htmlFor="autre">AUTRE</label>
                </div>

                <div
                  className="col-sm-12 col-xl-12 col-lg-12"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <a
                    href="javascript:void(0);"
                    className="btn_1 full-width mb_5"
                    onClick={() => add()}
                  >
                    Soumettre
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4" id="sidebar_fixed">
              <div className="box_booking">
                <div className="head">
                  <h3>{t("order_summary")}</h3>
                </div>
                <div className="main">
                  {deliveryDate != "" && (
                    <>
                      <ul>
                        <li>
                          Date de livraison<span>{deliveryDate}</span>
                        </li>
                      </ul>
                      <hr />
                    </>
                  )}

                  {/*<ul className="clearfix">
                                        {
                                            items.map((i, k) =>
                                                <li><a href="#0">{i.product_total_qty}x {i.product_name}</a><span> {i.product_price} {t('currency_symbol')}</span></li>
                                            )
                                        }
                                </ul>*/}

                  <table className="clearfix" width="100%">
                    {items.map((i, k) => (
                      <tr key={i.product_name}>
                        <td width="80%">
                          <a href="#0">
                            {i.product_total_qty}x {i.product_name}
                          </a>
                        </td>
                        <td>
                          <span>
                            {" "}
                            {i.product_price} {t("currency_symbol")}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </table>

                  <ul className="clearfix">
                    <li style={{ display: "none" }}>
                      Subtotal
                      <span>
                        {" "}
                        {subTotal} {t("currency_symbol")}
                      </span>
                    </li>
                    {address.length > 0 && (
                      <li>
                        {t("delivery_charge")}
                        {deliveryCharge === 0 ? (
                          <span style={{ color: "#1c844c" }}>OFFERT</span>
                        ) : (
                          <span>
                            {" "}
                            {deliveryCharge} {t("currency_symbol")}
                          </span>
                        )}
                      </li>
                    )}
                    <>
                      {vatDetails.length > 0 && (
                        <>
                          {vatDetails.map((v, k) => (
                            <>
                              <li>
                                Dont T.V.A ({v.percentage}%){" "}
                                <span>
                                  {v.total} {t("currency_symbol")}
                                </span>
                              </li>
                            </>
                          ))}
                        </>
                      )}
                    </>
                    <li style={{ display: "none" }}>
                      Dont T.V.A.
                      <span>
                        {" "}
                        {vat} {t("currency_symbol")}
                      </span>
                    </li>
                    {coupon_amount > 0 ? (
                      <li>
                        {t("coupon_amount")}
                        <span>
                          {" "}
                          {coupon_amount} {t("currency_symbol")}
                        </span>
                      </li>
                    ) : null}

                    <li className="total">
                      {t("total_price")}
                      <span>
                        {" "}
                        {total} {t("currency_symbol")}
                      </span>
                    </li>
                    {freeOrderBalanceAmt > 0 && (
                      <li
                        style={{
                          border: "1px dotted #fbfbb2",
                          background: "#f7f7d4",
                          padding: 10,
                        }}
                      >
                        Achetez pour <b>{freeOrderBalanceAmt} euros</b> de plus
                        et obtenez la livraison gratuite
                      </li>
                    )}
                  </ul>

                  {checkout === true ? (
                    <a
                      href="javascript:void(0);"
                      onClick={() => placeOrder()}
                      className="btn_1 full-width mb_5"
                    >
                      {t("place_order")}
                    </a>
                  ) : null}

                  {checkout === false ? (
                    <div
                      id="minimum_order_para"
                      style={{ padding: 10, border: "1px solid #bdb2b2" }}
                    >
                      <span style={{ display: "none" }}>
                        you must have a minimum order amount of 100 to place
                        your order.Your current order total is 60
                      </span>
                      Vous devez avoir un montant de commande minimum de{" "}
                      {min_order} euros pour passer votre commande. Le total de
                      votre commande actuelle est de {total_product_price} euros
                    </div>
                  ) : null}

                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Checkout;
