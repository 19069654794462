import { useTranslation } from "react-i18next";
import i18n from "../translations/i18n";
import "../translations/i18n";
import { store } from "react-notifications-component";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";

const addCart = (productId, productName) => {
  store.addNotification({
    title: i18n.t("wonderful"),
    message: i18n.t("success_cart").replace("##", '" ' + productName + ' "'),
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });

  var bhCart = reactLocalStorage.getObject("bhCart");

  if (Object.keys(bhCart).length == 0) {
    var objectData = {
      data: [{ product: productId, qty: 1 }],
      expiry_time: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    };

    reactLocalStorage.setObject("bhCart", JSON.stringify(objectData));
  } else {
    var cart = JSON.parse(bhCart);
    var create = false;
    console.log(cart);

    if (cart.expiry_time == undefined) create = true;

    var endTime = moment(cart.expiry_time);
    var currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

    console.log(
      "currentTime",
      moment(currentTime).format("YYYY-MM-DD HH:mm:ss")
    );
    console.log("endTime", moment(endTime).format("YYYY-MM-DD HH:mm:ss"));

    var duration = moment.duration(moment(currentTime).diff(endTime));
    var minutes = duration.asMinutes();

    console.log("minutes", minutes);

    if (minutes >= 0) create = true;

    if (create == false) {
      cart.data.push({ product: productId, qty: 1 });
      cart.expiry_time = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
      reactLocalStorage.setObject("bhCart", JSON.stringify(cart));
    } else {
      var objectData = {
        data: [{ product: productId, qty: 1 }],
        expiry_time: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      };

      reactLocalStorage.setObject("bhCart", JSON.stringify(objectData));
    }
  }
};

export const removeCart = (productId, productName) => {
  var bhCart = reactLocalStorage.getObject("bhCart");

  if (Object.keys(bhCart).length > 0) {
    var cart = JSON.parse(bhCart);
    cart.data.filter(function (v, i) {
      if (v["product"] == productId) {
        cart.data.splice(i, 1);
      }
    });

    if (cart.data.length == 0) {
      reactLocalStorage.remove("bhCart");
    } else {
      reactLocalStorage.setObject("bhCart", JSON.stringify(cart));
    }
  }

  store.addNotification({
    title: i18n.t("removed"),
    message: i18n.t("removed_cart").replace("##", '" ' + productName + ' "'),
    type: "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const decrementCart = (productId, qty) => {
  var bhCart = reactLocalStorage.getObject("bhCart");

  if (Object.keys(bhCart).length > 0) {
    var cart = JSON.parse(bhCart);
    cart.data.filter(function (v, i) {
      if (v["product"] == productId) {
        v["qty"] = v["qty"] - qty;

        if (v["qty"] == 0) {
          cart.data.splice(i, 1);
        }
      }
    });

    if (cart.data.length == 0) {
      reactLocalStorage.remove("bhCart");
    } else {
      reactLocalStorage.setObject("bhCart", JSON.stringify(cart));
    }
  }
};

export const incrementCart = (productId, qty) => {
  var bhCart = reactLocalStorage.getObject("bhCart");

  if (Object.keys(bhCart).length > 0) {
    var cart = JSON.parse(bhCart);
    cart.data.filter(function (v, i) {
      if (v["product"] == productId) {
        v["qty"] = v["qty"] + qty;

        if (v["qty"] == 0) {
          cart.data.splice(i, 1);
        }
      }
    });

    if (cart.data.length == 0) {
      reactLocalStorage.remove("bhCart");
    } else {
      reactLocalStorage.setObject("bhCart", JSON.stringify(cart));
    }
  }
};

export const isExist = (productId) => {
  var bhCart = reactLocalStorage.getObject("bhCart");
  if (Object.keys(bhCart).length == 0) {
    return false;
  }

  var rt = false;
  var cart = JSON.parse(bhCart);
  cart.data.filter(function (v, i) {
    if (v["product"] == productId) {
      rt = true;
    }
  });

  return rt;
};

export const getQty = (productId) => {
  var bhCart = reactLocalStorage.getObject("bhCart");
  if (Object.keys(bhCart).length == 0) {
    return 0;
  }

  var rt = 0;
  var cart = JSON.parse(bhCart);
  cart.data.filter(function (v, i) {
    if (v["product"] === productId) {
      rt = v["qty"];
    }
  });

  return rt;
};

export const count = () => {
  var bhCart = reactLocalStorage.getObject("bhCart");
  console.log("bhCart", bhCart);

  if (Object.keys(bhCart).length == 0) {
    return 0;
  } else {
    var cart = JSON.parse(bhCart);

    if (cart.expiry_time == undefined) return 0;

    var endTime = moment(cart.expiry_time);
    var currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    var duration = moment.duration(moment(currentTime).diff(endTime));
    var minutes = duration.asMinutes();

    if (minutes >= 0) {
      reactLocalStorage.remove("bhCart");
      return 0;
    }

    return cart.data.length;
  }
};

export default addCart;
