import React, { useState, useHook, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductView from "../components/ProductView";
import { productDetails, cart } from "../actions/index";
import "../styles/detail-page.css";
import Cookies from "js-cookie";
import { get, post } from "../adapters/index";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useParams } from "react-router";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Cache, { setCache } from "../utility/Cache";
import addCart, { isExist, removeCart } from "../utility/Cart";
import veg from "../images/veg.png";
import non_veg from "../images/nonveg.png";
import userImage from "../images/user.png";
import moment from "moment";

import jBox from "jbox";
import "jbox/dist/jBox.all.css";

const PaymentFailed = (props) => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [vat, setVat] = useState(0);
  const { id: paymentId } = useParams();

  const [date, setDate] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const [orders, setOrders] = useState([]);
  const [cartId, setCartId] = useState(0);
  const [vatDetails, setVatDetails] = useState([]);

  useEffect(function () {
    fetchOrderDetails();
  }, []);

  const fetchOrderDetails = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("payment_id", paymentId);

    post("orders/payment", form)
      .then(async function (response) {
        console.table(response.data.data.items);
        setOrders(response.data.data);
        setTotal(response.data.data.total_price);
        setDate(response.data.data.order_date);
        setTimeSlot(response.data.data.time_slot);
        setDeliveryCharge(response.data.data.delivery_charge);
        setCartId(response.data.data.order_no);
        setVat(response.data.data.vat);
        setVatDetails(response.data.data.vat_details);
      })
      .catch(function (error) {});
  };

  return (
    <div>
      <Header />
      <main className="bg_gray pattern">
        <div className="container margin_60_40 margin_mobile">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="box_booking_2">
                <div className="head" style={{ backgroundColor: "#944242" }}>
                  <div className="title">
                    <h3>{t("payment_failed")}</h3>
                    {total} {t("currency_symbol")}
                  </div>
                </div>
                <div className="main">
                  <h6>{t("booking_summary")}</h6>
                  <ul>
                    <li>
                      {t("date")}
                      <span> {date}</span>
                    </li>
                    <li>
                      {t("time_slot")}
                      <span>{timeSlot}</span>
                    </li>
                    <li>
                      {t("order_id")}
                      <span>{cartId}</span>
                    </li>
                  </ul>
                  <hr />
                  <h6>{t("order_details")}</h6>

                  <ul className="clearfix">
                    {orders.items != undefined && orders.items.length > 0
                      ? orders.items.map((i, k) => (
                          <li>
                            <a href="#0">
                              {i.product_qty}x {i.product_name}
                            </a>
                            <span>
                              {" "}
                              {i.product_price} {t("currency_symbol")}
                            </span>
                          </li>
                        ))
                      : null}
                    <li style={{ marginTop: 30, marginBottom: 30 }}>
                      <a>{t("delivery_charge")}</a>
                      <span>
                        {" "}
                        {deliveryCharge} {t("currency_symbol")}
                      </span>
                    </li>

                    {vatDetails.length > 0 && (
                      <>
                        {vatDetails.map((v, k) => (
                          <>
                            <li>
                              Dont T.V.A ({v.percentage}%){" "}
                              <span>
                                {v.total} {t("currency_symbol")}
                              </span>
                            </li>
                          </>
                        ))}
                      </>
                    )}

                    {/*<li style={{marginTop: 30}}>
                                            <a>T.V.A.</a>
                                            <span> {vat} {t('currency_symbol')}</span>
                                    </li>*/}

                    <li>
                      <a>{t("total_price")}</a>
                      <span>
                        {" "}
                        {total} {t("currency_symbol")}
                      </span>
                    </li>
                  </ul>

                  <a href="/" className="btn_1 full-width outline mb_5">
                    {t("continue_shopping")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PaymentFailed;
