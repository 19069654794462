import React, { useState, useHook, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer";

import bg_call_section from "../images/bg_call_section.jpg";
import banner_bg_desktop from "../images/banner_bg_desktop.jpg";

import veg from "../images/veg.png";
import non_veg from "../images/nonveg.png";

import { useTranslation } from "react-i18next";
import "../translations/i18n";
import LoadingOverlay from "react-loading-overlay-ts";
import PulseLoader from "react-spinners/PulseLoader";
import { loading } from "../actions/index";
import { useSelector, useDispatch } from "react-redux";
import { get, post } from "../adapters/index";
import { categories, popular, ratings } from "../actions/index";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Cookies from "js-cookie";
import "../styles/css_skelton.css";

//import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  WithStore,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Home = (props) => {
  const history = useHistory();
  const loading = useSelector((state) => state.loading);
  const category = useSelector((state) => state.categories);
  const popularProducts = useSelector((state) => state.products.popular);
  const ratingProducts = useSelector((state) => state.products.ratings);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [askPincode, setAskPincode] = useState(false);
  const [savePincodeLoading, setSavePincodeLoading] = useState(false);
  const inputM = useRef(null);
  const inputO = useRef(null);
  const onOpenModal = () => setAskPincode(true);
  const onCloseModal = () => setAskPincode(false);
  const [pincodeInfo, setPincodeInfo] = useState("");
  //const [category, setCategory] = useState([]);

  const [c, setC] = useState([]);
  const [offerBanner, setOfferBanner] = useState([]);
  const [offerBannerImages, setOfferBannerImages] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const [brandings, setBrandings] = useState([]);
  const [homeTitle, setHomeTitle] = useState(null);
  const [perPageItems, setPerPageItems] = useState(7);

  const [activeIndex, setActiveIndex] = useState(0);
  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ items }) => setActiveIndex(items);

  const [responsive, setResponsive] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  });

  const [settings, setSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function (i) {
      return (
        <a>
          <img src={tt(i, offerBanner)} style={{ width: 80, height: 60 }} />
        </a>
      );
    },
  });

  const tt = (i, offerBanner) => {
    if (offerBanner.length > 0) {
      for (var k = 0; k < offerBanner.length; k++) {
        if (k == i) {
          return offerBanner[k].image;
        }
      }
    } else {
      return "http://localhost/freshnfetch/public//uploads/images/1624345168.jpg";
    }
  };

  const flink = (i, offerBanner) => {
    if (offerBanner.length > 0) {
      for (var k = 0; k < offerBanner.length; k++) {
        if (k == i) {
          return offerBanner[k].link;
        }
      }
    } else {
      return "";
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //alert("You clicked outside of me!");
          setSearchProducts([]);
          var ele = document.getElementById("q");
          if (ele != null) {
            ele.value = "";
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(function () {
    var c = window.client;

    /*navigator.geolocation.getCurrentPosition(
            function (position) {
                console.log(position);
            },
            function (error) {
                console.error("Error Code = " + error.code + " - " + error.message);

                if (error.code == 1 && Cookies.get('bhDelivery') == undefined) {
                    //setAskPincode(true);
                }
            }
        );*/

    if (Cookies.get("bhId") == undefined) {
      Cookies.set("bhId", process.env.REACT_APP_DEFAULT_ID, { expires: 2 });
    }
    _loadCategories();
    _loadMostPopular();
    _loadRatings();
    _loadOfferBanner();

    if (window.innerWidth < 625) {
      setPerPageItems(1);
    }
  }, []);

  const _loadCategories = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("wb/category/all", form)
      .then(async function (response) {
        dispatch(categories(response.data.data));

        setTimeout(function () {
          window.$(".responsive").slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 7,
            slidesToScroll: 7,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ],
          });
        }, 10);
      })
      .catch(function (error) {});
  };

  const _loadMostPopular = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("wb/products/popular", form)
      .then(async function (response) {
        dispatch(popular(response.data.data));
      })
      .catch(function (error) {});
  };

  const _loadRatings = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("wb/products/ratings", form)
      .then(async function (response) {
        dispatch(ratings(response.data.data));
        setHomeTitle(response.data.home_title);
      })
      .catch(function (error) {});
  };

  const _loadOfferBanner = () => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));

    post("wb/home/offer_banner", form)
      .then(async function (response) {
        setOfferBanner(response.data.data.banners);
        setBrandings(response.data.data.brandings);

        var banners = [];

        for (var k = 0; k < response.data.data.banners.length; k++) {
          banners.push({
            original: response.data.data.banners[k].image,
            thumbnail: response.data.data.banners[k].image,
            originalTitle: response.data.data.banners[k].title,
            description: response.data.data.banners[k].title,
            link: response.data.data.banners[k].link,
          });

          setOfferBannerImages(banners);
        }

        setSettings({
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          dotsClass: "slick-dots slick-thumb",
          /*customPaging: function (i) {
                    return (
                        <a>
                            <img src={tt(i, response.data.data.banners)} style={{ width: 80, height: 60 }} />
                        </a>
                    );
                },*/
        });
      })
      .catch(function (error) {});
  };

  const set = () => {
    if (inputM.current.value.length != 5) {
      return false;
    }
    setSavePincodeLoading(true);

    const form = new FormData();
    form.append("pincode", inputM.current.value);
    post("customer/validate/pincode", form)
      .then(async function (response) {
        setSavePincodeLoading(false);

        if (response.data.data.pincode == "invalid") {
          setPincodeInfo(t("delivery_not"));
          Cookies.set("bhDelivery", false, { expires: 2 });
        } else {
          setAskPincode(false);
          Cookies.set("bhDelivery", true, { expires: 2 });

          if (response.data.data.id != 0) {
            Cookies.set("bhId", response.data.data.id, { expires: 2 });
          }
        }
      })
      .catch(function (error) {
        setSavePincodeLoading(false);
      });
  };

  const seo_url = (url) => {
    return url
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, "");
  };

  const images = [
    {
      original:
        "http://localhost/freshnfetch/public/uploads/images/1629889842.jpg",
      thumbnail:
        "http://localhost/freshnfetch/public/uploads/images/1629889842.jpg",
      originalTitle: "",
      description: "",
    },
  ];

  const searchP = (e) => {
    const form = new FormData();
    form.append("butcher_id", Cookies.get("bhId"));
    form.append("term", e.target.value);
    form.append("location", "");

    post("wb/products/search", form)
      .then(async function (response) {
        if (response.data.message === "failed") {
          setSearchProducts([]);
        } else {
          setSearchProducts(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  const secondaryOptions = {
    type: "slide",
    rewind: true,
    width: "100%",
    gap: "1rem",
    pagination: true,
    fixedWidth: 180,
    fixedHeight: 100,
    cover: true,
    focus: "center",
    isNavigation: true,
    updateOnMove: true,
    autoplay: true,
  };

  const responsives = {
    0: { items: 2 },
    568: { items: 2 },
    1024: { items: 7 },
  };

  const goto = (url) => {
    history.push(url);
  };

  return (
    <div>
      <LoadingOverlay active={loading} spinner={<PulseLoader />}>
        <HeaderHome />
        <main>
          <Modal
            styles={{
              modal: { backgroundColor: "transparent", overflow: "hidden" },
            }}
            open={askPincode}
            showCloseIcon={false}
            onClose={onCloseModal}
            blockScroll={true}
            center
          >
            <div id="sign-in-dialog" className="zoom-anim-dialog">
              <div className="modal_header">
                <h3>{t("set_pincode")}</h3>
              </div>
              <div className="sign-in-wrapper">
                <a
                  href="#0"
                  className="social_bt facebook"
                  style={{ display: "none" }}
                >
                  Login with Facebook
                </a>
                <a
                  href="#0"
                  className="social_bt google"
                  style={{ display: "none" }}
                >
                  Login with Google
                </a>
                <div className="divider" style={{ display: "none" }}>
                  <span>Or</span>
                </div>

                <p>{t("pincodehelp")}</p>

                {pincodeInfo != "" ? (
                  <p
                    style={{
                      color: "#1189ae",
                      border: "1px solid #eeeded",
                      padding: 10,
                    }}
                  >
                    {pincodeInfo}
                  </p>
                ) : null}

                <div className="form-group">
                  <label>{t("pincode")}</label>
                  <input
                    type="number"
                    ref={inputM}
                    className="form-control"
                    name="mobile"
                    id="mobile"
                    min="5"
                    max="5"
                  />
                  <i className="fs1" aria-hidden="true" data-icon=""></i>
                </div>

                <div className="text-center">
                  <button
                    onClick={() => set()}
                    className="btn_1 full-width mb_5 one"
                  >
                    {" "}
                    {savePincodeLoading}
                    {savePincodeLoading == false ? (
                      t("save")
                    ) : (
                      <PulseLoader color="#ffffff" size={8} />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <div className="hero_single version_2">
            <div
              className="opacity-mask"
              data-opacity-mask="rgba(0, 0, 0, 0.6)"
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-9 col-lg-10 col-md-8">
                    <h1>B.e Halal </h1>
                    <p>Tout le halal en 1 clic</p>
                    <form method="get" action="/products">
                      <div className="row no-gutters custom-search-input">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input
                              autoComplete="off"
                              id="q"
                              name="q"
                              onChange={(e) => searchP(e)}
                              className="form-control"
                              type="text"
                              placeholder="Que cherchez-vous..."
                            />
                            <i className="icon_search"></i>
                          </div>
                          {searchProducts != undefined ? (
                            <ul
                              ref={wrapperRef}
                              style={{
                                margin: 0,
                                padding: 0,
                                maxHeight: 300,
                                overflow: "auto",
                              }}
                            >
                              {searchProducts.map((p, k) => (
                                <li
                                  key={p.id}
                                  style={{
                                    borderBottom: "1px solid #e3e0e0",
                                    color: "#0d0d0d",
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "#fff",
                                    padding: 4,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div style={{ marginRight: 10 }}>
                                      <a
                                        href={
                                          "/product/" +
                                          p.id +
                                          "/" +
                                          seo_url(p.name + "-" + p.qty_type)
                                        }
                                      >
                                        <img
                                          style={{ width: 100 }}
                                          src={
                                            process.env.REACT_APP_IMG_URL +
                                            p.image
                                          }
                                          data-src={
                                            process.env.REACT_APP_IMG_URL +
                                            p.image
                                          }
                                          className="img-fluid lazy"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div>
                                      <div
                                        className="item_title"
                                        style={{ textAlign: "left" }}
                                      >
                                        <span
                                          style={{
                                            color: "#686767",
                                            fontSize: 12,
                                          }}
                                        >
                                          {p.category_name}
                                        </span>
                                        <a
                                          href={
                                            "/product/" +
                                            p.id +
                                            "/" +
                                            seo_url(p.name + "-" + p.qty_type)
                                          }
                                        >
                                          <h3
                                            style={{
                                              fontSize: 14,
                                              color: "#000",
                                            }}
                                          >
                                            {p.name} {p.qty_type}
                                          </h3>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      justifyContent: "flex-end",
                                      float: "right",
                                    }}
                                  >
                                    <span
                                      style={{
                                        marginTop: 19,
                                        display: "block",
                                        marginRight: 20,
                                      }}
                                    >
                                      <b>{p.unit_price} Euros</b>
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                        <div className="col-lg-6" style={{ display: "none" }}>
                          <div className="form-group">
                            <input
                              name="location"
                              className="form-control no_border_r"
                              type="text"
                              placeholder="Votre code postal..."
                            />
                            <i className="icon_pin_alt"></i>
                          </div>
                        </div>
                        <div className="col-lg-2" style={{ display: "none" }}>
                          <input type="submit" value="Recherche" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg_gray">
            <div className="container margin_60_40">
              <div className="main_title center">
                <span>
                  <em></em>
                </span>
                <h2>Qu’allez vous trouver ?</h2>
                <p>
                  Vos viandes favorites, vos charcuteries, vos épices, vos
                  sauces, vos fromages, vos desserts & bien plus encore... à
                  portée de main
                </p>
              </div>

              {/*
                                category &&
                                <CarouselProvider
                                    naturalSlideWidth={100}
                                    naturalSlideHeight={60}
                                    totalSlides={Math.ceil(category.length / perPageItems) + 1}
                                    infinite={false}
                                >
                                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                                        <ButtonBack className="carousel_btn left"><i className="fas fa-arrow-left"></i></ButtonBack>
                                        <Slider>
                                            {
                                                category &&
                                                category.map((c, v) =>
                                                    <Slide onClick={() => goto("/category/" + c.id + "/" + seo_url(c.category_name))} index={v} className="sl" style={{ cursor: 'pointer', boxShadow: '0 1px 4px rgba(0,0,0,.08)', width: 168, marginRight: 15, height: 88, backgroundImage: "url(" + process.env.REACT_APP_IMG_URL + c.image + ")", backgroundSize: 'cover', backgroundPosition: '50%', opacity: 1 }} key={v}>
                                                        <a href={"/category/" + c.id + "/" + seo_url(c.category_name)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <h3 className="carousel_h3">{c.category_name}</h3>
                                                        </a>
                                                    </Slide>
                                                )
                                            }
                                        </Slider>
                                        <ButtonNext disabled={null} className="carousel_btn right"><i className="fas fa-arrow-right"></i></ButtonNext>
                                    </div>

                                </CarouselProvider>
                                        */}

              <div className="slider responsive">
                {category &&
                  category.map((c, v) => (
                    <div
                      key={c.id}
                      className="multiple"
                      onClick={() =>
                        goto(
                          "/category/" + c.id + "/" + seo_url(c.category_name)
                        )
                      }
                    >
                      <div
                        style={{
                          borderRadius: 4,
                          cursor: "pointer",
                          boxShadow: "0 1px 4px rgba(0,0,0,.08)",
                          border: "1px solid #f4f4f4",
                          width: 168,
                          height: 88,
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_IMG_URL +
                            c.image +
                            ")",
                          backgroundSize: "cover",
                          backgroundPosition: "50%",
                          opacity: 1,
                        }}
                      >
                        <h3 className="carousel_slack">{c.category_name}</h3>
                      </div>
                    </div>
                  ))}
              </div>

              {/*
                            category &&                        
                            <CarouselProvider
                                naturalSlideWidth={305}
                                naturalSlideHeight={60}
                                totalSlides={Math.ceil(category.length/4) + 1}
                            >
                                <div style={{textAlign:'right'}}>
                                <ButtonBack className="carousel_btn">&#8249;</ButtonBack>
                                <ButtonNext className="carousel_btn">&#8250;</ButtonNext>
                                </div>
                                <Slider>

                                {
                                    category &&
                                        category.map((c, v) =>
                                            <Slide index={v} className="sl" style={{width:305, marginRight:10}} key={v}>
                                                <a href={"/category/" + c.id + "/" + seo_url(c.category_name)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                                    {
                                                        c.image === null
                                                            ?
                                                            <i className="icon-food_icon_burrito"></i>
                                                            :
                                                            <img src={process.env.REACT_APP_IMG_URL + c.image} style={{ width: 110, marginBottom: 35, marginTop:20 }} />
                                                    }

                                                    <h3>{c.category_name}</h3>
                                                </a>
                                            </Slide>
                                        )

                                }

                                </Slider>
                                
                            </CarouselProvider>
                            */}
            </div>
          </div>

          <div>
            {/*<Slider {...settings}>
                            {
                                offerBanner.map((i, k) =>
                                    <div className="each-slide">
                                        <div style={{ 'backgroundImage': `url(${i.image})` }}>
                                            <span><h3>{i.title}</h3></span>
                                        </div>
                                    </div>
                                )
                            }
                        </Slider>*/}
            {offerBanner.length > 0 ? (
              <Carousel autoPlay infiniteLoop showThumbs={false}>
                {offerBanner.map((i, k) => (
                  <div key={i.image}>
                    <img
                      onClick={() => goto(i.link)}
                      src={i.image}
                      style={{ cursor: "pointer", pointerEvents: "all" }}
                    />
                    <p className="legend">{i.title}</p>
                  </div>
                ))}
              </Carousel>
            ) : null}
          </div>

          <div className="container margin_60_40">
            {/*<div className="main_title">
                            <span><em></em></span>
                            <h2>Découvrez les articles les mieux notés</h2>
                            <p></p>
                            <a href="/products/popular"><font color="#0f8044">Voir tout</font></a>
                        </div>*/}

            {popularProducts.length > 0 ? (
              <OwlCarousel
                responsive={responsive}
                nav
                items={4}
                dots={false}
                className="owl-theme"
                margin={10}
                style={{ display: "none" }}
              >
                {popularProducts.map((p, k) => {
                  return (
                    <div
                      key={p.product_id}
                      className="item"
                      style={{ display: "none" }}
                    >
                      <div className="strip">
                        <figure>
                          <img
                            src={process.env.REACT_APP_IMG_URL + p.image}
                            data-src={process.env.REACT_APP_IMG_URL + p.image}
                            alt=""
                          />
                          <Link
                            to={
                              "/product/" + p.product_id + "/" + seo_url(p.name)
                            }
                            className="strip_info"
                          >
                            <small>{p.category_name}</small>
                            <div className="item_title">
                              <h3>{p.name}</h3>
                              <small>{p.price} Euros</small>
                            </div>
                          </Link>
                        </figure>
                        <ul>
                          <li>
                            <span>
                              {p.type == 1 ? (
                                <img src={veg} alt="" />
                              ) : (
                                <img src={non_veg} alt="" />
                              )}
                            </span>
                          </li>
                          <li>
                            <div className="score">
                              <strong>{p.rating} &#9733;</strong>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            ) : (
              <div></div>
            )}

            <div style={{ marginTop: 80, marginBottom: 80, borderWidth: 1 }}>
              {brandings.length > 0 ? (
                <Splide options={secondaryOptions}>
                  {brandings.map((b, k) => (
                    <SplideSlide key={b.image}>
                      <img
                        src={process.env.REACT_APP_IMG_URL + b.image}
                        alt="Image 1"
                      />
                    </SplideSlide>
                  ))}
                </Splide>
              ) : null}
            </div>

            <div
              className="banner lazy"
              data-bg={banner_bg_desktop}
              style={{
                display: "none",
                marginTop: 20,
                backgroundImage: "url(" + banner_bg_desktop + ")",
              }}
            >
              <div
                className="wrapper d-flex align-items-center opacity-mask"
                data-opacity-mask="rgba(0, 0, 0, 0.2)"
              >
                <div>
                  <small>B.e Halal</small>
                  <h3>
                    Choisissez vos produits préférés auprès de notre sélection
                    B.e Halal
                  </h3>
                  <a href="grid-listing-filterscol.html" className="btn_1">
                    En savoir plus
                  </a>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: 30 }}>
              <div className="col-12">
                <div className="main_title version_2">
                  <span>
                    <em></em>
                  </span>
                  <h2>{homeTitle}</h2>
                  <p></p>
                  <Link
                    to={"/products/ratings"}
                    className="btn_1"
                    style={{ display: "none" }}
                  >
                    Voir tout
                  </Link>
                </div>
              </div>
              <div className="col-md-12">
                <div className="list_home">
                  <div className="row">
                    {ratingProducts.length > 0
                      ? ratingProducts.map((p, k) => (
                          <div key={p.product_id} className="col-md-6">
                            <ul>
                              <li>
                                <Link
                                  to={
                                    "/product/" +
                                    p.product_id +
                                    "/" +
                                    seo_url(p.name)
                                  }
                                >
                                  <figure>
                                    <img
                                      src={
                                        process.env.REACT_APP_IMG_URL + p.image
                                      }
                                      data-src={
                                        process.env.REACT_APP_IMG_URL + p.image
                                      }
                                      alt=""
                                    />
                                  </figure>
                                  <div
                                    className="score"
                                    style={{ display: "none" }}
                                  >
                                    <strong>{p.rating} &#9733;</strong>
                                  </div>
                                  <em>{p.category_name}</em>
                                  <h3>{p.name}</h3>
                                  <ul>
                                    <li>
                                      <font color="#8cc04f">
                                        <b>{p.price} Euros</b>
                                      </font>
                                      {console.log(p)}
                                      {p.discount > 0 ? (
                                        <font
                                          color="#8cc04f"
                                          style={{
                                            textDecorationLine: "line-through",
                                            marginLeft: "10px",
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          {p.original_price} Euros
                                        </font>
                                      ) : null}
                                    </li>
                                  </ul>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="call_section lazy"
            data-bg={bg_call_section}
            style={{ backgroundImage: "url(" + bg_call_section + ")" }}
          >
            <div className="container clearfix">
              <div className="col-lg-5 col-md-6 float-right wow">
                <div className="box_1" style={{ display: "none" }}>
                  <h3>Devenir membre B.e Halal</h3>
                  <p>
                    Un produit digital, très demandé par les consommateurs
                    musulmans
                  </p>
                  <a href="about#form" className="btn_1">
                    S'inscrire maintenant
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </LoadingOverlay>
    </div>
  );
};

export default Home;
